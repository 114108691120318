import React, { useRef } from "react";

export const Quote = () => {
    const quotes = [
        { quote: "There is no greater agony than bearing an untold story inside you.", by: 'Maya Angelou' },
        { quote: "There is nothing to writing. All you do is sit down at a typewriter and bleed.", by: 'Ernest Hemingway' },
        { quote: "Fantasy is hardly an escape from reality. It's a way of understanding it.", by: 'Lloyd Alexander' },
        { quote: "A well-composed book is a magic carpet on which we are wafted to a world that we cannot enter in any other way.", by: 'Caroline Gordon' },
        { quote: "The strength of your villain is the strength of your story.", by: 'Dwight V. Swain' },
        { quote: "Specificity is the soul of narrative.", by: 'John Hodgman' },
        { quote: "If there's a book that you want to read, but it hasn't been written yet, then you must write it.", by: 'Toni Morrison' },
        { quote: "Imagination is like a muscle. I found out that the more I wrote, the bigger it got.", by: 'Philip José Farmer' },
        { quote: "We have to continually be jumping off cliffs and developing our wings on the way down.", by: 'Kurt Vonnegut' },
        { quote: "M is for magic. All the letters are, if you put them together properly. You can make magic with them, and dreams, and, I hope, even a few surprises…", by: 'Neil Gaiman' },
        { quote: "It's weird not to be weird.", by: "John Lennon" },
        { quote: "A writer is someone for whom writing is more difficult than it is for other people.", by: 'Thomas Mann' },
        { quote: "You can make anything by writing.", by: 'C.S. Lewis' },
        { quote: "Words are, in my not-so-humble opinion, our most inexhaustible source of magic.", by: 'J.K. Rowling' },
        { quote: "Fill your paper with the breathings of your heart.", by: 'William Wordsworth' },
        { quote: "Description begins in the writer’s imagination, but should finish in the reader’s.", by: 'Stephen King' },
        { quote: "The scariest moment is always just before you start.", by: 'Stephen King' },
        { quote: "Write what should not be forgotten.", by: 'Isabel Allende' },
        { quote: "Good writing is supposed to evoke sensation in the reader—not the fact that it is raining, but the feeling of being rained upon.", by: 'E.L. Doctorow' },
        { quote: "No tears in the writer, no tears in the reader. No surprise in the writer, no surprise in the reader.", by: 'Robert Frost' },
        { quote: "Stories are light. Light is precious in a world so dark.", by: 'Kate DiCamillo' },
        { quote: "The purpose of a writer is to keep civilization from destroying itself.", by: 'Albert Camus' },
        { quote: "A word after a word after a word is power.", by: 'Margaret Atwood' }
    ];
    

    const genQuote = (array) => {
        let rando = Math.floor(Math.random() * array.length);
        return array[rando];
    };

    const quote = genQuote(quotes);
    const link = `https://en.wikipedia.org/wiki/${quote.by}`;

    const quoteRef = useRef(null);


    return (
        <section
            ref={quoteRef}
            style={{
                fontSize: `20px`,
                height: "18.5rem",
                width: "100%",
                padding: "1rem",
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                overflow: "hidden" // Ensure no content spills out
            }}
        >
            <blockquote style={{ margin: 0, width: "100%" }}>
                <p style={{ margin: 0 }}>
                    "{quote.quote}"
                </p>
                <cite>
                    <a target="_blank" rel="noreferrer" href={link}>
                        - {quote.by}
                    </a>
                </cite>
            </blockquote>
        </section>
    );
};
