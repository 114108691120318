import { createSlice } from "@reduxjs/toolkit";


export const writePromptSlice = createSlice({
    name: 'writePrompt',
    initialState: {
        wPrompt: '',
        genres: []
    },
    reducers: {
        setPrompt: (state,action) => {
            state.wPrompt = action.payload
            
        },
        setStoryGenres: (state,action) => {
            state.genres = action.payload
        }
      
    }
})

export const selectWritePrompt= (state) => state.writePrompt.wPrompt;
export const selectGenres = (state) => state.writePrompt.genres
export const {setPrompt, setStoryGenres} = writePromptSlice.actions
export default writePromptSlice.reducer;
