import React, { useState } from 'react';
import './flagContent.css'; // Import the CSS file for styling
import { arrayUnion, collection, doc, updateDoc, addDoc } from 'firebase/firestore';
import { firestore } from '../../../firebase/firebase';

export const FlagContent = ({ story, user }) => {
    const [showFlagContent, setShowFlagContent] = useState(false);
    const [flagType, setFlagType] = useState('');
    const [error, setError] = useState(null); // State to track errors
    const [isSubmitting, setIsSubmitting] = useState(false); // State to track submission status
    const [success, setSuccess] = useState(false); // State to track success message

    const handleFlagChange = (event) => {
        setFlagType(event.target.value);
        setError(null); // Clear any previous errors when flag type changes
        setSuccess(false); // Clear success message when user changes selection
    };

    const handleFlagSubmit = async () => {
        if (!user || !flagType) {
            setError('Please select a reason and ensure you are logged in.');
            return;
        }

        setIsSubmitting(true);
        try {
            const storyRef = doc(firestore, `stories`, story.uid);
            const date = new Date().toISOString();

            // Update the story with the flag
            await updateDoc(storyRef, {
                flags: arrayUnion({
                    user: user.username,
                    type: flagType,
                    date: date,
                })
            });

            // Add a new document to the 'flags' collection
            const flagData = {
                user: user.username,
                type: flagType,
                date: date,
                title: story.title,
                author: story.user,
                storyId: story.uid
            };

            // Adding a new flag to the 'flags' collection
            const flagRef = collection(firestore, 'flags');
            await addDoc(flagRef, flagData);

            // Clear the form and show success message
            setFlagType('');
            setShowFlagContent(false);
            setError(null); // Clear any error after successful submission
            setSuccess(true); // Show success message
        } catch (err) {
            console.error("Error submitting flag:", err);
            setError('Failed to submit the flag. Please try again later.');
            setSuccess(false); // Hide success message on error
        } finally {
            setIsSubmitting(false);
        }
    };

    const toggleDropdown = () => {
        setShowFlagContent(!showFlagContent);
        setError(null); // Clear any previous errors when toggling the dropdown
        setSuccess(false); // Clear success message when toggling dropdown
    };

    return (
        <div className="flagging">
            <div className='flag-label' onClick={toggleDropdown}>
                {showFlagContent ? "Flag for Content " + String.fromCharCode(9650) : "Flag for Content " + String.fromCharCode(9660)}
            </div>
            {showFlagContent && (
                <div className="flag-dropdown">
                    <select
                        id="flag-type"
                        value={flagType}
                        onChange={handleFlagChange}
                        disabled={isSubmitting}
                    >
                        <option value="">Select a reason</option>
                        <option value="Illicit Content">Illicit Content</option>
                        <option value="Missing Content Warning">Missing Content Warning</option>
                        <option value="Spam">Spam</option>
                        <option value="Hate Speech">Hate Speech</option>
                        <option value="AI">Use of Generative AI</option>
                        <option value="Other">Other</option>
                    </select>
                    <button
                        onClick={handleFlagSubmit}
                        disabled={!flagType || isSubmitting}
                    >
                        {isSubmitting ? 'Submitting...' : 'Submit Flag'}
                    </button>
                    {error && <p className="error-message">{error}</p>}
                    {success && <p className="success-message">Flag submitted successfully!</p>}
                </div>
            )}
        </div>
    );
};
