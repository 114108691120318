import React from "react";
import { AdminActivity } from "./adminActivity";
import { TotalStats } from "./totalStats";
import './admin.css'
import UserManagement from "./userManagement";
import { Flags } from "./flags";
import { CreateContest } from "./createContest";
import { Judging } from "./judging";
import { PageViews } from "./pageViews";
import { useAuth } from "../../contexts/authContext/authContext";
import { AdminMessages } from "./adminMessages";
import AdminNews from "./news/adminNews";

export const Admin = () => {

    const {currentUser} = useAuth();

    if (!currentUser || currentUser.displayName !== 'dave') {
        return <header><h1>You do not have permission to view this page.</h1></header>;
    }
    return(
        
            <div className="admin-page">
                <header><h1>Starship Flintsmoke Control Center</h1></header>
                <div className='stats-ticker'><TotalStats /></div>
                <div className="admin-main">
                    <div >    
                        <div className='admin-content'>
                            <div className="admin-users box"><UserManagement /></div>
                            <div className="admin-views box"><PageViews /></div>
                            <div className="admin-flags box"><Flags /></div>
                            <div className="create-contest box"><CreateContest /></div>
                            <div className="admin-messages box"><AdminMessages /></div>
                            <div className="admin-news box"><AdminNews /></div>
                        </div>                
                        <div className="judging box"><Judging /></div>
                    </div>
                    <div className="admin-activity box"><AdminActivity /></div>
                </div>
            </div>
    )
}