// Login.js
import React, { useState } from 'react';
import { Navigate, Link } from 'react-router-dom';
import { getAuth, signInWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';
import { LeftSide } from '../../app/leftSide/leftSide';
import { RightSide } from '../../app/rightSide/rightSide';
import { useAuth } from '../../contexts/authContext/authContext';
import './login.css';

export const Login = () => {
  const { userLoggedIn, currentUser, setCurrentUser } = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isSigningIn, setIsSigningIn] = useState(false);
  const [isResetting, setIsResetting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [resetMessage, setResetMessage] = useState('');

  // Handle Sign In
  const onSubmit = async (e) => {
    e.preventDefault();
    setIsSigningIn(true);
    setErrorMessage('');
    setResetMessage('');
    try {
      const auth = getAuth();
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      setCurrentUser(userCredential.user);
    } catch (error) {
      console.error('Error during sign in:', error);
      // Map Firebase error codes to user-friendly messages
      let message = 'An error occurred during sign in.';
      switch (error.code) {
        case 'auth/invalid-email':
          message = 'Invalid email address.';
          break;
        case 'auth/user-disabled':
          message = 'This user has been disabled.';
          break;
        case 'auth/user-not-found':
          message = 'No user found with this email.';
          break;
        case 'auth/wrong-password':
          message = 'Incorrect password.';
          break;
        default:
          message = error.message;
      }
      setErrorMessage(message);
    } finally {
      setIsSigningIn(false);
    }
  };

  // Handle Password Reset
  const handlePasswordReset = async () => {
    if (!email) {
      setErrorMessage('Please enter your email address to reset your password.');
      return;
    }
    setIsResetting(true);
    setErrorMessage('');
    setResetMessage('');
    try {
      const auth = getAuth();
      await sendPasswordResetEmail(auth, email);
      setResetMessage('Password reset email sent! Please check your inbox.');
    } catch (error) {
      console.error('Error sending password reset email:', error);
      // Map Firebase error codes to user-friendly messages
      let message = 'An error occurred while sending the reset email.';
      switch (error.code) {
        case 'auth/invalid-email':
          message = 'Invalid email address.';
          break;
        case 'auth/user-not-found':
          message = 'No user found with this email.';
          break;
        default:
          message = error.message;
      }
      setErrorMessage(message);
    } finally {
      setIsResetting(false);
    }
  };

  // Redirect to profile if logged in
  if (userLoggedIn) {
    return <Navigate to={`/${currentUser.displayName}`} replace={true} />;
  }

  return (
    <div className='page'>
      <LeftSide />
      <main className="login-container">
        <div className="login-form-container">
          <div className="login-header">
            <header>
              <h1>Welcome Back</h1>
            </header>
          </div>
          <form onSubmit={onSubmit} className="login-form">
            <div className="login-field">
              <label>Email</label>
              <input
                type="email"
                autoComplete='email'
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email"
              />
            </div>
            <div className="login-field">
              <label>Password</label>
              <input
                type="password"
                autoComplete='current-password'
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Enter your password"
              />
            </div>
            {errorMessage && <span className='error-message'>{errorMessage}</span>}
            {resetMessage && <span className='success-message'>{resetMessage}</span>}
            <p className="forgot-password-link">
            Forgot your password?{' '}
            <span
              onClick={handlePasswordReset}
              disabled={isResetting}
              className="reset-password-button"
            >
              {isResetting ? 'Sending...' : 'Reset it here'}
            </span>
          </p>
            <button
              id='login-button'
              type="submit"
              disabled={isSigningIn}
              className={`submit-button ${isSigningIn ? 'disabled' : ''}`}
            >
              {isSigningIn ? 'Signing In...' : 'Sign In'}
            </button>
          </form>
          
          <p className="register-link">
            Don't have an account? <Link to={'/register'}>Sign up</Link>
          </p>
          {/* 
          <div className='divider'>
            <div></div>
            <span>OR</span>
            <div></div>
          </div>
          <button
            id='login-button'
            disabled={isSigningIn}
            onClick={onGoogleSignIn}
            className={`google-signin ${isSigningIn ? 'disabled' : ''}`}
          > 
            <svg viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_17_40)">
                <path d="M47.532 24.5528C47.532 22.9214 47.3997 21.2811 47.1175 19.6761H24.48V28.9181H37.4434C36.9055 31.8988 35.177 34.5356 32.6461 36.2111V42.2078H40.3801C44.9217 38.0278 47.532 31.8547 47.532 24.5528Z" fill="#4285F4" />
                <path d="M24.48 48.0016C30.9529 48.0016 36.4116 45.8764 40.3888 42.2078L32.6549 36.2111C30.5031 37.675 27.7252 38.5039 24.4888 38.5039C18.2275 38.5039 12.9187 34.2798 11.0139 28.6006H3.03296V34.7825C7.10718 42.8868 15.4056 48.0016 24.48 48.0016Z" fill="#34A853" />
                <path d="M11.0051 28.6006C9.99973 25.6199 9.99973 22.3922 11.0051 19.4115V13.2296H3.03298C-0.371021 20.0112 -0.371021 28.0009 3.03298 34.7825L11.0051 28.6006Z" fill="#FBBC04" />
                <path d="M24.48 9.49932C27.9016 9.44641 31.2086 10.7339 33.6866 13.0973L40.5387 6.24523C36.2 2.17101 30.4414 -0.068932 24.48 0.00161733C15.4055 0.00161733 7.10718 5.11644 3.03296 13.2296L11.005 19.4115C12.901 13.7235 18.2187 9.49932 24.48 9.49932Z" fill="#EA4335" />
              </g>
              <defs>
                <clipPath id="clip0_17_40">
                  <rect width="48" height="48" fill="white" />
                </clipPath>
              </defs>
            </svg>
            {isSigningIn ? 'Signing In...' : 'Continue with Google'}
          </button>*/}
        </div>
      </main>
      <RightSide />
    </div>
  );
};

export default Login;
