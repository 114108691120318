import React, { useState, useEffect } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { doSignOut } from "../../firebase/auth";
import './nav.css';
import { setUser } from "../appSlice";
import { useDispatch, useSelector } from "react-redux";
import { SearchBar } from "./search/search";

export const Nav = () => {
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);
  const location = useLocation();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isCommunityDropdownOpen, setIsCommunityDropdownOpen] = useState(false);
  const [isWriteDropdownOpen, setIsWriteDropdownOpen] = useState(false);

  const user = useSelector(state =>state.app.user);

  const dispatch = useDispatch();

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.scrollY;
      setVisible(prevScrollPos > currentScrollPos || currentScrollPos < 50);  // Reappear on scroll up or if near the top
      setPrevScrollPos(currentScrollPos);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [prevScrollPos, visible,]);

  // Toggle dropdown for the user menu
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
    setIsCommunityDropdownOpen(false);
    setIsWriteDropdownOpen(false);
  };
  
  const toggleCommunityDropdown = () => {
    setIsCommunityDropdownOpen(!isCommunityDropdownOpen);
    setIsDropdownOpen(false);
    setIsWriteDropdownOpen(false);
  };
  
  const toggleWriteDropdown = () => {
    setIsWriteDropdownOpen(!isWriteDropdownOpen);
    setIsDropdownOpen(false);
    setIsCommunityDropdownOpen(false);
  };
  

  // Close dropdown when clicking a link
  const closeDropdown = () => {
    setIsDropdownOpen(false);
    setIsCommunityDropdownOpen(false);
    setIsWriteDropdownOpen(false);
  };

  const handleSignOut = () => {
    doSignOut().then(() => {
        dispatch(setUser(null)); // Set user in global state to null
        closeDropdown(); // Assuming closeDropdown is defined elsewhere
    }).catch(error => {
        console.error("Error during sign out:", error);
    });
};

  const isActive = (paths) => paths.some(path => location.pathname.includes(path));

  return (
    <div>
      <nav className='nav' style={{ top: visible ? '0' : '-115px', transition: 'top 0.35s' }}>
        <div id='logos'>
          <Link to={'/'}><img alt='site-logo' id='favLogo' src='/images/favlogotrans.png' /></Link>
          <Link to={'/'}><img alt='logo' id='Wlogo' src='/images/newlogotrans.png' /></Link>
          <div className="wide-search"><SearchBar /></div>
        </div>
        <div className="nav-list">
          <NavLink to={`stories`} className='navLink' onClick={closeDropdown} id="nav1">Read</NavLink>
          
          <div id="nav2" className={`navLink write-dropdown ${isActive(['/prompt-generators', '/get-idea', '/write-idea', '/story-elements']) ? 'active' : ''}`} onClick={toggleWriteDropdown}>
            Write
            <i className="fa fa-caret-down"></i>
            {isWriteDropdownOpen && (
              <div className="write-dropdown-content">
                <NavLink to={`prompt-generators`} id="nav3"  className='navLink' onClick={closeDropdown}>Prompts</NavLink>
                {/* <NavLink to={`get-idea`} className='navLink' onClick={closeDropdown}>Get Idea</NavLink> */}
                <NavLink to={`write-idea`} className='navLink'id="nav4"  onClick={closeDropdown}>Write Idea</NavLink>
                <NavLink to={'story-elements'} className='navLink' id="nav5" onClick={closeDropdown}>Story Elements</NavLink>
              </div>
            )}
          </div>

          <div id="nav6" className={`navLink community-dropdown ${isActive(['/users', '/forum', '/about', '/contests']) ? 'active' : ''}`} onClick={toggleCommunityDropdown}>
            Community
            <i className="fa fa-caret-down"></i>
            {isCommunityDropdownOpen && (
              <div className="community-dropdown-content">
                <NavLink to={`members`} id="nav7"className='navLink' onClick={closeDropdown}>Members</NavLink>
                <NavLink to={`forum`} id="nav8"className='navLink' onClick={closeDropdown}>Forum</NavLink>
                <NavLink to={'about'} id="nav9"className='navLink' onClick={closeDropdown}>About</NavLink>
                <NavLink to={'contests'} id="nav10"className='navLink' onClick={closeDropdown}>Contests</NavLink>
              </div>
            )}
          </div>

          <NavLink to={'blog'} id="nav4"className='navLink' onClick={closeDropdown}>Blog</NavLink>

          {!user && <NavLink to={'/login'} id="nav5" className='navLink'>Login</NavLink>}
          {user && (
            <div className="user-dropdown">
              <div id="nav5"className='navLink' onClick={toggleDropdown}>
                ~{user.username}
                <i className="fa fa-caret-down"></i>
              </div>
              {isDropdownOpen && (
                <div className="user-dropdown-content">
                  <NavLink to={`/${user.username}`} id="nav7"className='navLink' onClick={closeDropdown}>Profile</NavLink>
                  <NavLink to={`account`} id="nav8"className='navLink' onClick={closeDropdown}>Account</NavLink>
                  {user.username === 'dave' && <NavLink to={`admin`} id="nav9"className='navLink'>Admin</NavLink>}
                  {user.username === 'dave' && <NavLink to={`blog-builder`} id="nav2"className='navLink'>Blog Builder</NavLink>}
                  <div onClick={handleSignOut} id="nav10"className='navLink'>Log Out</div>
                </div>
              )}
            </div>
          )}
        </div>

        {/* Mobile Dropdown Navbar */}
        <span className="mobile-search"><SearchBar /></span>
        <div className="navbar">
          <div className={`dropdown ${isDropdownOpen ? "active" : ""}`}>
            <button className="dropbtn" onClick={toggleDropdown}>
              <img alt="" src="/images/favlogotrans.png" id='favLogo-mobile' />
              
              <i className="fa fa-caret-down"></i>
            </button>
            <div className="dropdown-content">
              <NavLink to={`/`} className='dropLink' onClick={closeDropdown}>Home</NavLink>
              <NavLink to={`stories`} className='dropLink' onClick={closeDropdown}>Read</NavLink>
              <NavLink to={`prompt-generators`} className='dropLink' onClick={closeDropdown}>Prompt Generators</NavLink>
              {user && (
                <>
                  <NavLink to={`/${user.username}`} className='dropLink' onClick={closeDropdown}>Profile</NavLink>
                  <NavLink to={`account`} className='dropLink' onClick={closeDropdown}>Account</NavLink>
                  {user.username === 'dave' && <NavLink to={`admin`} className='dropLink'>Admin</NavLink>}
                  
                </>
              )}
              {/* <NavLink to={`get-idea`} className='dropLink' onClick={closeDropdown}>Get an Idea</NavLink> */}
              <NavLink to={`write-idea`} className='dropLink' onClick={closeDropdown}>Write Your Idea</NavLink>
              <NavLink to={'story-elements'} className='dropLink' onClick={closeDropdown}>Story Elements</NavLink>
              <NavLink to={`members`} className='dropLink' onClick={closeDropdown}>Members</NavLink>
              <NavLink to={`forum`} className='dropLink' onClick={closeDropdown}>Forum</NavLink>
              <NavLink to={'about'} className='dropLink' onClick={closeDropdown}>About</NavLink>
              <NavLink to={'contests'} className='dropLink' onClick={closeDropdown}>Contests</NavLink>
              <NavLink to={'blog'} className='dropLink' onClick={closeDropdown}>Blog</NavLink>
              {user ? (
                <>
                  <div onClick={handleSignOut} className='dropLink'>Log Out</div>

                </>) : (
                  <NavLink to={'login'} className='dropLink' onClick={closeDropdown}>Login</NavLink>
                )}
              
            </div>
          </div>
        </div>
        
      </nav>
    </div>
  );
};
