import { addDoc, collection, getDocs } from "firebase/firestore";
import React, { useState, useEffect } from "react";
import { firestore } from "../../firebase/firebase";
import { useDispatch } from "react-redux";
import { setPotds } from "../../app/appSlice";

export const CreatePotd = ({ prompt, promptType }) => {
  const dispatch = useDispatch();
  const [isButtonDisabled, setIsButtonDisabled] = useState(false); // State to control button disable
  const [lastPrompt, setLastPrompt] = useState(prompt); // Track the last prompt to enable/disable button

  const handleSet = async () => {
    try {
      // Add new POTD document to Firestore
      const potdCollection = collection(firestore, 'potds');
      await addDoc(potdCollection, {
        prompt: prompt,
        promptType: promptType,
        time: new Date().toISOString(),
      });

      // Fetch updated POTDs from Firestore after adding the new one
      const querySnapshot = await getDocs(potdCollection);
      const updatedPotds = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));

      // Update global state with the new POTD list
      dispatch(setPotds(updatedPotds));

      // Show alert on successful submission
      alert('POTD successfully added!');

      // Disable the button until the prompt changes
      setIsButtonDisabled(true);
      setLastPrompt(prompt); // Track the current prompt to reset button on change
    } catch (error) {
      console.error("Error adding POTD:", error);
    }
  };

  // Re-enable the button if the prompt changes
  useEffect(() => {
    if (prompt !== lastPrompt) {
      setIsButtonDisabled(false); // Re-enable the button if the prompt changes
    }
  }, [prompt, lastPrompt]);

  return (
    <button
      id='button2'
      onClick={handleSet}
      disabled={isButtonDisabled} // Disable the button based on state
    >
      Set as POTD
    </button>
  );
};
