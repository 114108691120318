import { createSlice } from "@reduxjs/toolkit";

//removes Math.random from reducers
const generate = () => {
    let rando = {
        theme:[
            'honor','fate','willpower','greed','corruption',
            'love','beauty','death','rebirth','anarchy',
            'control','vengence','redemption','exploration','truth',
            'justice','mercy','madness','divinity','jealousy',
            'loss','betrayal', 'Hope','Identity','Friendship','Family','Adventure','Survival',
            'Self-discovery','Justice','Overcoming','Forgiveness','Connection','Growing',
            'Struggle','Perseverance','Freedom','Belonging','Courage','Imagination',
            'Transformation','Truth','Acceptance','Friendship','Growth','Confusion','Insight',
            'Self-acceptance','Self-improvement','Self-expression','Self-esteem',
            'Power','Control','Dominance','Submission','Oppression','Rebellion',
            "Isolation", "Sacrifice", "Legacy", "Betrayal", "Desire", "Prejudice", "Time", 
            "Rebellion", "Survival", "Neutrality", 
            "Humanity", "Obsession", "Dystopia", "Utopia", "Cultural Clash", 
            "Suffering", "Equilibrium", "Metamorphosis", "Chaos", "Destiny", 
            "Isolation", "Nostalgia", "Innocence", "Surrender", "Mortality",
            "Cyclical Nature", "Ambition", "Connection", "Ethical Dilemmas", 
            "Discovery", "Conformity", "Individualism", "Equality", "Inequality", "War",
            "Injustice", "Manipulation", "Censorship", "Knowledge and Ignorance", "Innovation",
            "Resistance", "Faith", "Secrecy", "Authority", "Fear", "Hopelessness", "Empowerment",
            "Rivalry", "Change", "Stagnation", "Survivor's Guilt", "Insecurity", "Obscurity",
            "Fanaticism", "Tolerance", "Nihilism", "Eternal Love", "Forgotten Past", "Transcendence",
            "Mystery", "Paranoia", "Second Chances", "Self-sacrifice", "Selfishness", "Enlightenment",
            "Manipulation", "Consequences", "Wisdom", "Purpose", "Stoicism", "Ecology", "Decay",
            "Renewal", "Moderation", "Passion", "Humility", "Arrogance", "Growth", "Tradition",
            "Progress", "Futility", "Fulfillment", "Regret", "Ephemeral Beauty", "Resistance",
            "Patriotism", "Duty", "Tradition", "Hubris", "Tragedy", "Irony", "Simplicity", "Complexity",
            "Futurism", "Nostalgia", "Unity", "Diversity", "Illusion", "Reality", "Illusion", "Perception",
            "Forgotten Knowledge", "Deception", "Legacy", "Heritage", "Indulgence", "Asceticism", 
            "Renunciation", "Chivalry", "Materialism", "Altruism", "Symbiosis", "Disintegration", 
            "Integration", "Innocence Lost", "Freedom",
        
        ],
        thing1: [
            'spaceship','moon','laser','bomb','sword',
            'magic potion','fairy','jungle cats','hurricane','mountain range',
            'killer whale','dagger','flame thrower','machine gun','suit of armour',
            'weapons chest','underground laboratory','toxic waste','love letter','bridge',
            'enchanted sword','enchanted flashlight','wicker basket','baseball bat','stone tools',
            'buried treasure','unearthly artifact','compass', 'time machine', 'spacesuit',
            'alien artifact', 'ancient tome', 'teleportation device', 'robot companion', 'orb of power',
            'dimensional gateway', 'flying carpet', 'phoenix feather', 'mechanical key',
            'invisibility cloak', 'crystal ball', 'telepathic communicator', 'mind-control device', 
            'hoverboard', 'elixir of immortality'
        ],
        thing2: [
            'birdcage','prison cell','narcotics','suitcase full of money',
            'taxi cab','rushing river','artificial intelligence','bears','compass',
            'guitar','penguins','flying saucer','droids','minivan',
            'keg','mansion','diamonds','handgun','chariot','spear','helmet','war memorabilia',
            'shotguns','rotting garbage','christmas tree','zoo animals','possessed doll','mysterious stranger',
            'secret passage','stolen artwork','encrypted USB drive',
            "Hidden diary", "Weathered map", "Creaky floorboards", "Mysterious letter",
            "Flickering streetlight", "Dusty attic", "Faded photograph", "Old bookstore",
            "Antique pocket watch", "Locked chest", "Empty train platform", "Abandoned theme park",
            "Cracked mirror", "Tattered journal", "Rustling leaves", "Vintage record player",
            "Crumbling ruins", "Worn-out teddy bear", "Foggy graveyard", "Neglected garden", 
             "Flickering candle", "Broken compass", "Distant laughter", 
            "Locked door", "Hidden passage", "treasure map", "Echoing footsteps", 
             "Shattered window", "Forgotten melody", "Wandering shadows", 
            "Empty swing set", "Rustic street sign", "Faint whispers", "Mysterious key", "Moonlit lake", 
            "Abandoned farmhouse", "Wandering cat", "Rumpled love letter", "Cracked spectacles", 
            "Lost limb", "Flickering neon sign", "Dilapidated mansion", "fireflies", 
            "Chipped tooth", "mountain pass", "Cracked sidewalk", "Worn-out suitcase",'cybernetic implant'
        ],
        people:[
            'feline humanoids','canine humanoids','punks','rednecks','street gangs',
            'musicians','office workers','royals','peasants','little green men',
            'aliens','demons','demigods','warriors','truckers',
            'computer club','pro athletes','super models','bank robbers','cyborgs',
            'office workers','paid actors','chimney sweepers','rodent men','villains',
            'spiritual warriors','maniacs','puppet masters','pilots','ninjas','tattoo artists',
            'shady people','aristocrats','corrupt officials','miners','striking union members',
            'fairies','political activists', 'androids', 'detectives', 'time travelers', 'mercenaries', 
            'scientists', 'journalists', 'explorers', 'undercover agents', 'artificial intelligences', 
            'vampires', 'zombies', 'clones', 'rebels', 'survivalists', 'pirates', 'bounty hunters', 
            'healers', 'bards', 'illusionists', 'timekeepers', 'bureaucrats', 'teachers', 'librarians', 
            'thieves', 'snipers', 'archaeologists', 'gamblers', 'spies', 'street performers', 
            'bartenders', 'surgeons', 'pilgrims', 'hackers', 'historians', 'illusionists', 'cartographers',
             'bakers', 'scavengers', 'vigilantes', 'inventors', 'explorers', 'traders', 'smugglers', 
             'plumbers', 'sherpas', 'con artists', 'bodyguards', 'acrobats', 'counselors', 'oracles', 
             'psychics', 'couriers', 'disguise artists', 'fortune tellers', 'gourmets', 'ghost hunters', 
             'hypnotists', 'mimes', 'necromancers', 'paranormal investigators', 'prophets', 
             'traveling circus performers'
        ],
        situation: [
            'street racing','setting sail','teenage rebellion','economic collapse','political revolution',
            'divorce','marriage','enslavement','escape','cheating death','vision quest',
            'trial by combat','magical education','cult initiation','brainwashing','rebuilding',
            'fire','war of extermination','alien invasion','first contact','apocalypse','hot pursuit',
            'world war','murder mystery', 'kidnapping', 'treasure hunt', 'time loop', 'identity theft', 
            'haunted house', 'lost civilization', 'technological singularity', 
            'body swapping', 'political conspiracy', 'amnesia', 
            'reincarnation', 'genetic manipulation', 'wild west showdown', 'new world', 'robot uprising', 
            'lost in space', 'underground resistance', 'murder for hire', 'cloning experiment', 
            'the afterlife', 'origin story', 'government experiment', 'time-traveling heist', 
            'trade war', 'undercover mission', 'journey within', 'battle of wits', 'utopia',
            'cursed artifact', 'cyber espionage', 'family reunion', 'worldwide pandemic',
            'AI uprising', 'psychic detective', 'lost in the wilderness', 'technological addiction',
            'black market dealings', 'interstellar romance', 'identity crisis', 'gladiator games', 
            'political intrigue', 'escape from a cult', 'forbidden love', 'revenge quest', 
             'journey to find a lost city', 'betrayal from within'
        ],

    }

    const random0 = Math.floor(Math.random() * rando.theme.length);
    const random1 = Math.floor(Math.random() * rando.thing1.length);
    const random2 = Math.floor(Math.random() * rando.thing2.length);
    const random3 = Math.floor(Math.random() * rando.people.length);
    const random4 = Math.floor(Math.random() * rando.situation.length);

    const theme =rando.theme[random0].toLowerCase();
    const thing1=rando.thing1[random1].toLowerCase();
    const thing2=rando.thing2[random2].toLowerCase();
    const people=rando.people[random3].toLowerCase();
    const situation=rando.situation[random4].toLowerCase();

    const items = {theme,thing1,thing2,people,situation};
    return items;
}


export const randomElementsSlice = createSlice({
    name: 'randomElements',
    initialState: {
        randomElements: '',
    },
    reducers: {
        randoGen: (state) => {
            

            let theme = generate().theme;
            let thing1 = generate().thing1
            let thing2 = generate().thing2
            let people = generate().people
            let situation = generate().situation

            state.randomElements = JSON.stringify(<div className = 'random text'>{theme}<br/>{thing1}<br/>{thing2}<br/>{people}<br/>{situation}</div>)

            //state.randomElements = {id: uuidv4(), prompt: {theme: theme,thing1: thing1,thing2: thing2,people: people,situation: situation}}

        },
        
    }
})



export const selectRandomElements = (state) => state.randomElements.randomElements;
export const selectSavedPrompts = (state) => state.randomElements.savedPrompts;
export default randomElementsSlice.reducer;
export const { randoGen, savePrompt, removePrompt } = randomElementsSlice.actions;