import React, { useState } from "react";
import { collection, addDoc } from "firebase/firestore";
import { firestore } from "../../firebase/firebase";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import "./createContest.css"; // Import the CSS file for styling

export const CreateContest = () => {
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [promptType, setPromptType] = useState("all");
    const [newPrompt, setNewPrompt] = useState("");
    const [prize, setPrize] = useState("");
    const [image, setImage] = useState(null); // State for image file
    const [minWordCount, setMinWordCount] = useState("");
    const [maxWordCount, setMaxWordCount] = useState("");

    const handleImageChange = (e) => {
        if (e.target.files[0]) {
            setImage(e.target.files[0]);
        }
    };

    const uploadImage = async () => {
        if (image) {
            const storage = getStorage();
            const imageRef = ref(storage, `prize-images/${image.name}`);
            await uploadBytes(imageRef, image);
            const url = await getDownloadURL(imageRef);
            return url;
        }
        return "";
    };

    const handleCreateContest = async () => {
        try {
            const imageUrl = await uploadImage();
            const contestsCollection = collection(firestore, 'contests');

            // Get today's date
            const today = new Date();
            // Determine if the contest should be open
            const isOpen = today <= new Date(endDate);

            await addDoc(contestsCollection, {
                title,
                description,
                minWordCount: parseInt(minWordCount, 10) || 0,
                maxWordCount: parseInt(maxWordCount, 10) || 0,
                startDate: startDate,
                endDate: endDate, 
                promptType: promptType,
                prompt: newPrompt ? newPrompt : '',
                prize,
                prizeImage: imageUrl, // Store the URL of the uploaded image
                open: isOpen
            });
            alert("Contest created successfully!");

            // Clear the input fields after successful submission
            setTitle("");
            setDescription("");
            setMinWordCount("");
            setMaxWordCount("");
            setStartDate("");
            setEndDate("");
            setPromptType("all");
            setNewPrompt("");
            setPrize("");
            setImage(null);
        } catch (error) {
            console.error("Error creating contest:", error);
            alert("Failed to create contest");
        }
    };

    return (
        <div className="create-contest">
            <h1>Create a New Contest</h1>
            <form onSubmit={(e) => { e.preventDefault(); handleCreateContest(); }}>
                <div className="form-group">
                    <label htmlFor="title">Title:</label>
                    <input 
                        id="title"
                        type="text" 
                        value={title} 
                        onChange={(e) => setTitle(e.target.value)} 
                        required 
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="description">Description:</label>
                    <textarea 
                        id="description"
                        value={description} 
                        onChange={(e) => setDescription(e.target.value)} 
                        required 
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="start-date">Start Date:</label>
                    <input 
                        id="start-date"
                        type="date" 
                        value={startDate} 
                        onChange={(e) => setStartDate(e.target.value)} 
                        required 
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="end-date">End Date:</label>
                    <input 
                        id="end-date"
                        type="date" 
                        value={endDate} 
                        onChange={(e) => setEndDate(e.target.value)} 
                        required 
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="prompt-type">Prompt Type:</label>
                    <select 
                        id="prompt-type"
                        value={promptType} 
                        onChange={(e) => setPromptType(e.target.value)}
                    >
                        <option value="all">All</option>
                        <option value="plot">Plot</option>
                        <option value="premise">Premise</option>
                        <option value="setting">Setting</option>
                        <option value="usp">USP</option>
                        <option value="character">Character</option>
                        <option value="random">Random</option>
                        <option value="unique">Unique</option>
                    </select>
                </div>
                {promptType === "unique" && (
                    <div className="form-group">
                        <label htmlFor="new-prompt">Or Input a New Prompt:</label>
                        <input 
                            id="new-prompt"
                            type="text" 
                            value={newPrompt} 
                            onChange={(e) => setNewPrompt(e.target.value)} 
                            placeholder="Enter a new prompt" 
                        />
                    </div>
                )}
                <div className="form-group">
                    <label htmlFor="prize">Prize:</label>
                    <input 
                        id="prize"
                        type="text" 
                        value={prize} 
                        onChange={(e) => setPrize(e.target.value)} 
                        placeholder="Enter the prize for the contest"
                        required 
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="min-word-count">Minimum Word Count:</label>
                    <input 
                        id="min-word-count"
                        type="number" 
                        value={minWordCount} 
                        onChange={(e) => setMinWordCount(e.target.value)} 
                        placeholder="Minimum word count"
                        min="0"
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="max-word-count">Maximum Word Count:</label>
                    <input 
                        id="max-word-count"
                        type="number" 
                        value={maxWordCount} 
                        onChange={(e) => setMaxWordCount(e.target.value)} 
                        placeholder="Maximum word count"
                        min="0"
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="prize-image">Prize Image:</label>
                    <input 
                        id="prize-image"
                        type="file" 
                        accept="image/*" 
                        onChange={handleImageChange} 
                    />
                </div>
                <button type="submit">Create Contest</button>
            </form>
        </div>
    );
};
