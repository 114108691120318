import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

export const ReadNext = ({ currentStory }) => {
    const [selection, setSelection] = useState("sameAuthor");
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const { stories } = useSelector(state => state.app);

    const handleSelectionChange = (e) => {
        setSelection(e.target.value);
    };

    const handleReadNext = () => {
        setIsLoading(true);

        let filteredStories;

        // Ensure genres are always treated as arrays
        const currentGenres = Array.isArray(currentStory.genres) ? currentStory.genres : [currentStory.genres];

        switch (selection) {
            case "samePrompt":
                filteredStories = stories.filter(story => {
                    return story.uid !== currentStory.uid && story.promptId === currentStory.promptId
                });
                break;
            case "sameGenre":
                filteredStories = stories.filter(story => {
                    const storyGenres = Array.isArray(story.genres) ? story.genres : [story.genres];
                    return story.uid !== currentStory.uid && storyGenres.some(genre => currentGenres.includes(genre));
                });
                break;
            case "samePromptType":
                filteredStories = stories.filter(story =>
                    story.promptType === currentStory.promptType && story.uid !== currentStory.uid
                );
                break;
            case "sameAuthor":
                filteredStories = stories.filter(story =>
                    story.user === currentStory.user && story.uid !== currentStory.uid
                );
                break;
            case "different":
                filteredStories = stories.filter(story =>
                    story.promptType !== currentStory.promptType &&
                    story.user !== currentStory.user &&
                    (!Array.isArray(story.genres) ? story.genres !== currentGenres[0] : !story.genres.some(genre => currentGenres.includes(genre)))
                );
                break;
            case "contest":
                filteredStories = stories.filter(story =>
                    story.contest === currentStory.contest && story.uid !== currentStory.uid
                );
                break;
            default:
                filteredStories = [];
                break; // Add the break here to prevent fall-through
        }

        if (filteredStories.length > 0) {
            const randomStory = filteredStories[Math.floor(Math.random() * filteredStories.length)];
            navigate(`/${randomStory.user}/${randomStory.id}`);
        } else {
            alert("No other stories found based on your selection.");
        }

        setIsLoading(false);
    };

    return (
        <div className="read-next-page">
            {isLoading &&
                <div className="loading-screen">
                    <div className="loading-overlay"></div>
                    <img src="/images/favlogotrans.png" alt="Loading..." className="loading-image" />
                </div>}
            <h3>Want to read another story?</h3>
            <select className='read-next-select' value={selection} onChange={handleSelectionChange}>
                {currentStory.promptType==='user' && <option value="samePrompt">With the same prompt?</option>}
                <option value="sameAuthor">By the same author?</option>
                <option value="sameGenre">In the same genre?</option>
                <option value="samePromptType">With the same prompt type?</option>
                <option value="contest">In the same contest?</option>
                <option value="different">Something different?</option>
            </select>
            <button className="reply-submit" onClick={handleReadNext} disabled={isLoading}>
                {isLoading ? "Finding a story..." : "Read Next"}
            </button>
        </div>
    );
};
