import React, { useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { doc, updateDoc } from "firebase/firestore";
import { Pagination } from "@mui/material";
import { firestore } from "../../firebase/firebase";
import { setUsers } from "../../app/appSlice";
import UserPointsBreakdown from "./pointsBreakdown"; // Import the breakdown component

const UserManagement = () => {
    const dispatch = useDispatch();
    const users = useSelector(state => state.app.users); // Assuming users are stored in state.app.users
    const activity = useSelector(state => state.app.activity); // Assuming activity is stored in state.app.activity
    const stories = useSelector(state => state.app.stories); // Assuming stories are stored in state.app.stories
    const forumAnswers = useSelector(state => state.app.forumAnswers); // Assuming forum answers are stored in state.app.forumAnswers

    const [searchTerm, setSearchTerm] = useState("");
    const [selectedUser, setSelectedUser] = useState(null);
    const [editData, setEditData] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 5; // Set items per page for pagination

    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
        setSelectedUser(null);
        setCurrentPage(1); // Reset to page 1 on search
    };

    const handleSelectUser = (user) => {
        setSelectedUser(user);
        setEditData(user);
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setEditData(prevData => ({ ...prevData, [name]: value }));
    };

    const handleSave = async () => {
        const userDoc = doc(firestore, "users", selectedUser.id);
        await updateDoc(userDoc, editData);

        // Update local state after successful Firestore update
        dispatch(setUsers(users.map(user => user.id === selectedUser.id ? { ...user, ...editData } : user)));
        setSelectedUser(null); // Clear selected user after saving
    };

    const filteredUsers = useMemo(() => 
        users.filter(user =>
            user.username.toLowerCase().includes(searchTerm.toLowerCase())
        ),
    [users, searchTerm]);

    // Paginate users
    const paginatedUsers = useMemo(() =>
        filteredUsers.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage),
    [filteredUsers, currentPage]);

    // Calculate total pages
    const totalPages = Math.ceil(filteredUsers.length / itemsPerPage);

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    return (
        <div className="user-management">
            <h1>Users</h1>
            <input
                type="text"
                placeholder="Search users..."
                value={searchTerm}
                onChange={handleSearch}
            />
            <div className="user-list">
                {filteredUsers.length === 0 ? (
                    <p>No users found.</p>
                ) : (
                    paginatedUsers.map(user => (
                        <div key={user.id} onClick={() => handleSelectUser(user)}>
                            {user.username}
                        </div>
                    ))
                )}
            </div>
            {selectedUser && (
                <div className="user-edit">
                    <h2>Edit User</h2>
                    <p>{editData.uid}</p>
                    <form>
                        <label>
                            Pen Name:
                            <input
                                type="text"
                                name="penName"
                                value={editData.penName || ''}
                                onChange={handleChange}
                            />
                        </label>
                        <label>
                            Email:
                            <input
                                type="email"
                                name="email"
                                value={editData.email || ''}
                                onChange={handleChange}
                            />
                        </label>
                        <label>
                            Bio:
                            <input
                                type="text"
                                name="bio"
                                value={editData.bio || ''}
                                onChange={handleChange}
                            />
                        </label>
                        <label>
                            Location:
                            <input
                                type="text"
                                name="location"
                                value={editData.location || ''}
                                onChange={handleChange}
                            />
                        </label>
                        <label>
                            Joined:
                            <input
                                type="text"
                                name="joined"
                                value={editData.joined || ''}
                                onChange={handleChange}
                            />
                        </label>
                        <label>
                            Points:
                            <input
                                type="number"
                                name="points"
                                value={editData.points || ''}
                                onChange={handleChange}
                            />
                        </label>
                        <label>
                            Rank:
                            <input
                                type="text"
                                name="rank"
                                value={editData.rank || ''}
                                onChange={handleChange}
                            />
                        </label>
                        {/* Add more fields as needed */}
                        <button type="button" onClick={handleSave}>Save</button>
                    </form>
                    {/* Add the UserPointsBreakdown component */}
                    <UserPointsBreakdown
                        profile={selectedUser}
                        activity={activity.filter(a => a.user === selectedUser.username)}
                        stories={stories.filter(story => story.user === selectedUser.username)}
                        forumAnswers={forumAnswers.filter(a => a.user === selectedUser.username)}
                    />
                </div>
            )}
            {totalPages > 1 && (
                <div className="pagination">
                    <Pagination
                        count={totalPages}
                        page={currentPage}
                        onChange={handlePageChange}
                        color="primary"
                        style={{ marginTop: "20px", display: "flex", justifyContent: "center" }}
                    />
                </div>
            )}
        </div>
    );
};

export default UserManagement;
