import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import './blogHome.css'

export const BlogHome = () => {
    useEffect(()=>{
        window.scrollTo(0,0)

    },[])
    return(

        <div id='blogHome'>
                <div className="blog-head">
                    <Link to='welcome-to-writician' className='blogLink'>
                        <div  className='blog-home-top'id='blogTop1'>
                            <img className='blogImage' src='/images/assortedpics/amandine-bataille-5W78I6Jvbjw-unsplash.jpg' alt='typewriter'/>
                            <div className = 'blogHeader'>
                                <div className='blogTitle'>Welcome to Writician!</div>
                                <div className='blogHook'>
                                    <p>
                                    If you're reading this then you're one of the first lucky ones who've discovered our site; 
                                    a new space for creative writers to learn, share, and grow together. 
                                    We've got a lot of plans for the site, and we're hoping we can make this journey together with all of you...
                                    </p>
                                </div>
                                <div className='blogDate'>Nov 1, 2024</div>

                            </div>
                        </div>
                    </Link>
                </div>
                <div className="blog-head">
                    <Link to='are-you-a-gardener-or-an-architect' className='blogLink'>
                        <div className='blog-home-top' id='blogTop2'>
                            <img className='blogImage' src='../images/blog2.png' alt='building next to garden'/>
                            <div className = 'blogHeader'>
                                <div className='blogTitle'>Are you a Gardener or an Architect?</div>
                                <div className='blogHook'>Find out your plotting style and what it means for your next writing project.</div>
                                <div className='blogDate'>Nov 12,2024</div>

                            </div>
                        </div>
                    </Link> 
                </div>
                <div className="blog-head">
                    <Link to='creative-writing-exercise-1' className='blogLink'>
                        <div className='blog-home-top'id='blogTop3'>
                            <img className='blogImage' src='/images/magic.jpg' alt='book with magic coming to life'/>
                            <div className = 'blogHeader'>
                                <div className='blogTitle'>Creative Writing Exercise #1</div>
                                <div className='blogHook'>Magical abilities in fantasy based on world-building.</div>
                                <div className='blogDate'>Nov 19,2024</div>

                            </div>
                        </div>
                    </Link>
                </div>
                    
        </div>
    )
}



                    


                