import React, { useState } from "react";
import { collection, addDoc } from "firebase/firestore";
import { firestore } from "../../firebase/firebase";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";

export const CreatePromptContest = ({ prompt, promptType }) => {
    const [showPopup, setShowPopup] = useState(false);
    const [formValues, setFormValues] = useState({
        title: '',
        description: '',
        startDate: '',
        endDate: '',
        promptType: promptType,
        prompt: JSON.stringify(prompt),
        prize: '',
        image: null,
        minWordCount: '',
        maxWordCount: ''
    });


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        
        // Handle minWordCount and maxWordCount as numbers
        if (name === "minWordCount" || name === "maxWordCount") {
            setFormValues(prev => ({
                ...prev,
                [name]: value ? parseInt(value, 10) : ''
            }));
        } else {
            setFormValues(prev => ({
                ...prev,
                [name]: value
            }));
        }
    };

    const handleImageChange = (e) => {
        if (e.target.files[0]) {
            setFormValues(prev => ({
                ...prev,
                image: e.target.files[0]
            }));
        }
    };

    const uploadImage = async () => {
        if (formValues.image) {
            const storage = getStorage();
            const imageRef = ref(storage, `prize-images/${formValues.image.name}`);
            await uploadBytes(imageRef, formValues.image);
            const url = await getDownloadURL(imageRef);
            return url;
        }
        return "";
    };

    const handleCreateContest = async () => {
        try {
            const imageUrl = await uploadImage();
            const contestsCollection = collection(firestore, 'contests');
            await addDoc(contestsCollection, {
                title: formValues.title,
                minWordCount: formValues.minWordCount || 0,
                maxWordCount: formValues.maxWordCount || 0,
                description: formValues.description,
                startDate: new Date(formValues.startDate).toISOString(),
                endDate: new Date(formValues.endDate).toISOString(),
                promptType: promptType,
                prompt: prompt,
                prize: formValues.prize,
                prizeImage: imageUrl, // Store the URL of the uploaded image
            });
            alert("Contest created successfully!");
            setShowPopup(false);
            // Clear form fields
            setFormValues({
                title: '',
                description: '',
                startDate: '',
                endDate: '',
                promptType: promptType,
                prompt: JSON.stringify(prompt),
                prize: '',
                image: null,
                minWordCount: '',
                maxWordCount: ''
            });
        } catch (error) {
            console.error("Error creating contest:", error);
            alert("Failed to create contest");
        }
    };

    return (
        <div>
           <button id='button2' onClick={() => setShowPopup(true)}>Create Prompt Contest</button>

            {showPopup && (
                <div className="popup">
                    <div className="popup-content">
                        <h1>Create Prompt Contest</h1>
                        <form onSubmit={(e) => { e.preventDefault(); handleCreateContest(); }}>
                            <div>
                                <label>Title:</label>
                                <input 
                                    type="text" 
                                    name="title" 
                                    value={formValues.title} 
                                    onChange={handleInputChange} 
                                    required 
                                />
                            </div>
                            <div>
                                <label>Description:</label>
                                <textarea 
                                    name="description" 
                                    value={formValues.description} 
                                    onChange={handleInputChange} 
                                    required 
                                />
                            </div>
                            <div>
                                <label>Start Date:</label>
                                <input 
                                    type="date" 
                                    name="startDate" 
                                    value={formValues.startDate} 
                                    onChange={handleInputChange} 
                                    required 
                                />
                            </div>
                            <div>
                                <label>End Date:</label>
                                <input 
                                    type="date" 
                                    name="endDate" 
                                    value={formValues.endDate} 
                                    onChange={handleInputChange} 
                                    required 
                                />
                            </div>
                            <div>
                                <label>Prize:</label>
                                <input 
                                    type="text" 
                                    name="prize" 
                                    value={formValues.prize} 
                                    onChange={handleInputChange} 
                                    placeholder="Enter the prize for the contest"
                                    required 
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="min-word-count">Minimum Word Count:</label>
                                <input 
                                    id="min-word-count"
                                    type="number" 
                                    name="minWordCount"
                                    value={formValues.minWordCount} 
                                    onChange={handleInputChange} 
                                    placeholder="Minimum word count"
                                    min="0"
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="max-word-count">Maximum Word Count:</label>
                                <input 
                                    id="max-word-count"
                                    type="number" 
                                    name="maxWordCount"
                                    value={formValues.maxWordCount} 
                                    onChange={handleInputChange} 
                                    placeholder="Maximum word count"
                                    min="0"
                                />
                            </div>
                            <div>
                                <label>Prize Image:</label>
                                <input 
                                    type="file" 
                                    accept="image/*" 
                                    onChange={handleImageChange} 
                                />
                            </div>
                            <button type="submit">Create Contest</button>
                            <button type="button" onClick={() => setShowPopup(false)}>Cancel</button>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
};
