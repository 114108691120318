// AdminMessages.js
import React, { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import './admin.css';
import { firestore } from "../../firebase/firebase";

export const AdminMessages = () => {
    const [messagesByCategory, setMessagesByCategory] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
    const [openCategories, setOpenCategories] = useState({});

    useEffect(() => {
        const fetchMessages = async () => {
            try {
                const querySnapshot = await getDocs(collection(firestore, "messages"));
                const messagesData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

                // Group messages by category
                const groupedMessages = messagesData.reduce((acc, message) => {
                    const { category } = message;
                    if (!acc[category]) {
                        acc[category] = [];
                    }
                    acc[category].push(message);
                    return acc;
                }, {});

                setMessagesByCategory(groupedMessages);
            } catch (err) {
                console.error("Error fetching messages:", err);
                setError("Failed to load messages. Please try again.");
            } finally {
                setLoading(false);
            }
        };

        fetchMessages();
    }, []);

    const toggleCategory = (category) => {
        setOpenCategories((prevState) => ({
            ...prevState,
            [category]: !prevState[category],
        }));
    };

    if (loading) {
        return <p>Loading messages...</p>;
    }

    if (error) {
        return <p className="error">{error}</p>;
    }

    return (
        <div className="message-list">
            <h3>Submitted Messages</h3>
            {Object.keys(messagesByCategory).length === 0 ? (
                <p>No messages submitted yet.</p>
            ) : (
                Object.keys(messagesByCategory).map((category) => (
                    <div key={category}>
                        <h4 onClick={() => toggleCategory(category)} style={{ cursor: 'pointer' }}>
                            {category} {openCategories[category] ? '▲' : '▼'}
                        </h4>
                        {openCategories[category] && (
                            <div className="message-dropdown">
                                {messagesByCategory[category].map(message => (
                                    <div key={message.id} className="message-item">
                                        <p><strong>Subject:</strong> {message.subject}</p>
                                        <p><strong>From:</strong> {message.username}</p>
                                        <p><strong>Message:</strong> {message.body}</p>
                                        <p><strong>Submitted On:</strong> {new Date(message.time).toLocaleString()}</p>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                ))
            )}
        </div>
    );
};
