import React, { useEffect, useState } from "react";
import "./genreSelector.css"; // Add your CSS styles for visuals
import { setStoryGenres } from "../../pages/prompts/writePrompt/writePromptSlice";
import { useDispatch } from "react-redux";



export const GenreSelector = ({ genres, onGenreChange, iconStyle }) => {
  const [selectedGenres, setSelectedGenres] = useState([]);
  const dispatch = useDispatch();

  useEffect(()=> {
    dispatch(setStoryGenres(selectedGenres));

  },[selectedGenres, dispatch])

  const handleGenreClick = (genre) => {
    if (selectedGenres.includes(genre)) {
      // If already selected, remove it
      const updatedGenres = selectedGenres.filter((g) => g !== genre);
      setSelectedGenres(updatedGenres);
      onGenreChange && onGenreChange(updatedGenres);
      dispatch(setStoryGenres(selectedGenres));

    } else if (selectedGenres.length < 2) {
      // Add genre if less than 2 are selected
      const updatedGenres = [...selectedGenres, genre];
      setSelectedGenres(updatedGenres);
      onGenreChange && onGenreChange(updatedGenres);
      dispatch(setStoryGenres(selectedGenres));
    }
  };

  return (
    <div className="genre-selector">
      {genres.map((genre) => (
        <div 
          key={genre} 
          className={`genre-icon-container ${selectedGenres.includes(genre) ? "selected" : ""}`} 
          onClick={() => handleGenreClick(genre)}
        >
          <img
            loading="lazy"
            className="genre-selector-icon"
            alt={genre}
            src={`/images/genre-icons/${genre}.png`}
            style={iconStyle}
            title={genre}
          />
          {selectedGenres.includes(genre) && <span className="dot-selector"></span>}
        </div>
      ))}
    </div>
  );
};
