import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Selector } from "./selector";
import { plotGen } from "./plotPromptSlice";
import { selectPlotPrompt,displayPlot } from "./plotPromptSlice";
import { All } from "./all";
import { useState } from "react";
import { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { setPrompt } from "../writePrompt/writePromptSlice";
import { SavePrompt } from "../savePrompt";
import { CreatePromptContest } from "../createPromptContest";
import JsonToReact from "../../../utilities/jsonToReact";
import { CreatePotd } from "../../admin/createPotd";

export default function PlotPrompt(){

    const {user} = useSelector(state => state.app)
    const dispatch = useDispatch();
    const plotPrompt = useSelector(selectPlotPrompt);
    const prompt = plotPrompt.prompt;

    const [everything, setEverything] = useState(false)
    const [save, setSave] = useState(false);
    const [style, setStyle] = useState({});
    
    useEffect(()=>{
        if(prompt !== ''){
            setSave(true);

            const style1 = {
                backgroundColor: 'white',
                padding: '2rem'
            }
            setStyle(style1);
        }
    },[prompt])


    const handleClick = () => {
        dispatch(plotGen());
        dispatch(displayPlot())
        save? setSave(false): setSave(true)
        
    }
    
    const handleEverything = () => {
        if(everything){
            setEverything(false)
            document.getElementById('seeAll').innerHTML='See all story elements.'
        } else {
        setEverything(true);
        document.getElementById('seeAll').innerHTML='Hide all story elements.'
    }}

    const handleWrite =()=>{
        dispatch(setPrompt({type:'plot', prompt: prompt}));
    }

    return(
        <div id="plotPrompt">
            <Selector /> 
            {save && <div id='plotP' style={style}>
            {prompt && JsonToReact({ jsonString: prompt})}
                {user && <NavLink to={'/writePrompt'}> <button id='button2' onClick={()=> handleWrite()}>Write It</button></NavLink>}
                {user?.admin && 
                <>
                    <CreatePromptContest prompt={prompt} promptType='plot'/>
                    <CreatePotd prompt={prompt} promptType='plot'/>
                </>
                }
            </div>}
            
            <button id='button1' onClick={()=> handleClick()}>Generate New Plot</button>
            {save && user && <SavePrompt prompt={prompt} type={'plot'}/>}


            <div id='seeAllBox'> 
                <a href='#mainAll'><p id='seeAll' onClick={()=> handleEverything()}>
                See all story elements.
                </p></a>
            </div>
            {everything && <All />}
        </div>
    )
}