import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getAuth, createUserWithEmailAndPassword, updateProfile, sendEmailVerification } from 'firebase/auth';
import { LeftSide } from '../../app/leftSide/leftSide';
import { RightSide } from '../../app/rightSide/rightSide';
import { setDoc, doc, query, where, getDocs, collection } from 'firebase/firestore';
import { firestore } from "../../firebase/firebase";
import Filter from 'bad-words';
import VerifyEmailScreen from './verifyEmailScreen';
import './register.css';
import { useDispatch, useSelector } from 'react-redux';
import { setUser, setUsers } from '../../app/appSlice';

const Register = () => {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isRegistering, setIsRegistering] = useState(false);
  const [registrationComplete, setRegistrationComplete] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [emailVerificationSent, setEmailVerificationSent] = useState(false);

  const {user, users} = useSelector(state => state.app);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (user && registrationComplete) {
      const isUserInUsers = users.some(u => u.username === user.username);
      if (isUserInUsers) {
        navigate(`/${user.username}`);
      }
    }
  }, [user, users, navigate, registrationComplete]); // Only navigate when `user` is confirmed in `users`

  const filter = new Filter();
  filter.removeWords('butt', 'hell', 'hells', 'ass');

  const isUsernameTaken = async (username) => {
    try {
      const usersRef = collection(firestore, "users");
      const q = query(usersRef, where("username", "==", username));
      const querySnapshot = await getDocs(q);
      return !querySnapshot.empty;
    } catch (error) {
      console.error('Error checking username:', error);
      setErrorMessage('Error checking username');
      return false;
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      setErrorMessage('Passwords do not match');
      return;
    }

    if (filter.isProfane(username)) {
      setErrorMessage('Username contains inappropriate language');
      return;
    }

    const usernamePattern = /^[a-zA-Z0-9_-]+$/;
    if (!usernamePattern.test(username) || username.length > 20) {
      setErrorMessage('Username must contain only letters, numbers, underscores, or dashes, and be no more than 20 characters long');
      return;
    }

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(email)) {
      setErrorMessage('Please enter a valid email address');
      return;
    }

    if (!isRegistering) {
      setIsRegistering(true);
      try {
        if (await isUsernameTaken(username)) {
          setErrorMessage('Username is already taken');
          setIsRegistering(false);
          return;
        }

        const auth = getAuth();
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        const user = userCredential.user;
        await updateProfile(user, { displayName: username });
        await sendEmailVerification(user);
        setEmailVerificationSent(true);
        setErrorMessage('Verification email sent. Please check your inbox.');

        const newUserRef = doc(firestore, `users/${username}`);
        const userData = {
          username, 
          email, 
          uid: user.uid,
          bio: '',
          location: '',
          penName: '',
          joined: new Date().toISOString(),
          points: 0,
          bonus: 0,
          rank: 'Novice',
          premiumTier: 0,
          favorites: [],
          savedPrompts: [],
          profileImageUrl: 'https://firebasestorage.googleapis.com/v0/b/writician-64638.appspot.com/o/profileImages%2Ffavlogotrans.png?alt=media&token=7865ee45-96cb-4fa5-ae8e-de1203672341'
        };
        
        await setDoc(newUserRef, userData);
        // Dispatch to save user data in Redux store
      } catch (error) {
        console.error('Error during registration:', error);
        setErrorMessage(error.message);
      } finally {
        setIsRegistering(false);
      }
    }
  };

  const handleVerified = async() => {
    setRegistrationComplete(true);
    const userData = {
      username,
      email,
      uid: getAuth().currentUser.uid,
      bio: '',
      location: '',
      penName: '',
      joined: new Date().toISOString(),
      points: 0,
      bonus: 0,
      rank: 'Novice',
      premiumTier: 0,
      favorites: [],
      savedPrompts: [],
      profileImageUrl: 'https://firebasestorage.googleapis.com/v0/b/writician-64638.appspot.com/o/profileImages%2Ffavlogotrans.png?alt=media&token=7865ee45-96cb-4fa5-ae8e-de1203672341'
    };
      dispatch(setUser(userData));
      try {
        // Fetch updated user list from Firestore
        const usersSnapshot = await getDocs(collection(firestore, "users"));
        const updatedUsers = usersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

        // Dispatch the updated user list to Redux
        dispatch(setUsers(updatedUsers));
    } catch (error) {
        console.error("Failed to fetch updated users:", error);
    }  
  };

  if (emailVerificationSent && !registrationComplete) {
    return <VerifyEmailScreen onVerified={handleVerified} />;
  }

  return (
    <div className='page'>
      <LeftSide />
      <main className="register-container">
        <div className="register-form-container">
          <div className="register-header">
            <header>
              <h1>Create a New Account</h1>
            </header>
          </div>
          <form onSubmit={onSubmit} className="register-form">
            <div className="register-field">
              <label>Username</label>
              <input
                type="text"
                autoComplete='off'
                required
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>
            <div className="register-field">
              <label>Email</label>
              <input
                type="email"
                autoComplete='off'
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="register-field">
              <label>Password</label>
              <input
                type="password"
                autoComplete='new-password'
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className="register-field">
              <label>Confirm Password</label>
              <input
                type="password"
                autoComplete='off'
                required
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </div>
            {errorMessage && <span className='error-message'>{errorMessage}</span>}
            <button
              id='login-button'
              type="submit"
              disabled={isRegistering}
              className={`submit-button ${isRegistering ? 'disabled' : ''}`}
            >
              {isRegistering ? 'Signing Up...' : 'Sign Up'}
            </button>
          </form>
          <p className="login-link">
            Already have an account? <Link to={'/login'}>Continue</Link>
          </p>
        </div>
      </main>
      <RightSide />
    </div>
  );
};

export default Register;
