import React, { useEffect, useState } from 'react';
import { collection, deleteDoc, doc, getDoc, getDocs, updateDoc } from 'firebase/firestore';
import { firestore } from '../../firebase/firebase';
import { Link } from 'react-router-dom';
import {formatDate} from '../../utilities/formatDate'
export const Flags = () => {
    const [flags, setFlags] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchFlags = async () => {
            try {
                // Fetch all documents in the 'flags' collection
                const flagsCollection = collection(firestore, 'flags');
                const flagsSnap = await getDocs(flagsCollection);

                // Map documents into an array of flag objects
                const flagsData = flagsSnap.docs.map(doc => ({ id: doc.id, ...doc.data() }));

                // Set the state with the flags array
                setFlags(flagsData); 
            } catch (err) {
                console.error("Failed to fetch flags:", err);
                setError("Failed to fetch flags");
            } finally {
                setIsLoading(false);
            }
        };

        fetchFlags();
    }, []);

    const deleteFlag = async (index) => {
        try {
            // Get the flag to delete based on the index
            const flagToDelete = flags[index];
            
            if (!flagToDelete || !flagToDelete.id) {
                console.error("Invalid flag data or missing ID");
                setError("Invalid flag data or missing ID");
                return;
            }
    
            // Step 1: Delete the specific flag document from the `flags` collection
            const flagDocRef = doc(firestore, 'flags', flagToDelete.id);
            await deleteDoc(flagDocRef); 
    
            // Step 2: Update the specific user's story flag
            if (flagToDelete.storyId) {
                const userStoryDoc = doc(firestore, 'stories', flagToDelete.storyId);
                const userStorySnap = await getDoc(userStoryDoc);
    
                if (userStorySnap.exists()) {
                    const userStoryFlags = userStorySnap.data().flags || [];
                    const updatedUserFlags = userStoryFlags.filter(
                        userFlag => userFlag.date !== flagToDelete.date
                    );
                    await updateDoc(userStoryDoc, { flags: updatedUserFlags });
                }
            }
    
            // Step 3: Update the local state
            const updatedFlags = [...flags];
            updatedFlags.splice(index, 1);
            setFlags(updatedFlags);
        } catch (err) {
            console.error("Failed to delete flag:", err);
            setError("Failed to delete flag");
        }
    };
    

    if (isLoading) return <div>Loading...</div>;
    if (error) return <div>{error}</div>;

    return (
        <div className="flags-container">
            <h1>Flags</h1>
            {flags.length > 0 ? (
                <ul>
                    {flags.map((flag, index) => (
                        <li key={flag.id || index} className="flag-item"> {/* Use index as fallback */}
                            <Link to={`/${flag.author}/${flag.storyId}`}>
                                <h4>{flag.title}</h4>
                            </Link>
                            <p><strong>Type:</strong> {flag.type}</p>
                            <p><strong>Date:</strong> {formatDate (flag.date)}</p>
                            <p><strong>User:</strong> {flag.user}</p>
                            <p><strong>Author:</strong> {flag.author}</p>
                            <button className='delete' onClick={() => deleteFlag(index)}>Delete</button>
                        </li>
                    ))}
                </ul>
            ) : (
                <p>No flags found</p>
            )}
        </div>
    );
};
