import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectCharacterPrompt, selectElements, displayPrompt } from "./characterPromptSlice";
import { useDispatch } from "react-redux";

export const CharacterSelector =()=> {

    const characterPrompt = useSelector(selectCharacterPrompt);
    const dispatch = useDispatch();

    const [count, setCount] = useState(0);

    // useEffect to calculate the number of selected checkboxes when plotPrompt changes
    useEffect(() => {
        let selectedCount = 0;
        Object.keys(characterPrompt).forEach((key) => {
            if (characterPrompt[key] === true) selectedCount++;
        });
        setCount(selectedCount); // Update the count
    }, [characterPrompt]);
    
    const handleChange = (item) => {
        const isChecked = characterPrompt[item]; // Current state of the checkbox

        if (isChecked && count === 2) {
            return; 
        }

        dispatch(selectElements(item));
        if(characterPrompt.prompt!== ''){
            dispatch(displayPrompt());
        }
    }
            const hairStyle = characterPrompt.hs;
            const hairLength = characterPrompt.hl;
            const hairColor = characterPrompt.hc;
            const height = characterPrompt.ht;
            const weight = characterPrompt.wt;
            const skinColor = characterPrompt.sc;
            const look = characterPrompt.lk;
            const accessories = characterPrompt.acc;
            const defining = characterPrompt.def;
            const enneagram = characterPrompt.enn;
            //const archetype = characterPrompt.arch;
            const species = characterPrompt.spe;
        return(
            <div>
                <fieldset className="selector">
                    <legend>Choose your Character Elements</legend>
                    <div className='check'>
                        <div>
                            <input type="checkbox" id="hairStyle" name="hairStyle" 
                                value="hairStyle" checked={hairStyle} onChange={()=> handleChange('hairStyle')} />
                            <label htmlFor="hairStyle">hair style</label>
                        </div>
                        <div>
                            <input type="checkbox" id="hairLength" name="hairLength" 
                                value="hairLength" checked={hairLength} onChange={()=> handleChange('hairLength')} />
                            <label htmlFor="hairLength">hair length</label>
                        </div>
                        <div>
                            <input type="checkbox" id="hairColor" name="hairColor" 
                                value="hairColor" checked={hairColor} onChange={()=> handleChange('hairColor')} />
                            <label htmlFor="hairColor">hair color</label>
                        </div>
                        <div>
                            <input type="checkbox" id="height" name="height" 
                                value="height" checked={height} onChange={()=> handleChange('height')} />
                            <label htmlFor="height">height</label>
                        </div>
                        <div>
                            <input type="checkbox" id="weight" name="weight" 
                                value="weight" checked={weight} onChange={()=> handleChange('weight')} />
                            <label htmlFor="weight">body type</label>
                        </div>
                        <div>
                            <input type="checkbox" id="skinColor" name="skinColor" 
                                value="skinColor" checked={skinColor} onChange={()=> handleChange('skinColor')} />
                            <label htmlFor="skinColor">skin tone</label>
                        </div>
                    </div>
                    <div className='check'>

                        <div>
                            <input type="checkbox" id="look" name="look" 
                                value="look" checked={look} onChange={()=> handleChange('look')} />
                            <label htmlFor="look">look</label>
                        </div>
                        
                        <div>
                            <input type="checkbox" id="defining" name="defining" 
                                value="defining" checked={defining} onChange={()=> handleChange('defining')} />
                            <label htmlFor="defining">defining</label>
                        </div>
                        <div>
                            <input type="checkbox" id="accessories" name="accessories" 
                                value="accessories" checked={accessories} onChange={()=> handleChange('accessories')} />
                            <label htmlFor="accessories">accessories</label>
                        </div>
                        <div>
                            <input type="checkbox" id="enneagram" name="enneagram" 
                                value="enneagram" checked={enneagram} onChange={()=> handleChange('enneagram')} />
                            <label htmlFor="enneagram">enneagram</label>
                        </div>
                        <div>
                            <input type="checkbox" id="species" name="species" 
                                value="species" checked={species} onChange={()=> handleChange('species')} />
                            <label htmlFor="species">species</label>
                        </div>
                    </div>
                    
                </fieldset>
            </div>
        )
}