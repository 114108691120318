import React from 'react';
import { TwitterShareButton, FacebookShareButton, EmailShareButton, TumblrShareButton, WhatsappShareButton, RedditShareButton, } from 'react-share';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faFacebook, faTumblr, faWhatsapp, faReddit } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

export const StoryShare = ({ username, storyId, storyTitle }) => {
    const shareUrl = `${window.location.origin}/${username}/${storyId}`;
    const title = storyTitle ? `Check out this story: ${storyTitle}` : "Check out this story!";

    return (
        <div className="social-share">
            <p>Share this story</p>
            <TwitterShareButton url={shareUrl} title={title} >
                <FontAwesomeIcon icon={faTwitter} className="share-button"/>
            </TwitterShareButton>

            <FacebookShareButton url={shareUrl} quote={title} >
                <FontAwesomeIcon icon={faFacebook} className="share-button"/>
            </FacebookShareButton>

            <TumblrShareButton url={shareUrl} title={title} >
                <FontAwesomeIcon icon={faTumblr} className="share-button"/>
            </TumblrShareButton>
            
            <WhatsappShareButton url={shareUrl} title={title} separator=":: " >
                <FontAwesomeIcon icon={faWhatsapp} className="share-button"/>
            </WhatsappShareButton>

            <EmailShareButton url={shareUrl} subject={title} body="Check out this story!">
                <FontAwesomeIcon icon={faEnvelope} className="share-button"/>
            </EmailShareButton>
            <RedditShareButton url={shareUrl} title={title}>
                <FontAwesomeIcon icon = {faReddit} className="share-button"/>
            </RedditShareButton>
            
        </div>
    );
};
