import React from "react";
import {Motto} from "./motto";
import {Quote} from "./quote";
import { BlogCarousel } from "../blog/blogCarousel";
import './home.css'
import { AdminActivity } from "../admin/adminActivity";
import { Leaderboard } from "./leaderboard/leaderboard";
import { OpenContests } from "./openContests/openContests";
import { News } from "./news/news";
import { DiscoverStories } from "./discoverStories/discoverStories";
import { HomePotd } from "./homePotd";
import { FeaturedPotd } from "../potd/featuredPotd";
import { MerchCarousel } from "./merchCarousel/merchCarousel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faBlog, faBook, faBullseye, faList, faNewspaper, faPen, faSearch, faShoppingBasket } from "@fortawesome/free-solid-svg-icons";
import { ThoughtStream } from "./thoughtStream/thoughtStream";

export const Home = () => {
    
    return(
        <div id='home'className = 'body'>
            <main>

                {/* LEVEL ONE */}

                <div className="level one">
                    <section id="welcome">
                            <Motto />
                    </section>
                    
                </div>

                <div className="home-ticker" id='ticker'>
                    <AdminActivity />
                </div>

                {/* LEVEL TWO */}

                <div className = 'level two'>
                    <div className="left-two">
                        <div className="feature sub-section">
                            <h3 className= 'home-subheader'><FontAwesomeIcon icon={faBook}/> Featured Story</h3>
                            <FeaturedPotd />
                        </div>
                        <div className="homePotd sub-section">
                            <h3 className= 'home-subheader'><FontAwesomeIcon icon={faPen}/> Prompt of the Day</h3>
                            <HomePotd />
                        </div>
                        <div className="merch sub-section">
                            <h3 className= 'home-subheader'><FontAwesomeIcon icon={faShoppingBasket}/> Merch</h3>
                            <MerchCarousel />
                        </div>
                        <div className="home-thought sub-section">
                            <ThoughtStream />
                        </div>
                            
                    </div>
                    <div className="discover sub-section">
                        <h3 className= 'home-subheader'><FontAwesomeIcon icon={faSearch}/> Discover Stories</h3>
                        <DiscoverStories />
                    </div>
                    <div className="right-two">
                        <div className="leaderboard sub-section">
                                <h3 className= 'home-subheader'><FontAwesomeIcon icon={faList}/> Leaderboard</h3>
                                <Leaderboard />
                        </div>
                        <div className="quote sub-section">
                                <Quote />
                            </div>
                        
                    </div>
                    
                </div>
                {/* LEVEL THREE */}

                <div className="level three">
                        <div className="left-three">
                            <div className="blog-title">Check out the Writician <span>blog</span> <FontAwesomeIcon icon={faArrowRight}/></div>
                        </div>
                        <div className="right-three" id='blog-section'>
                                <h3 id='mobile-title'className= 'home-subheader'><FontAwesomeIcon icon={faBlog}/> On the Blog</h3>
                                <BlogCarousel />
                            
                        </div>
                </div> 

                {/* LEVEL FOUR */}

                <div className="level four">
                        <div className="left-four">
                            <div className="home-contests sub-section">
                                <h3 id='mobile-title'className= 'home-subheader'><FontAwesomeIcon icon={faBullseye}/> Open Contests</h3>
                                <OpenContests />
                            </div>
                            <div className="blog-title" id='news-title'>Writician <span>News</span></div>
                        </div>
                        <div className="right-four">
                            <div className="blog-title" id='contests-title'>Open <span>Contests</span></div>
                            <div className="news sub-section">
                                <h3 id='mobile-title'className= 'home-subheader'><FontAwesomeIcon icon={faNewspaper}/> Writician News</h3>
                                <News />
                            </div>
                        </div>
                       
                </div> 

            </main>

        </div>
        
    )
}
