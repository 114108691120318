import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { selectSettingPrompt, setGen } from "./settingPromptSlice";
import { setPrompt } from "../writePrompt/writePromptSlice";
import { SavePrompt } from "../savePrompt";
import { CreatePromptContest } from "../createPromptContest";
import { CreatePotd } from "../../admin/createPotd";

export function SettingPrompt() {
    const {user} = useSelector(state => state.app)
    const dispatch = useDispatch();
    const prompt = useSelector(selectSettingPrompt);
    const [save, setSave] = useState(false);
    const [style, setStyle] = useState({});

    useEffect(() => {
        if (prompt) {
            setSave(true);
            setStyle({
                backgroundColor: 'white',
                padding: '2rem',
            });
        }
    }, [prompt]);

    const handleClick = () => {
        dispatch(setGen());
        setSave(prevSave => !prevSave);
    };

    const handleWrite = () => {
        dispatch(setPrompt({ type: 'setting', prompt }));
    };

    return (
        <div className="prompt" id="settingPrompt">
            <div className='prompt-subsection'style={style}>
                {prompt}
                <br />
                {save && user && (
                    <>
                        <NavLink to="/writePrompt">
                            <button id="button2" onClick={handleWrite}>Write It</button>
                        </NavLink>
                        {user?.admin && 
                        <>
                            <CreatePromptContest prompt={prompt} promptType='setting'/>
                            <CreatePotd prompt={prompt} promptType='setting'/>
                        </>
                        }
                    </>
                )}
            </div>
            <button onClick={handleClick}>Generate New Setting</button>
            {save && user && <SavePrompt prompt={prompt} type="setting" />}
        </div>
    );
}
