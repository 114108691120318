import React, {useEffect} from "react";
import { NavLink } from "react-router-dom";

export const CharacterArchetypes = () => {
    useEffect(()=>{
        window.scrollTo(0,0)
    },[])
    
    return(
        <div >
            <header><h1>Character Archetypes</h1></header>
            <NavLink to='/story-elements/character-elements'><button id='button3'>Back</button></NavLink>
            <br/>
            <br/>
            <div>
            <img id='img12' src='/images/12.jpeg' alt='wheel of 12 character archetypes' loading="lazy"/>
            <br/>
            <br/>
            <br/>
            <div className="characters" id='charArchs'>
                <div className="character">
                    <h3>The Lover</h3>
                    <h5>The romantic lead who's guided by the heart.</h5>
                    <ul>
                        <li>Strengths: humanism, passion, conviction</li>
                        <li>Weaknesses: naivete, irrationality</li>
                        <li>Goal: Connection</li>
                        <li>Fear: Isolation</li>
                    </ul>
                </div>
                <div className="character">
                    <h3>The Hero</h3>
                    <h5>The protagonist who rises to meet a challenge and saves the day.</h5>
                    <ul>
                        <li>Strengths: courage, perseverance, honor</li>
                        <li>Weaknesses: overconfidence, hubris</li>
                        <li>Goal: Change the world</li>
                        <li>Fear: Weakness</li>
                    </ul>
                </div>
                <div className="character">
                    <h3>The Magician</h3>
                    <h5>A powerful figure who has harnessed the ways of the universe to achieve key goals.</h5>
                    <ul>
                        <li>Strengths: omniscience, omnipotence, discipline</li>
                        <li>Weaknesses: corruptibility, arrogance</li>
                        <li>Goal: Alter reality </li>
                        <li>Fear: Unintended results</li>
                    </ul>
                </div>
                <div className="character">
                    <h3>The Rebel</h3>
                    <h5>The outlaw who won't abide by society's demands.</h5>
                    <ul>
                        <li>Strengths: independent thinking, virtue, owes no favors</li>
                        <li>Weaknesses: self-involved, potentially criminal</li>
                        <li>Goal: Revolution </li>
                        <li>Fear: No power</li>
                    </ul>
                </div>
                <div className="character">
                    <h3>The Explorer</h3>
                    <h5>A character naturally driven to push the boundaries of the status quo and explore the unknown.</h5>
                    <ul>
                        <li>Strengths: curious, driven, motivated by self-improvement</li>
                        <li>Weaknesses: restless, unreliable, never satisfied</li>
                        <li>Goal: Freedom</li>
                        <li>Fear: Entrapment</li>
                    </ul>
                </div>
                <div className="character">
                    <h3>The Sage</h3>
                    <h5>A wise figure with knowledge for those who inquire. The mother figure or mentor is often based on this archetype.</h5>
                    <ul>
                        <li>Strengths: wisdom, experience, insight</li>
                        <li>Weaknesses: cautious, hesitant to actually join the action</li>
                        <li>Goal: Knowledge</li>
                        <li>Fear: Deception</li>
                    </ul>
                </div>
                <div className="character">
                    <h3>The Innocent</h3>
                    <h5>morally pure character, often a child, whose only intentions are good.</h5>
                    <ul>
                        <li>Strengths: morality, kindness, sincerity</li>
                        <li>Weaknesses: vulnerable, naive, rarely skilled</li>
                        <li>Goal: Happiness</li>
                        <li>Fear: Punishment</li>
                    </ul>
                </div>
                <div className="character">
                    <h3>The Creator</h3>
                    <h5>A motivated visionary who creates art or structures during the narrative.</h5>
                    <ul>
                        <li>Strengths: creativity, willpower, conviction</li>
                        <li>Weaknesses: self-involvement, single-mindedness, lack of practical skills</li>
                        <li>Goal: Realize vision</li>
                        <li>Fear: Mediocrity</li>
                    </ul>
                </div>
                <div className="character">
                    <h3>The Ruler</h3>
                    <h5>A character with legal or emotional power over others.</h5>
                    <ul>
                        <li>Strengths: omnipotence, status, resources</li>
                        <li>Weaknesses: aloofness, disliked by others, out of touch</li>
                        <li>Goal: Prosperity </li>
                        <li>Fear: Overthrown</li>
                    </ul>
                </div>
                <div className="character">
                    <h3>The Caregiver</h3>
                    <h5>A character who continually supports others and makes sacrifices on their behalf.</h5>
                    <ul>
                        <li>Strengths: honorable, selfless, loyal</li>
                        <li>Weaknesses: lacking personal ambition or leadership</li>
                        <li>Goal: Help others</li>
                        <li>Fear: Selfishness</li>
                    </ul>
                </div>
                <div className="character">
                    <h3>The Everyman</h3>
                    <h5>A relatable character who feels recognizable from daily life.</h5>
                    <ul>
                        <li>Strengths: grounded, salt-of-the-earth, relatable</li>
                        <li>Weaknesses: lacking special powers, often unprepared for what’s to come</li>
                        <li>Goal: Belonging</li>
                        <li>Fear: Exclusion</li>
                    </ul>
                </div>
                <div className="character">
                    <h3>The Jester</h3>
                    <h5>A funny character or trickster who provides comic relief, but may also speak important truths.</h5>
                    <ul>
                        <li>Strengths: funny, disarming, insightful</li>
                        <li>Weaknesses: can be obnoxious and superficial</li>
                        <li>Goal: Levity and fun</li>
                        <li>Fear: Boredom</li>
                    </ul>
                </div>
            </div>
            </div>
        </div>
    )
}