import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import JsonToReact from "../../utilities/jsonToReact";
import { formatDate, formatPotdDate } from "../../utilities/formatDate";
import { DisplayGenreIcon } from "../../utilities/displayGenreIcon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComment, faHeart, faStar, faEye, faThumbsUp as faThumbsUpFilled } from '@fortawesome/free-solid-svg-icons';
import { LeftSide } from "../../app/leftSide/leftSide";
import { RightSide } from "../../app/rightSide/rightSide";

export const Potd = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const { potdId } = useParams();
    const navigate = useNavigate();
    const { potds, stories } = useSelector((state) => state.app);

    // Find the prompt with the matching potdId
    const prompt = potds.find((potd) => potd.id === potdId);

    // Filter the stories related to this prompt
    const relatedStories = stories.filter(
        (story) => story.contest === 'potd' && story.contestId === potdId
    );

    // Find the story that has potd: true
    const featuredStory = relatedStories.find(story => story.potd === true);

    // Filter out the featured story from the rest
    const nonFeaturedStories = relatedStories.filter(story => story.potd !== true);

    const handleStoryClick = (user, storyId) => {
        navigate(`/${user}/${storyId}`);
    };

    if (!prompt) {
        return <div>Prompt not found</div>;
    }

    return (
        <div className="page">
        <LeftSide />
            <div className='content'>
                <h2 style={{fontWeight: 800}}>{formatPotdDate(prompt.time)}</h2>
                <div id='story-page-prompt'>
                    {JsonToReact({ jsonString: prompt.prompt })}
                </div>

                {/* If a featured story exists, display it with the header */}
                {featuredStory && (
                    <>
                        <h3>Featured Story:</h3>
                        <div className="prof-stories">
                        <div className='prof-story' onClick={() => handleStoryClick(featuredStory.user, featuredStory.uid)}>
                            <div className='prof-story-details'>
                                <h3 className='storyTitle'>{featuredStory.title}</h3>
                                <h4 style={{ color: 'black' }}>{featuredStory.author}</h4>
                                <DisplayGenreIcon genres={featuredStory.genres} style={{ width: '30px', borderRadius: '25px', margin: '.5rem' }} />
                                <p className="prof-snippet"><i>{featuredStory.story.substring(0, 500)}...</i></p>
                            </div>
                            <div className='story-interaction'>
                                <span><FontAwesomeIcon id='thumb' icon={faThumbsUpFilled} /> {featuredStory.likes ? featuredStory.likes.length : 0}</span>
                                {featuredStory.rateable && (
                                    <span><FontAwesomeIcon id='star' icon={faStar} /> {featuredStory.ratings ? (featuredStory.ratings.reduce((sum, r) => sum + r.rating, 0) / featuredStory.ratings.length).toFixed(1) : 0} ({featuredStory.ratings ? featuredStory.ratings.length : 0})</span>
                                )}
                                <span><FontAwesomeIcon id='comment' icon={faComment} /> {featuredStory.comments ? featuredStory.comments.length : 0}</span>
                                <span><FontAwesomeIcon id='heart' icon={faHeart} /> {featuredStory.favorites ? featuredStory.favorites.length : 0}</span>
                                <span><FontAwesomeIcon id='eye' icon={faEye} /> {featuredStory.views}</span>
                                <span>{formatDate(featuredStory.time)}</span>
                            </div>
                        </div>
                        </div>
                    </>
                )}

                {/* Display the remaining stories */}
                <h3>{nonFeaturedStories.length} {nonFeaturedStories.length === 0 ? '  Stories' : nonFeaturedStories.length === 1 ? ' Other Story' : ' Other Stories'}</h3>
                {nonFeaturedStories.length > 0 && (
                    <div className='prof-stories'>
                        {nonFeaturedStories.map((story) => (
                            <div key={story.uid} className='prof-story' onClick={() => handleStoryClick(story.user, story.uid)}>
                                <div className='prof-story-details'>
                                    <h3 className='storyTitle'>{story.title}</h3>
                                    <h4 style={{ color: 'black' }}>{story.author}</h4>
                                    <DisplayGenreIcon genres={story.genres} style={{ width: '30px', borderRadius: '25px', margin: '.5rem' }} />
                                    <p className="prof-snippet"><i>{story.story.substring(0, 500)}...</i></p>
                                </div>
                                <div className='story-interaction'>
                                    <span><FontAwesomeIcon id='thumb' icon={faThumbsUpFilled} /> {story.likes ? story.likes.length : 0}</span>
                                    {story.rateable && (
                                        <span><FontAwesomeIcon id='star' icon={faStar} /> {story.ratings ? (story.ratings.reduce((sum, r) => sum + r.rating, 0) / story.ratings.length).toFixed(1) : 0} ({story.ratings ? story.ratings.length : 0})</span>
                                    )}
                                    <span><FontAwesomeIcon id='comment' icon={faComment} /> {story.comments ? story.comments.length : 0}</span>
                                    <span><FontAwesomeIcon id='heart' icon={faHeart} /> {story.favorites ? story.favorites.length : 0}</span>
                                    <span><FontAwesomeIcon id='eye' icon={faEye} /> {story.views}</span>
                                    <span>{formatDate(story.time)}</span>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>
            <RightSide />
        </div>
    );
};
