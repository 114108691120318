
export const groupActivities = (activities) => {
        const grouped = {};
    
        activities.forEach(activity => {
            let key;
    
            if (activity.type === 'follow') {
                // For follow activities, group by the follower and the followed user
                key = `${activity.action}-${activity.user}-${activity.username}`;
            } else if (activity.type === 'thought') {
                key = `${activity.uid}`;
            } else if(activity.type ==='forum'){
                key = `${activity.action}-${activity.user}-${activity.uid}`
            } else if(activity.type === 'prompt'){
                key = `${activity.promptId}`
            }
                else {
                // For other activities, group by action and title
                key = `${activity.action}-${activity.title}`;
            }
            
    
            if (!grouped[key]) {
                grouped[key] = {
                    action: activity.action,
                    title: activity.title,
                    users: new Set(),
                    time: activity.time,
                    storyId: activity.storyId,
                    author: activity.author,
                    username: activity.username,  // For follow actions, add the followed user's username
                    type: activity.type,
                    questionId: activity.questionId,
                    text: activity.text,
                    prompt: activity.prompt,
                    uid: activity.uid,
                    comments: activity.comments,
                    likes: activity.likes,
                    promptType: activity.promptType,
                    contest: activity.contest,
                    contestId: activity.contestId,
                    promptId: activity.promptId,
                    promptAuthor: activity.promptAuthor,
                };
            }
    
            grouped[key].users.add(activity.user); // Add user to the set (duplicates are automatically handled)
        });
        return Object.values(grouped).map(group => ({
            ...group,
            users: Array.from(group.users), // Convert Set back to Array
            count: group.users.size,
        }));
};