import React, { useState, useEffect } from "react";
import './leaderboard.css';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserPlus, faUserCheck } from "@fortawesome/free-solid-svg-icons";
import { doc, setDoc, deleteDoc, collection, query, where, getDocs } from "firebase/firestore";
import { firestore } from "../../../firebase/firebase";
import { useDispatch, useSelector } from "react-redux";
import { setActivity, setFollowing } from "../../../app/appSlice";
import { UserPoints } from "../../../utilities/updateUserPoints";

export const Leaderboard = () => {
    const { users, user, activity, following } = useSelector(state => state.app);
    const colors = ["#ffadad", "#ffd6a5", "#fdffb6", "#caffbf", "#9bf6ff", "#a0c4ff", "#bdb2ff", "#ffc6ff", "#ffcfee", "#ff9cee"];
    const [sortedUsers, setSortedUsers] = useState([]);
    const [followingStatus, setFollowingStatus] = useState({}); // Track following status
    const dispatch = useDispatch();

    // Sort users by points and limit to top 10
    useEffect(() => {
        if (users) {
            const sorted = [...users].sort((a, b) => b.points - a.points); // Sort users by points (highest first)
            setSortedUsers(sorted.slice(0, 10)); // Get top 10 users
        }
    }, [users]);

    // Check following status for each profile
    useEffect(() => {
        if (user && following && sortedUsers.length > 0) {
            const status = {};
            sortedUsers.forEach(profile => {
                const isUserFollowing = following.some(
                    pair => pair.follower === user.username && pair.followed === profile.username
                );
                status[profile.username] = isUserFollowing; // Store the status
            });
            setFollowingStatus(status);
        }
    }, [user, following, sortedUsers]);

    // Follow/unfollow functionality
    const handleFollow = async (username) => {
        if (!user) return;

        const followPair = { follower: user.username, followed: username };

        // Check if user is currently following the target user
        const followedPair = following.find(
            pair => pair.follower === user.username && pair.followed === username
        );

        if (!followedPair) {
            // Follow logic
            const followingRef = doc(collection(firestore, 'following'));
            await setDoc(followingRef, followPair);
            // Update local state immediately after following
            setFollowingStatus(prev => ({ ...prev, [username]: true }));
            dispatch(setFollowing([...following, { ...followPair, id: followingRef.id }])); // Include ID in the local following state
            await addActivity('followed', followingRef.id, username);
        } else {
            // Unfollow logic
            await deleteDoc(doc(firestore, 'following', followedPair.id)); // Use the correct document ID
            // Update local state immediately after unfollowing
            setFollowingStatus(prev => ({ ...prev, [username]: false }));
            const updatedFollowing = following.filter(
                pair => pair.id !== followedPair.id
            );
            dispatch(setFollowing(updatedFollowing)); // Dispatch updated following state
            await removeActivity(followedPair.id, username);
        }
    };

    const addActivity = async (actionType, id, username) => {
        if (!user) return;

        const newDocRef = doc(collection(firestore, 'activity'));
        const docData = {
            type: 'follow',
            action: actionType,
            user: user.username,
            username: username,
            time: new Date().toISOString(),
            uid: newDocRef.id,
            actId: id,
        };

        try {
            await setDoc(newDocRef, docData);
            dispatch(setActivity([...activity, docData]));
        } catch (error) {
            console.error("Error adding activity:", error);
        }
    };

    const removeActivity = async (id) => {
        try {
            const activityQuery = query(
                collection(firestore, 'activity'),
                where('actId', '==', id)
            );
            const activitySnapshot = await getDocs(activityQuery);

            if (!activitySnapshot.empty) {
                const activityDocId = activitySnapshot.docs[0].id;
                await deleteDoc(doc(firestore, 'activity', activityDocId));
                dispatch(setActivity(activity.filter(act => act.actId !== id)));
            }
        } catch (error) {
            console.error('Error removing activity:', error);
        }
    };

    return (
        <div className="leaderboard-page">
            <ol className='leaderboard-list'>
                {sortedUsers.map((profile, index) => (
                    <li className='username' key={profile.id}>
                        <div className='leader-card' style={{ border: `2px solid ${colors[index % colors.length]}`, color: colors[index % colors.length] }}>
                            <div className="leaderboard-info">
                                <img style={{ width: '50px', borderRadius: '5px', backgroundColor: '#15181e' }} src={profile.profileImageUrl} alt="Profile" loading="lazy"/>
                                <div className="leaderboard-sub-info">
                                    <Link to={`/${profile.username}`} className="leader-link"><b>~{profile.username}</b></Link>
                                    {profile.penName && <><br /><i>{profile.penName}</i></>}
                                    <br />
                                    <b>{profile.rank}</b> of valor <b><UserPoints profile={profile}/></b>
                                </div>
                            </div>
                            {user && user.username !== profile.username && (
                                <FontAwesomeIcon 
                                    onClick={() => handleFollow(profile.username)} 
                                    id="leader-follow" 
                                    icon={followingStatus[profile.username] ? faUserCheck : faUserPlus} 
                                />
                            )}
                        </div>
                    </li>
                ))}
            </ol>
        </div>
    );
};
