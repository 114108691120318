import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import './thoughtStream.css';
import Filter from 'bad-words';
import { Link } from "react-router-dom";

export const ThoughtStream = () => {
  const { activity } = useSelector((state) => state.app);
  const thoughts = activity.filter((a) => a.type === 'thought');

  const [currentThoughtIndex, setCurrentThoughtIndex] = useState(0);
  const [isSlidingIn, setIsSlidingIn] = useState(true); // State to control sliding direction
  const filter = new Filter();
  filter.removeWords('butt', 'hell', 'hells', 'ass');

  const sortedThoughts = [...thoughts]
    .sort((a, b) => new Date(b.time) - new Date(a.time))
    .slice(0, 50);

  useEffect(() => {
    if (sortedThoughts.length > 0) {
      const interval = setInterval(() => {
        setIsSlidingIn(false); // Trigger slide-out
        setTimeout(() => {
          setCurrentThoughtIndex((prevIndex) => (prevIndex + 1) % sortedThoughts.length);
          setIsSlidingIn(true); // Trigger slide-in
        }, 600); // Match slide-out duration
      }, 4000); // Change every 3 seconds

      return () => clearInterval(interval);
    }
  }, [sortedThoughts]);

  const getFilteredText = (text) => filter.clean(text);

  return (
    <div className="thought-stream-ticker">
      <div className="thought-stream-ticker-wrapper">
        {sortedThoughts.length > 0 ? (
          <div
            key={currentThoughtIndex} // Ensure each thought mounts with a fresh key
            className={`thought-stream-ticker-content ${isSlidingIn ? 'slide-in' : 'slide-out'}`}
          >
            <b className="thought-stream-line">
              {getFilteredText(sortedThoughts[currentThoughtIndex].text)}
            </b>
            <br /><br />
            <Link id="thought-link" className="link" to={`/${sortedThoughts[currentThoughtIndex].user}`}>
              ~{sortedThoughts[currentThoughtIndex].user}
            </Link>
          </div>
        ) : (
          <div className="thought-stream-line">No thoughts available</div>
        )}
      </div>
    </div>
  );
};
