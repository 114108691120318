import React, { useState, useEffect, useRef, useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import Pagination from '@mui/material/Pagination';
import { firestore } from "../../../firebase/firebase";
import { doc, deleteDoc } from "firebase/firestore";
import './activity.css';
import { groupActivities } from "../../../utilities/groupActivities";
import { Thought } from "./thought";
import JsonToReact from "../../../utilities/jsonToReact";
import { DisplayThought } from "./displayThought";
import { formatDate } from "../../../utilities/formatDate";
import { useDispatch, useSelector } from "react-redux";
import { setActivity } from "../../../app/appSlice";

export const Activity = () => {
    const {following, activity, user, userPrompts} = useSelector(state => state.app)
    const {username} = useParams();
    const [data,setData] = useState([]);
    const [activities, setActivities] = useState(activity);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const itemsPerPage = 25;

        // Checkbox filter state
    const [showThoughts, setShowThoughts] = useState(true);
    const [showUser, setShowUser] = useState(true);
    const [showFollowing, setShowFollowing] = useState(true);

    const [isDropdownOpen, setIsDropdownOpen] = useState(false); // State for controlling dropdown visibility
    const dropdownRef = useRef(null); // For closing dropdown when clicking outside
    const isOwnFeed = user && user.username === username;

    const dispatch =useDispatch();


 

    const usersPrompts = useMemo(() => userPrompts.filter(p => p.user === username), [userPrompts, username]);

    const prompts = useMemo(() => usersPrompts.map(p => ({
        prompt: p.prompt,
        promptId: p.id,
        promptAuthor: p.user
    })), [usersPrompts]);

    const matchingActivities = useMemo(
        () =>
            activity
                .filter(act => act.type === 'story' && prompts.some(p => p.prompt === act.prompt))
                .map(act => {
                    const matchingPrompt = prompts.find(p => p.prompt === act.prompt);
                    return {
                        ...act,
                        promptId: matchingPrompt?.promptId, // Ensure safe access
                        promptAuthor: matchingPrompt?.promptAuthor
                    };
                }),
        [activity, prompts]
    );
   

    useEffect(() => {
    
        let allActivities = activity.filter(act=> act.user === username || act.username ===username);
        allActivities = [...allActivities, ...matchingActivities];

            if (isOwnFeed) {
                // Step 1: Find followed names where the follower is user.username
                const followedNames = following
                .filter(entry => entry.follower === user?.username) // Filter where follower is user.username
                .map(entry => entry.followed); // Extract the followed names

                // Step 2: Find activities for those followed names
                const followingActivities = activity.filter(act =>
                    followedNames.includes(act.user) && 
                    ['submitted', 'posted', 'shared a thought', 'favorited'].includes(act.action) // Filter by action types
                );
            
                allActivities = [...allActivities, ...followingActivities];

                
            }

            // Sort activities by timestamp, most recent first

            const sortedActivities = allActivities.slice().sort((a, b) => {
                // Convert timestamps to Date objects
                const dateA = new Date(a.time);
                const dateB = new Date(b.time);
                // Return the comparison
                return dateB - dateA; // For descending order (most recent first)
            });

            const groupedData = groupActivities(sortedActivities);
            setActivities(sortedActivities);
            setData(groupedData);
            setTotalPages(Math.ceil(groupedData.length / itemsPerPage));
        
    }, [username, following, activity,isOwnFeed,user?.username, prompts, matchingActivities]);
    
    

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    useEffect(() => {
        const scrollTarget = document.getElementById('activities-top');
    
        if (scrollTarget) {
            // Adding a small timeout to ensure the element is loaded in the DOM
            setTimeout(() => {
                scrollTarget.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }, 100); // Adjust delay as needed
        }
    }, [currentPage]);

    // Get paginated data with filters applied
    const getFilteredData = () => {
        let filteredData = data;
        // Only show thoughts when showThoughts is true and showUser and showFollowing are false
        if (showThoughts && !showUser && !showFollowing) {
            filteredData = filteredData.filter(activity => activity.type === 'thought');
        } else {
            // Filter out thoughts if showThoughts is false
            if (!showThoughts) {
                filteredData = filteredData.filter(activity => activity.type !== 'thought');
            }
            // Exclude activities that don't include the current user when showUser is false
            if (!showUser) {
                filteredData = filteredData.filter(activity => activity.users.some(user => user !== username));
            }
            // Exclude activities that include the current user when showFollowing is false
            if (!showFollowing) {
                filteredData = filteredData.filter(activity => activity.users.some(user => user === username));
            }
        }
        // Paginate the filtered data
        const startIndex = (currentPage - 1) * itemsPerPage;
        return filteredData.slice(startIndex, startIndex + itemsPerPage);
    };
    
    
    // Handle checkbox change
    const handleFilterChange = (filterType) => {
        switch (filterType) {
            case 'thoughts':
                setShowThoughts(prev => !prev);
                break;
            case 'user':
                setShowUser(prev => !prev);
                break;
            case 'following':
                setShowFollowing(prev => !prev);
                break;
            default:
                break;
        }
    };

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const handleNewThought = (newThought) => {
    // Add the new thought to the current activities
    const updatedActivities = [newThought, ...activities];

    // Update local state
    setActivities(updatedActivities);
    
    // Dispatch action to update Redux state
    dispatch(setActivity(updatedActivities)); // Dispatch directly with the updatedActivities array

    // Group activities and update data
    const groupedData = groupActivities(updatedActivities);
    setData(groupedData);

    // Update total pages based on the new grouped data
    setTotalPages(Math.ceil(groupedData.length / itemsPerPage));
};



const handleDeleteThought = async (thoughtId) => {
    try {
        const thoughtDocRef = doc(firestore, `activity`, thoughtId);
        await deleteDoc(thoughtDocRef);

        // Update the UI after deletion by filtering out the deleted thought
        const updatedActivities = activities.filter(activity => activity.uid !== thoughtId);

        // Update local state
        setActivities(updatedActivities);
        
        // Dispatch action to update Redux state
        dispatch(setActivity(updatedActivities)); // Dispatch the updated activities array

        // Update the grouped data and total pages
        const groupedData = groupActivities(updatedActivities);
        setData(groupedData);
        setTotalPages(Math.ceil(groupedData.length / itemsPerPage));
    } catch (error) {
        console.error('Error deleting thought:', error);
        // Optionally, you can dispatch an error action here if you have one
    }
};




    if (!user) {
        return (
            <div>
                <p>Please <Link to='/login' className='link'>login</Link> to view user activity</p>
            </div>
        );
    }

    return (
        <div className="activity-page" id = 'activities-top'>
            
            {isOwnFeed && <div className="filter-dropdown-container" ref={dropdownRef}>
                <button className="filter-dropdown-btn" onClick={toggleDropdown}>
                    Filter Activities
                </button>
                {isDropdownOpen && (
                    <div className="filter-dropdown">
                        <label>
                            <input type="checkbox" checked={showThoughts} onChange={() => handleFilterChange('thoughts')} />
                            Thoughts
                        </label>
                        <label>
                            <input type="checkbox" checked={showUser} onChange={() => handleFilterChange('user')} />
                            User Activity
                        </label>
                        <label>
                            <input type="checkbox" checked={showFollowing} onChange={() => handleFilterChange('following')} />
                            Following Activity
                        </label>
                    </div>
                )}
            </div>}
            <Thought onNewThought={handleNewThought} />
                {!activities && <p>No activities yet, do something!</p>}
            <ul className="activities-list">
                {user && getFilteredData().map((activity, index) => (
                    <li key={index} className='activity-line'>
                        <>
                            {activity.type === 'follow' ? (
                                activity.users[0] === user.username ? (
                                    // Display if the current user is the one who followed
                                    <>
                                        You <b className={activity.action}>{activity.action}</b> <Link to={`/${activity.username || username}`} className='link'>{activity.author || `~${activity.username}`} </Link>
                                        <p className="activity-date">{formatDate(activity.time)}</p>
                                        </>
                                ) : activity.username === user.username ? (
                                    <>
                                        <Link to={`/${activity.users[0]}`} className='link'>~{activity.users[0]}</Link> <b className={activity.action}>{activity.action}</b> you
                                        <p className="activity-date">{formatDate(activity.time)}</p>
                                    </>
                                ) : (
                                    <>
                                        <Link to={`/${activity.users[0]}`} className='link'>~{activity.users[0]}</Link> <b className={activity.action}>{activity.action}</b> <Link to={`/${activity.username}`} className='link'>{activity.author || `~${activity.username}`} </Link>
                                        <p className="activity-date">{formatDate(activity.time)}</p>
                                        </>
                                )
                            ) : activity.type === 'forum' ? (
                                activity.users[0] === user.username ? (
                                    // Display if the current user is the one who acted on the forum
                                    <>
                                        You <b className={activity.action}>{activity.action}</b>
                                        {activity.action !== 'posted' && (
                                            <>
                                            {activity.username !== user.username ? (
                                                <Link to={`/${activity.username}`} className='link'> ~{activity.username}'s</Link>
                                            ) : ' your'}
                                        </>

                                        )}
                                        {activity.action==='posted' && ' a'}<Link to={`/forum#${activity.questionId}`} className='link'> forum question </Link>
                                        {/* <p className="activity-text">{activity.text}</p> */}
                                        <p className="activity-date">{formatDate(activity.time)}</p>
                                    </>
                                ) : (
                                    // Display for other user's actions on the forum
                                    <>
                                        <Link to={`/${activity.users[0]}`} className='link'>~{activity.users[0]}</Link> <b className={activity.action}>{activity.action}</b>
                                        {activity.action !== 'posted' && (
                                        <>
                                            {activity.username !== user.username && activity.username !== activity.users[0] ? (
                                                <Link to={`/${activity.username}`} className='link'> ~{activity.username}'s</Link>
                                            ) : (
                                                activity.username === activity.users[0] ? ' their' : ' your'
                                            )}
                                        </>

                                        )}
                                        {activity.action==='posted' && ' a'}<Link to={`/forum/${activity.questionId}`} className='link'> forum question </Link>
                                        <p className="activity-date">{formatDate(activity.time)}</p>
                                    </>
                                )
                            ) : activity.type === 'thought' ? (
                                <DisplayThought thought={activity} onDeleteThought={handleDeleteThought} user={user} key={activity.uid}/>

                            ): activity.type === 'prompt' ? (
                                <>
                                    {activity.username === user.username ? (
                                    // Display if the current user is the one who performed the action
                                    <>
                                        You <b className={activity.action}>{activity.action}</b> a <Link to={`/prompt-generators/userPrompts/${activity.promptId}`} className="link"><u>prompt</u></Link>
                                    </>
                                    ) : (
                                    // Display if another user is the one who performed the action
                                    <>
                                        <Link to={`/${activity.username}`} className='link'>~{activity.username}</Link> <b className={activity.action}> {activity.action} </b> a <Link to={`/prompt-generators/userPrompts/${activity.promptId}`} className="link"><u>prompt</u></Link>
                                    </>
                                    )}
                                    {activity.prompt && (
                                    <div>
                                        <span className="activity-prompt" style={{ padding: '1rem', display: 'inline-block', marginTop: '1rem' }}>
                                        {JsonToReact({ jsonString: activity.prompt })}
                                        </span>
                                    </div>
                                    )}
                                    <p className="activity-date">{formatDate(activity.time)}</p>
                                </>


                            ): activity.type === 'story' && activity.promptAuthor === username ? (
                                // Handle matching activity
                                <>
                                    {activity.users.length === 1 ? (
                                        <Link to={`/${activity.users[0]}`} className='link'>~{activity.users[0]}</Link>
                                    ) : (
                                        <>
                                            {activity.users.slice(0, 2).map((user, idx) => (
                                                <Link key={idx} to={`/${user}`} className='link'>~{user}</Link>
                                            ))}
                                            {activity.users.length > 2 && ` and ${activity.users.length - 2} others`}
                                        </>
                                    )}
                                    <span><b className={activity.action}> {activity.action} </b><Link className="link" to={`/${activity.username}/${activity.storyId}`}><u>{activity.title}</u></Link> in response to {user.username===username ?'your': `~${username}'s`} <Link to={`/prompt-generators/userPrompts/${activity.promptId}`} className="link"><u>prompt.</u></Link></span>
                                </>
                            ):(
                                <>
                                    {activity.count === 1 ? (
                                        activity.users[0] === user.username ? 'You' : <Link to={`/${activity.users[0]}`} className="link">~{activity.users[0]}</Link>
                                    ) : (
                                        
                                        //IF MORE THAN ONE USER LIKE/COMMENT ETC.
                                        <>
                                            {activity.users.includes(user.username) ? (
                                                // Logic when the current user is included
                                                <>
                                                    You 
                                                    {activity.count > 2 ? (
                                                        ` and ${activity.count - 1} others`
                                                    ) : (
                                                        <>
                                                            {` and ` }
                                                            <Link to={`/${activity.users.find(u => u !== user.username)}`} className="link">
                                                                ~{activity.users.find(u => u !== user.username)}
                                                            </Link>
                                                        </>
                                                    )}
                                                </>
                                            ) : (
                                                // Logic when the current user is not included
                                                <>
                                                    <Link to={`/${activity.users[0]}`} className="link">
                                                        {`~${activity.users[0]} `}
                                                    </Link>
                                                    {activity.count > 2 ? (
                                                        ` and ${activity.count - 1} others`
                                                    ) : (
                                                        <>
                                                            and  
                                                            <Link to={`/${activity.users[1]}`} className="link">
                                                            {` ~${activity.users[1]}`}
                                                            </Link>
                                                        </>
                                                    )}
                                                </>
                                            )}
                                        </>
                                    )}
                                    <b className={activity.action}> {activity.action} </b>
                                    <Link to={`/${activity.username}/${activity.storyId}`} className="link"><u>{activity.title}</u></Link> 
                                    {activity.contest && (
                                        <>
                                            {' '}for <Link 
                                                to={activity.contest === "potd" ? `/potd/${activity.contestId}` : `/contests/${activity.contestId}`} 
                                                className="link"
                                            >
                                                {activity.contest}
                                            </Link>
                                        </>
                                    )}

                                    {activity.prompt && (
                                        <div>
                                            <span className="activity-prompt" style={{ padding: '1rem', display: 'inline-block', marginTop: '1rem' }}>
                                                {JsonToReact({ jsonString: activity.prompt })}
                                            </span> 
                                        </div>                                             
                                    )}
                                    <div>
                                        <p className="activity-date">{formatDate(activity.time)}</p>                          
                                    </div>
                                </>
                            )}
                        </>
                        {/* <div className="activity-delete">X</div> */}
                    </li>
                    
                ))}
            </ul>
            {totalPages > 1 && (
                <div className="pagination">
                    <Pagination
                        count={totalPages}
                        page={currentPage}
                        onChange={handlePageChange}
                        color="primary"
                        style={{ marginTop: "20px", display: "flex", justifyContent: "center" }}
                    />
                </div>
            )}
        </div>
    );

};
