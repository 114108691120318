import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { debounce } from 'lodash';

export const ForumSearch = ({ category }) => {
    const [queryText, setQueryText] = useState('');
    const [results, setResults] = useState([]);
    const [showDropdown, setShowDropdown] = useState(false);
    const searchRef = useRef(null);

    // Access forum-related data from global state using useSelector
    const { forumQuestions, forumAnswers, forumComments } = useSelector(state => state.app);

    // Handle input changes and trigger debounced search
    const handleInputChange = (e) => {
        const searchTerm = e.target.value;
        setQueryText(searchTerm);

        // Inline debounce function
        debounce(() => {
            if (searchTerm.trim() === '') {
                setResults([]);
                setShowDropdown(false);
                return;
            }

            const lowerSearchTerm = searchTerm.toLowerCase();

            // Filter forum questions, answers, and comments
            const questionResults = forumQuestions.filter(question =>
                question.text.toLowerCase().includes(lowerSearchTerm)
            );
            const answerResults = forumAnswers.filter(answer =>
                answer.text.toLowerCase().includes(lowerSearchTerm)
            );
            const commentResults = forumComments.filter(comment =>
                comment.text.toLowerCase().includes(lowerSearchTerm)
            );

            // Combine the filtered results into one array
            const combinedResults = [
                ...questionResults.map(question => ({ type: 'question', data: question })),
                ...answerResults.map(answer => ({ type: 'answer', data: answer })),
                ...commentResults.map(comment => ({ type: 'comment', data: comment }))
            ];

            setResults(combinedResults);
            setShowDropdown(true);
        }, 300)(); // Immediately invoke the debounced function
    };

    // Handle outside click to close dropdown
    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (searchRef.current && !searchRef.current.contains(event.target)) {
                setShowDropdown(false);
            }
        };

        document.addEventListener('mousedown', handleOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

    const renderResultItem = (result) => {
        switch (result.type) {
            case 'question':
                return (
                    <div key={result.data.id} className="search-dropdown-item">
                        <a href={`/forum#${result.data.id}`} className="search-dropdown-link">
                            Question: {result.data.text.substring(0, 50)}...
                        </a>
                    </div>
                );
            case 'answer':
                return (
                    <div key={result.data.id} className="search-dropdown-item">
                        <a href={`/forum#${result.data.questionId}`} className="search-dropdown-link">
                            Answer: {result.data.text.substring(0, 50)}...
                        </a>
                    </div>
                );
            case 'comment':
                return (
                    <div key={result.data.id} className="search-dropdown-item">
                        <a href={`/forum#${result.data.questionId}`} className="search-dropdown-link">
                            Comment: {result.data.text.substring(0, 50)}...
                        </a>
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <div className="search-forum" ref={searchRef}>
            <input
                type="text"
                placeholder="Search forum..."
                value={queryText}
                onChange={handleInputChange}
                className="search-input"
                name="search"
                onFocus={() => setShowDropdown(results.length > 0)} // Show dropdown on focus if there are results
            />
            {showDropdown && results.length > 0 && (
                <div className="search-dropdown">
                    {results.map(result => renderResultItem(result))}
                </div>
            )}
        </div>
    );
};
