import React, { useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { collection, doc, setDoc, deleteDoc, query, where, getDocs, writeBatch } from "firebase/firestore";
import { setActivity, setUserPrompts } from "../../../app/appSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBook, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { formatPotdDate } from "../../../utilities/formatDate";
import { setPrompt } from "../writePrompt/writePromptSlice";
import "./userPromptPage.css";
import { firestore } from "../../../firebase/firebase";

export const UserPromptHome = () => {
  const [promptText, setPromptText] = useState("");
  const [wordCount, setWordCount] = useState(0);
  const [showWarning, setShowWarning] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [deletePromptId, setDeletePromptId] = useState(null);
  const [isExpanded, setIsExpanded] = useState(false);

  const { user, activity, userPrompts, stories } = useSelector((state) => state.app);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const countWords = (text) => text.trim().split(/\s+/).filter(Boolean).length;

  const handlePrompt = (event) => {
    const inputText = event.target.value;
    const words = countWords(inputText);

    setPromptText(inputText);
    setWordCount(words);
    setShowWarning(words > 40);
  };

  const addActivity = async (action, promptId) => {
    if (!user) return;

    const newDocRef = doc(collection(firestore, `activity`));
    const actId = newDocRef.id;
    const activityData = {
      user: user.username,
      username: user.username,
      action,
      time: new Date().toISOString(),
      actId,
      author: user.penName || user.username,
      promptId,
      prompt: promptText,
      type: "prompt",
    };

    try {
      await setDoc(newDocRef, activityData);
      const updatedActivities = [...activity, activityData];
      dispatch(setActivity(updatedActivities));
    } catch (error) {
      console.error("Error adding activity document:", error);
    }
  };

  const handleSubmit = async () => {
    if (!user || promptText.trim() === "" || wordCount > 40) {
      alert("Submission failed! Make sure the prompt is not empty and within 40 words.");
      return;
    }
  
    try {
      // Calculate the start of the current day
      const startOfDay = new Date();
      startOfDay.setHours(0, 0, 0, 0);
  
      // Fetch all prompts submitted by the user on the current day
      const userPromptsQuery = query(
        collection(firestore, "user-prompts"),
        where("user", "==", user.username),
        where("time", ">=", startOfDay.toISOString())
      );
      const userPromptsSnapshot = await getDocs(userPromptsQuery);
      const userDailySubmissions = userPromptsSnapshot.size;
  
      if (userDailySubmissions >= 2) {
        alert("You can only submit up to 1 prompt per day.");
        return;
      }
  
      // Fetch the total number of prompts submitted globally
      const allPromptsSnapshot = await getDocs(collection(firestore, "user-prompts"));
      const totalSubmissions = allPromptsSnapshot.size;
  
      if (totalSubmissions >= 20) {
        alert("The maximum number of prompts (20) has been reached for today.");
        return;
      }
  
      // Proceed with submission
      const newDocRef = doc(collection(firestore, `user-prompts`));
      const promptId = newDocRef.id;
  
      const promptData = {
        user: user.username,
        prompt: promptText,
        promptType: "user",
        uid: promptId,
        username: user.username,
        time: new Date().toISOString(),
      };
  
      await setDoc(newDocRef, promptData);
      dispatch(setUserPrompts([...userPrompts, promptData])); // Append the new prompt to the list
      setIsExpanded(false);
      setPromptText(""); // Clear the textarea
      setWordCount(0); // Reset word count
      await addActivity("submitted", promptId);
  
    } catch (error) {
      console.error("Error submitting prompt:", error);
      alert("There was an error submitting your prompt. Please try again.");
    }
  };

  const handleWrite = (prompt) => {
    dispatch(setPrompt({ type: prompt.promptType, prompt: prompt.prompt, promptId: prompt.uid }));
  };

  const confirmDelete = (promptId) => {
    setDeletePromptId(promptId);
    setShowConfirm(true);
  };

  const handleDelete = async () => {
    if (!deletePromptId) return;
  
    try {
      // Delete the prompt document
      await deleteDoc(doc(firestore, `user-prompts`, deletePromptId));
  
      // Remove the prompt from Redux state
      const updatedPrompts = userPrompts.filter((prompt) => prompt.uid !== deletePromptId);
      dispatch(setUserPrompts(updatedPrompts)); 
  
      // Find and delete the associated activity
      const activitiesQuery = query(
        collection(firestore, "activity"),
        where("promptId", "==", deletePromptId)
      );
      const activitiesSnapshot = await getDocs(activitiesQuery);
  

        const batch = writeBatch(firestore);

      activitiesSnapshot.forEach((doc) => {
        batch.delete(doc.ref); // Add delete operation for each activity document
      });
      await batch.commit();
  
      // Update Redux state to remove the deleted activity
      const updatedActivities = activity.filter((act) => act.promptId !== deletePromptId);
      dispatch(setActivity(updatedActivities));
  
      setShowConfirm(false); // Close the modal
    } catch (error) {
      console.error("Error deleting prompt or associated activity document:", error);
      alert("There was an error deleting your prompt or related activity. Please try again.");
    }
  };
  

  const storyCounts = useMemo(() => {
    const counts = {};
    stories.forEach((story) => {
      counts[story.promptId] = (counts[story.promptId] || 0) + 1;
    });
    return counts;
  }, [stories]);

  const sortedUserPrompts = [...userPrompts].sort((a, b) => new Date(b.time) - new Date(a.time));




  const handleFocus = () => {
    setIsExpanded(true);
};

// Shrinks the textarea when blurred
const handleBlur = () => {
    if (!promptText) {
        setIsExpanded(false);
    }
};

  return (
    <div className="user-prompts-page">
      {user && <>
        <textarea
          className={`prompt-input ${isExpanded ? 'expanded' : ''}`}
          value={promptText}
          onChange={handlePrompt}
          placeholder="Write your prompt here..."
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
        {showWarning && <p className="warning">Word count exceeds 40 words!</p>}
        <button onClick={handleSubmit}>Submit Prompt</button>
      </>}
      

      <div className="user-prompts">
        {sortedUserPrompts.map((prompt, index) => (
          <div key={index} className="user-prompt">
            <div
              className="user-prompt-text"
              onClick={() => navigate(`/prompt-generators/userPrompts/${prompt.uid}`)}
            >
              {prompt.prompt}
            </div>

            {user?.username === prompt.user ? (
              <button
                className="userPrompt-delete-button"
                onClick={() => confirmDelete(prompt.uid)}
              >
                <FontAwesomeIcon icon={faTrashAlt} />
              </button>
            ) : (
              <NavLink to="/writePrompt" className="potd-write">
                <button onClick={() => handleWrite(prompt)}>Write It</button>
              </NavLink>
            )}

            <div className="user-prompt-bottom">
              <Link style={{ color: "#0056b3" }} className="link" to={`/${prompt.user}`}>
                ~{prompt.user}
              </Link>
              <h5>{formatPotdDate(prompt.time)}</h5>
              <div>
                {storyCounts[prompt.uid] || 0} <FontAwesomeIcon icon={faBook} />
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Confirmation Modal */}
      {showConfirm && (
        <div className="confirm-modal">
          <div className="confirm-modal-content">
            <p>Are you sure you want to delete this prompt?</p>
            <button onClick={handleDelete} className="confirm-yes">Yes</button>
            <button onClick={() => setShowConfirm(false)} className="confirm-no">No</button>
          </div>
        </div>
      )}
    </div>
  );
};
