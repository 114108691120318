import React from "react"
import { Link } from "react-router-dom"
export const All = () => {

return (

    <div id='all'>
        
        
            
            <nav className='sideNav'>
                    <button className="sidebtn" id='button3'>Menu
                        <i className="fa fa-caret-down"></i>
                    </button>
                    <div className="sidedown-content">
                        <a href="#genres"><button id='button3' className='sideLink'><span className="text">Genres</span></button></a>
                        <a href="#plots"><button id='button3'className='sideLink' ><span className="text">Plot Archetypes</span></button></a>
                        <a href="#personalities"><button id='button3' className='sideLink'><span className="text">Personality Types</span></button></a>
                        <a href="#characters"><button id='button3' className='sideLink'><span className="text">Character Archetypes</span></button></a>
                        <a href="#arcs"><button id='button3' className='sideLink'><span className="text">Character Arcs</span></button></a>
                        <a href="#hooks"><button id='button3' className='sideLink'><span className="text">Hooks</span></button></a>
                        <a href="#endings"><button id='button3' className='sideLink'><span className="text">Endings</span></button></a>
                        <a href="#litDevices"><button id='button3' className='sideLink'><span className="text">Literary Devices</span></button></a>
                        <a href="#narStyles"><button id='button3' className='sideLink'><span className="text">Narrative Styles</span></button></a>
                        <a href="#seeAll"><button id='button3' className='sideLink'><span className="text">Back to Top</span></button></a>
                    </div>
            </nav>

        
        


        <div className ='mainAll' id='mainAll'>

            <div className="page-title" id="genres">           
                <h1>  Genres </h1>
           </div>

            <div className="genres">
                <div className="genre">
                    <h3>Science Fiction</h3>
                    <p>A genre of speculative fiction which typically deals with imaginative and futuristic concepts such as advanced science and technology, space exploration, time travel, parallel universes, and extraterrestrial life. It has been called the "literature of ideas", and it often explores the potential consequences of scientific, social, and technological innovations.</p>
                </div>
                <div className="genre">
                    <h3>Fantasy</h3>
                    <p>A genre of speculative fiction involving magical elements, typically set in a fictional universe and sometimes inspired by mythology and folklore. Most fantasy uses magic or other supernatural elements as a main plot element, theme, or setting. Magic, magic practitioners (sorcerers, witches and so on) and magical creatures are common in many of these worlds.</p>
                </div>
                <div className="genre">
                    <h3>Mystery</h3>
                    <p>A fiction genre where the nature of an event, usually a murder or other crime, remains mysterious until the end of the story. Often within a closed circle of suspects, each suspect is usually provided with a credible motive and a reasonable opportunity for committing the crime. The central character is often a detective (such as Sherlock Holmes), who eventually solves the mystery by logical deduction from facts presented to the reader.</p>
                    </div>
                <div className="genre">
                    <h3>Thriller</h3>
                    <p>A genre of fiction, having numerous, often overlapping subgenres. Thrillers are characterized and defined by the moods they elicit, giving viewers heightened feelings of suspense, excitement, surprise, anticipation and anxiety.</p>
                </div>
                <div className="genre">
                    <h3>Dystopian</h3>
                    <p>A genre of speculative fiction which imagines worlds or societies where life is extremely bad because of deprivation or oppression or terror, and human society is characterized by human misery, such as squalor, oppression, disease, overcrowding, environmental destruction, or war.</p>
                </div>
                <div className="genre">
                    <h3>Steampunk</h3>
                    <p>A subgenre of science fiction that incorporates retrofuturistic technology and aesthetics inspired by 19th-century industrial steam-powered machinery. Steampunk works are often set in an alternative history of the Victorian era or the American "Wild West", where steam power remains in mainstream use, or in a fantasy world that similarly employs steam power.</p>
                </div>
                <div className="genre">
                    <h3>Romance</h3>
                    <p>Romantic fiction centers around love stories between two people. They're lighthearted, optimistic, and have an emotionally satisfying ending. Romance novels do contain conflict, but it doesn't overshadow the romantic relationship, which always prevails in the end.</p>
                </div>
                <div className="genre">
                    <h3>Historical Fiction</h3>
                    <p>Historical fiction novels take place in the past. Written with a careful balance of research and creativity, they transport readers to another time and place—which can be real, imagined, or a combination of both. Many historical novels tell stories that involve actual historical figures or historical events within historical settings.</p>
                </div>
                <div className="genre">
                    <h3>Post-Apocalyptic</h3>
                    <p>a subgenre of science fiction, science fantasy, dystopia or horror in which the Earth's (or another planet's) civilization is collapsing or has collapsed. The apocalypse event may be climatic, such as runaway climate change; astronomical, such as an impact event; destructive, such as nuclear holocaust or resource depletion; medical, such as a pandemic, whether natural or human-caused; end time, such as the Last Judgment, Second Coming or Ragnarök; or more imaginative, such as a zombie apocalypse, cybernetic revolt, technological singularity, dysgenics or alien invasion.</p>
                </div>
                <div className="genre">
                    <h3>Adventure</h3>
                    <p>Adventure stories make the element of danger the focus. An adventure is an event or series of events that happens outside the course of the protagonist's ordinary life, usually accompanied by danger, often by physical action. Adventure stories almost always move quickly, and the pace of the plot is at least as important as characterization, setting and other elements of a creative work.</p>
                </div>
                <div className="genre">
                    <h3>Horror</h3>
                    <p>Horror novels are meant to scare, startle, shock, and even repulse readers. Generally focusing on themes of death, demons, evil spirits, and the afterlife, they prey on fears with scary beings like ghosts, vampires, werewolves, witches, and monsters. In horror fiction, plot and characters are tools used to elicit a terrifying sense of dread</p>
                </div>
                <div className="genre">
                    <h3>Literary Fiction</h3>
                    <p>Considered works with artistic value and literary merit. They often include political criticism, social commentary, and reflections on humanity. Literary fiction novels are typically character-driven, as opposed to being plot-driven, and follow a character's inner story. </p>
                </div>
                <div className="genre">
                        <h3>Super Hero</h3>
                        <p>A category within speculative fiction that explores the adventures, personalities, and ethical dilemmas of costumed crime fighters known as superheroes. These characters often possess superhuman powers and engage in battles with similarly powered criminals referred to as supervillains. Situated between hard fantasy and soft science fiction on the spectrum of scientific realism, the superhero genre incorporates elements of both fantastical and scientifically influenced storytelling.</p>
                    </div>
                    <div className="genre">
                        <h3>Cyberpunk</h3>
                        <p>A subset within science fiction, Cyberpunk unfolds in a dystopian future where there is an emphasis on the intersection of "lowlife and high tech." It showcases advanced technological and scientific marvels like artificial intelligence and cybernetics against a backdrop of societal collapse, dystopia, or decay. This subgenre explores the complex interplay between futuristic innovation and the darker facets of society, often delving into themes of corruption, inequality, and urban deterioration.</p>
                    </div>
                    <div className="genre">
                        <h3>Urban Fantasy</h3>
                        <p>A fantasy subgenre that integrates supernatural elements into a modern urban environment. This fusion allows authors to employ whimsical plot devices, unique character traits, and a backdrop for traditional fantasy themes, all while avoiding the necessity of constructing an entirely imagined world.</p>
                    </div>
                    <div className="genre">
                        <h3>Alternative History</h3>
                        <p>A genre of speculative fiction that explores narratives in which one or more historical events unfold and conclude differently than in reality. Rooted in conjecture based on historical facts, these stories pose "What if?" scenarios, envisioning crucial events in human history with outcomes diverging significantly from the established historical record.</p>
                    </div>
                    <div className="genre">
                        <h3>Western</h3>
                        <p>The Western genre unfolds against the backdrop of the American frontier, closely tied to the folk tales of the Western United States, particularly the Southwestern United States, Northern Mexico, and Western Canada. The 'old west' was a terrain traversed by cowboys, outlaws, sheriffs, and an array of archetypal "gunslinger" figures. Western narratives frequently delve into the gradual endeavors to bring order to the crime-infested American West, interweaving broader themes of justice, freedom, rugged individualism, Manifest Destiny, and the historical and national identity of the United States.</p>
                    </div>
                    <div className="genre">
                        <h3>Spy Fiction</h3>
                        <p>A genre that incorporates espionage as a significant context or plot device. Originating in the early twentieth century, it draws inspiration from the rivalries and intrigues among major powers during that time, as well as the establishment of modern intelligence agencies.</p>
                    </div>
                    <div className="genre">
                        <h3>Satire</h3>
                        <p>A genre of fiction that employs ridicule to highlight vices, follies, abuses, and shortcomings with the aim of exposing or shaming perceived flaws in individuals, corporations, government, or society as a whole, prompting improvement. While humor is a common element, the primary purpose of satire is often constructive social criticism. It uses wit to bring attention to specific and broader societal issues, intending to provoke reflection and change.</p>
                    </div>
                    <div className="genre">
                        <h3>First Contact</h3>
                        <p>A prevalent science fiction motif revolves around the initial encounter between humans and extraterrestrial life or between sentient species from different celestial bodies. This theme provides writers with an avenue to delve into subjects like xenophobia, transcendentalism, and fundamental linguistics. By adapting the anthropological concept of first contact to extraterrestrial cultures, storytellers can explore the intricacies and challenges of these unprecedented interactions.</p>
                    </div>
                    <div className="genre">
                        <h3>Space Opera</h3>
                        <p>A subgenre of science fiction, places a strong emphasis on space warfare, weaving melodramatic, adventurous tales of risk, relationships, and chivalric romance. Primarily or exclusively set in outer space, this genre showcases technological and social advancements (or their absence) through faster-than-light travel, futuristic weaponry, and sophisticated technology. Against the backdrop of galactic empires and interstellar conflicts, often featuring fictional aliens in imaginary galaxies, space opera invites audiences into grandiose narratives of cosmic proportions.</p>
                    </div>
                    <div className="genre">
                        <h3>Magical Realism</h3>
                        <p>a literary and artistic style that portrays a realistic view of the world while seamlessly incorporating magical elements, creating a subtle interplay between fantasy and reality. In this genre, magical or supernatural phenomena are seamlessly woven into an otherwise real-world or ordinary setting. While magical realism includes elements of magic, it is distinct from fantasy as it integrates a substantial amount of realistic detail. The use of magical elements serves to convey deeper insights about reality, distinguishing it from fantasy, where narratives are often distinctly separated from the constraints of the real world.</p>
                    </div>
                    <div className="genre">
                        <h3>Epic</h3>
                        <p>A narrative genre characterized by the portrayal of heroic or legendary adventures presented in an extended format.</p>
                    </div>
                    <div className="genre">
                        <h3>Heist</h3>
                        <p>A subgenre of crime movies that center on the meticulous planning, execution, and aftermath of a notable robbery.</p>
                    </div>
                )
                
            </div>
    
            <div className="page-title" id="plots">           
                <h1>Plot Archetypes</h1>
           </div>

            <div className="plots">
                <div className="plot">
                    <h3>Overcoming the Monster</h3>
                    <p>The protagonist sets out to defeat an antagonistic force (often evil) which threatens the protagonist and/or protagonist's homeland.</p>
                </div>
                <div className="plot">
                    <h3>Rags to Riches</h3>
                    <p>The poor protagonist acquires power, wealth, and/or a mate, loses it all and gains it back, growing as a person as a result.</p>
                </div>
                <div className="plot">
                    <h3>The Quest</h3>
                    <p>The protagonist and companions set out to acquire an important object or to get to a location. They face temptations and other obstacles along the way.</p>
                </div>
                <div className="plot">
                    <h3>Voyage and Return</h3>
                    <p>The protagonist goes to a strange land and, after overcoming the threats it poses or learning important lessons unique to that location, they return with experience.</p>
                </div>
                <div className="plot">
                    <h3>Comedy</h3>
                    <p>Light and humorous character with a happy or cheerful ending; a dramatic work in which the central motif is the triumph over adverse circumstance, resulting in a successful or happy conclusion.</p>
                </div>
                <div className="plot">
                    <h3>Tragedy</h3>
                    <p>The protagonist is a hero with a major character flaw or great mistake which is ultimately their undoing. Their unfortunate end evokes pity at their folly and the fall of a fundamentally good character.</p>
                </div>
                <div className="plot">
                    <h3>Rebirth</h3>
                    <p>An event forces the main character to change their ways and often become a better individual.</p>
                </div>
                <div className="plot">
                    <h3>Rebellion Against 'The One'</h3>
                    <p>Concerns a hero who rebels against the all-powerful entity that controls the world until he is forced to surrender to that power.</p>
                </div>
            </div>
            <div className="page-title" id="personalities">           
                <h1>Personality Types</h1>
           </div>

            <div className="personalities">
                <div className="analyst personality">
                    <h3>Architect</h3>
                    <h5>Most independent</h5>
                    <ul>
                        <li>Theoretical</li>
                        <li>Skeptical</li>
                        <li>Needs to be competent</li>
                        <li>Sees world as chessboard</li>
                        <li>Needs things 'my way'</li>
                        <li>Imaginative and strategic thinkers, with a plan for everything.</li>
                    </ul>
                </div>
                <div className="analyst personality">
                    <h3>Logician</h3>
                    <h5>Most conceptual</h5>
                    <ul>
                        <li>Challenges others to think</li>
                        <li>High need for competency</li>
                        <li>Socially cautious</li>
                        <li>Independent</li>
                        <li>Innovative inventors with an unquenchable thirst for knowledge</li>
                    </ul>
                </div>
                <div className="analyst personality">
                    <h3>Commander</h3>
                    <h5>Most commanding</h5>
                    <ul>
                        <li>Visionary</li>
                        <li>Gregarious</li>
                        <li>Argumentative</li>
                        <li>Planner</li>
                        <li>Impatient with incompetence</li>
                        <li>Bold, imaginative and strong willed leaders, always finding a way, or making one</li>
                    </ul>
                </div>
                <div className="analyst personality">
                    <h3>Debater</h3>
                    <h5>Most inventive</h5>
                    <ul>
                        
                        <li>Given to brinksmanship</li>
                        <li>Tests limits</li>
                        <li>Enthusiastic</li>
                        <li>Innovative</li>
                        <li>Enjoys arguing both sides</li>
                        <li>Likes challenges</li>
                        <li>Smart and curious thinkers who cannot resist an intellectual challenge</li>
                    </ul>
                </div>
                <div className="diplomat personality">
                    <h3>Advocate</h3>
                    <h5>Most reflective</h5>
                    <ul>
                        <li>Introspective</li>
                        <li>Quietly caring</li>
                        <li>Creative</li>
                        <li>Eloquent speaker/writer</li>
                        <li>Visionary</li>
                        <li>Quiet and mystical, yet very inspiring and tireless idealists</li>
                    </ul>
                </div>
                <div className="diplomat personality">
                    <h3>Mediator</h3>
                    <h5>Most idealistic</h5>
                    <ul>
                        <li>Has strong personal values</li>
                        <li>Seeks inner order and peace</li>
                        <li>Creative</li>
                        <li>Non-directive</li>
                        <li>Reserved with people</li>
                        <li>Poetic, kind, and altruistic people, always eager to help a good cause</li>
                    </ul>
                </div>
                <div className="diplomat personality">
                    <h3>Protagonists</h3>
                    <h5>Most persuasive</h5>
                    <ul>
                        <li>Charismatic</li>
                        <li>Idealistic</li>
                        <li>Ignores unpleasantness</li>
                        <li>Sees the potential in others</li>
                        <li>Compassionate</li>
                        <li>Charismatic and inspiring leaders, able to mesmerize their listeners</li>
                    </ul>
                </div>
                <div className="diplomat personality">
                    <h3>Campaigners</h3>
                    <h5>Most optimistic</h5>
                    <ul>
                        <li>People oriented</li>
                        <li>Creative</li>
                        <li>Seeks harmony with others</li>
                        <li>Life of the party</li>
                        <li>Better at starting than finishing</li>
                        <li>Enthusiastic, creative, sociable, free spirits, can always find a reason to smile</li>
                    </ul>
                </div>
                <div className="sentinel personality">
                    <h3>Logistician</h3>
                    <h5>Most reliable</h5>
                    <ul>
                        <li>Organized</li>
                        <li>Compulsive</li>
                        <li>Private</li>
                        <li>Trustworthy</li>
                        <li>Follows rules</li>
                        <li>Practical and fact minded individuals, who reliability cannot be doubted</li>
                    </ul>
                </div>
                <div className="sentinel personality">
                    <h3>Defender</h3>
                    <h5>Most loyal</h5>
                    <ul>
                        <li>Easy to collaborate with</li>
                        <li>Works behind the scenes</li>
                        <li>Sacrifices readily</li>
                        <li>Accountable</li>
                        <li>Very dedicated and warm protectors, ready to defend loved ones</li>
                    </ul>
                </div>
                <div className="sentinel personality">
                    <h3>Executive</h3>
                    <h5>Most forceful</h5>
                    <ul>
                        <li>Orderly and structured</li>
                        <li>Sociable</li>
                        <li>Opinionated</li>
                        <li>Results-oriented</li>
                        <li>Productive</li>
                        <li>Traditional</li>
                        <li>Excellent administrators, unsurpassed at managing things or people</li>
                    </ul>
                </div>
                <div className="sentinel personality">
                    <h3>Consul</h3>
                    <h5>Most harmonious</h5>
                    <ul>
                        <li>Gracious</li>
                        <li>Thoughtful</li>
                        <li>Lives to please</li>
                        <li>Has strong interpersonal skills</li>
                        <li>Accomplished host/hostess </li>
                        <li>Extraordinarily caring, social and popular people, eager to help</li>
                    </ul>
                </div>
                <div className="explorer personality">
                    <h3>Virtuoso</h3>
                    <h5>Most pragmatic</h5>
                    <ul>
                        <li>Observant</li>
                        <li>Often seen as cool and aloof</li>
                        <li>Practical</li>
                        <li>Unpretentious</li>
                        <li>Ready for anything</li>
                        <li>Bold and practical experimenters, masters of all kinds of tools</li>
                    </ul>
                </div>
                <div className="explorer personality">
                    <h3>Adventurer</h3>
                    <h5>Most artistic</h5>
                    <ul>
                        <li>Warm</li>
                        <li>Sensitive</li>
                        <li>Unassuming</li>
                        <li>Team player</li>
                        <li>In touch with self and nature</li>
                        <li>Flexible and charming artists, always ready to explore and experience something new</li>
                    </ul>
                </div>
                <div className="explorer personality">
                    <h3>Entrepreneur</h3>
                    <h5>Most fun</h5>
                    <ul>
                        <li>Unconventional</li>
                        <li>Fun</li>
                        <li>Gregarious</li>
                        <li>Lives for the here and now</li>
                        <li>Problem solver</li>
                        <li>Smart, energetic, and very perceptive, enjoy living on the edge</li>
                    </ul>
                </div>
                <div className="explorer personality">
                    <h3>Entertainer</h3>
                    <h5>Most generous</h5>
                    <ul>
                        <li>Sociable</li>
                        <li>Spontaneous</li>
                        <li>Loves surprises</li>
                        <li>Cuts red tape</li>
                        <li>Juggles many projects</li>
                        <li>Shares readily</li>
                        <li>Spontaneous, energetic, enthusiastic, life is never boring around them</li>
                    </ul>
                </div>
                
            </div>
            <div className="page-title" id="characters">           
                <h1>Character Archetypes</h1>
           </div>

            <div className="characters">
                <div className="character">
                    <h3>The Lover</h3>
                    <h5>The romantic lead who's guided by the heart.</h5>
                    <ul>
                        <li>Strengths: humanism, passion, conviction</li>
                        <li>Weaknesses: naivete, irrationality</li>
                        <li>Goal: Connection</li>
                        <li>Fear: Isolation</li>
                    </ul>
                </div>
                <div className="character">
                    <h3>The Hero</h3>
                    <h5>The protagonist who rises to meet a challenge and saves the day.</h5>
                    <ul>
                        <li>Strengths: courage, perseverance, honor</li>
                        <li>Weaknesses: overconfidence, hubris</li>
                        <li>Goal: Change the world</li>
                        <li>Fear: Weakness</li>
                    </ul>
                </div>
                <div className="character">
                    <h3>The Magician</h3>
                    <h5>A powerful figure who has harnessed the ways of the universe to achieve key goals.</h5>
                    <ul>
                        <li>Strengths: omniscience, omnipotence, discipline</li>
                        <li>Weaknesses: corruptibility, arrogance</li>
                        <li>Goal: Alter reality </li>
                        <li>Fear: Unintended results</li>
                    </ul>
                </div>
                <div className="character">
                    <h3>The Rebel</h3>
                    <h5>The outlaw who won't abide by society's demands.</h5>
                    <ul>
                        <li>Strengths: independent thinking, virtue, owes no favors</li>
                        <li>Weaknesses: self-involved, potentially criminal</li>
                        <li>Goal: Revolution </li>
                        <li>Fear: No power</li>
                    </ul>
                </div>
                <div className="character">
                    <h3>The Explorer</h3>
                    <h5>A character naturally driven to push the boundaries of the status quo and explore the unknown.</h5>
                    <ul>
                        <li>Strengths: curious, driven, motivated by self-improvement</li>
                        <li>Weaknesses: restless, unreliable, never satisfied</li>
                        <li>Goal: Freedom</li>
                        <li>Fear: Entrapment</li>
                    </ul>
                </div>
                <div className="character">
                    <h3>The Sage</h3>
                    <h5>A wise figure with knowledge for those who inquire. The mother figure or mentor is often based on this archetype.</h5>
                    <ul>
                        <li>Strengths: wisdom, experience, insight</li>
                        <li>Weaknesses: cautious, hesitant to actually join the action</li>
                        <li>Goal: Knowledge</li>
                        <li>Fear: Deception</li>
                    </ul>
                </div>
                <div className="character">
                    <h3>The Innocent</h3>
                    <h5>morally pure character, often a child, whose only intentions are good.</h5>
                    <ul>
                        <li>Strengths: morality, kindness, sincerity</li>
                        <li>Weaknesses: vulnerable, naive, rarely skilled</li>
                        <li>Goal: Happiness</li>
                        <li>Fear: Punishment</li>
                    </ul>
                </div>
                <div className="character">
                    <h3>The Creator</h3>
                    <h5>A motivated visionary who creates art or structures during the narrative.</h5>
                    <ul>
                        <li>Strengths: creativity, willpower, conviction</li>
                        <li>Weaknesses: self-involvement, single-mindedness, lack of practical skills</li>
                        <li>Goal: Realize vision</li>
                        <li>Fear: Mediocrity</li>
                    </ul>
                </div>
                <div className="character">
                    <h3>The Ruler</h3>
                    <h5>A character with legal or emotional power over others.</h5>
                    <ul>
                        <li>Strengths: omnipotence, status, resources</li>
                        <li>Weaknesses: aloofness, disliked by others, out of touch</li>
                        <li>Goal: Prosperity </li>
                        <li>Fear: Overthrown</li>
                    </ul>
                </div>
                <div className="character">
                    <h3>The Caregiver</h3>
                    <h5>A character who continually supports others and makes sacrifices on their behalf.</h5>
                    <ul>
                        <li>Strengths: honorable, selfless, loyal</li>
                        <li>Weaknesses: lacking personal ambition or leadership</li>
                        <li>Goal: Help others</li>
                        <li>Fear: Selfishness</li>
                    </ul>
                </div>
                <div className="character">
                    <h3>The Everyman</h3>
                    <h5>A relatable character who feels recognizable from daily life.</h5>
                    <ul>
                        <li>Strengths: grounded, salt-of-the-earth, relatable</li>
                        <li>Weaknesses: lacking special powers, often unprepared for what’s to come</li>
                        <li>Goal: Belonging</li>
                        <li>Fear: Exclusion</li>
                    </ul>
                </div>
                <div className="character">
                    <h3>The Jester</h3>
                    <h5>A funny character or trickster who provides comic relief, but may also speak important truths.</h5>
                    <ul>
                        <li>Strengths: funny, disarming, insightful</li>
                        <li>Weaknesses: can be obnoxious and superficial</li>
                        <li>Goal: Levity and fun</li>
                        <li>Fear: Boredom</li>
                    </ul>
                </div>
            </div>
            <div className="page-title" id="arcs">           
                <h1>Character Arcs</h1>
                <Link to='/story-elements/plot-elements/character-arcs' className="link">Click for more details</Link>

           </div>



            <div className="arcs">
                <div className="arc">
                    <h3>Positive Arc</h3>
                    <h5>Character believes lie, overcomes lie, new truth is liberating.</h5>
                </div>
                <div className="arc">
                    <h3>Flat Arc</h3>
                    <h5>Character believes Truth, maintains Truth, uses Truth to overcome world's Lie.</h5>
                </div>
                <div className="arc">
                    <h3>Corruption Arc</h3>
                    <h5>Character sees Truth, rejects Truth, embraces Lie.</h5>
                </div>
                <div className="arc">
                    <h3>Disillusionment Arc</h3>
                    <h5>Character believes Lie, overcomes Lie, new Truth is tragic.</h5>
                </div>
                <div className="arc">
                    <h3>The Fall</h3>
                    <h5>Character believes Lie, clings to Lie, rejects new Truth, believes worse Lie.</h5>
                </div>
            </div>


            <div className="page-title" id="hooks">           
                <h1>Hooks</h1>
           </div>

           <div className = 'genres'>


           <div className = 'genre'>
                    <h3>Action</h3>
                    <ul>
                        <li>Begin in the middle of the action</li>
                        <li>Grabs the reader's attention right away, adding context later on.</li>
                    </ul>
                </div>

                <div className = 'genre'>
                    <h3>Analogy</h3>
                    <ul>
                        <li>Compare two or more things.</li>
                        <li>A way to draw the reader into the themes of the story, possibily the protagonist/narrator's personality as well.</li>
                    </ul>

                </div>

                <div className = 'genre'>
                    <h3>Command to the Reader</h3>
                    <ul>
                        <li>Sets the tone right away, breaking the fourth wall.</li>
                        <li>Establishes the role of the narrator in the story.</li>
                    </ul>

                </div>

                <div className = 'genre'>
                    <h3>Bold Statement</h3>
                    <ul>
                        <li>Something shocking or interesting to catch the reader's attention and draw them in.</li>
                      
                    </ul>
                </div>

                <div className = 'genre'>
                    <h3>Character's Thoughts/Feelings</h3>
                    <ul>
                        <li>Introduces the protagonist, establishes the story question through their fears or desires.</li>
                        <li>Should entice the reader to want to see what happens to them.</li>
                    </ul>

                </div>

                <div className = 'genre'>
                    <h3>Definition</h3>
                    <ul>
                        <li>Define a word that encapsulates the theme of the story.</li>
                    </ul>
                </div>
   
                <div className = 'genre'>
                    <h3>Dialogue</h3>
                    <ul>
                        <li>Jumping right into dialogue quick-starts the story and drama.</li>
                        <li>The reader is forced right into the heart of the scene.</li>
                    </ul>
                </div>
            

                <div className = 'genre'>
                    <h3>Foreshadowing</h3>
                    <ul>
                        <li>Set up events that will unfold later on in the story.</li>
                        <li>Could be a hint or clue of something to come that the reader won't recognize until it's revealed later on.</li>
                    </ul>
                </div>
            

                <div className = 'genre'>
                    <h3>Onomatopoeia</h3>
                    <ul>
                        <li>A word that is also the sound, catches the reader's attention.</li>
                    </ul>
                </div>
            

                <div className = 'genre'>
                    <h3>Question</h3>
                    <ul>
                        <li>Asks the reader a question, to be answered later on, or to reveal theme.</li>
    
                    </ul>
                </div>
            

                <div className = 'genre'>
                    <h3>Theme Statement</h3>
                    <ul>
                        <li>Tells the reader exactly what the story is going to be about.</li>
                        <li>Themes are vague and wide, so the specifics are not revealed.</li>
                    </ul>

                </div>
            

          
                <div className = 'genre'>
                    <h3>Sentence Fragment</h3>
                    <ul>
                        <li>A short fragment of a sentence that leaves reader wanting more.</li>
                        <li>What does it mean? Where is this going?</li>
                    </ul>
                </div>
            

                <div className = 'genre'>
                    <h3>Setting</h3>
                    <ul>
                        <li>Paints a picture of the setting with words.</li>
                        <li>Draws the reader in with sensory details, building the world around them and placing them in it.</li>
                    </ul>
                </div>

           </div>


           <div className="page-title" id="endings">           
                <h1>Endings</h1>
           </div>

           <div className = 'genres'>

                <div className = 'genre' >
                    <h3>Circular</h3>
                    <ul>
                        <li>The story circles back to the beginning</li>
                        <li>Sometimes ending on the same idea or theme, even the exact same or similar words as the beginning of the story.</li>
                    </ul>

                </div>
                <div className = 'genre'>
                    <h3>Twist</h3>
                    <ul>
                        <li>The story takes you where you didn't expect it to go.</li>
                        <li>An unforeseen ending, but in hindsight it makes sense if properly foreshadowed.</li>
                    </ul>
                </div>
                
               
                <div className = 'genre'>
                    <h3>Moral</h3>
                    <ul>
                        <li>The main character grows, changes, or learns something positive at the end.</li>
                       
                    </ul>
                </div>
                <div className = 'genre'>
                    <h3>Warm & Fuzzy</h3>
                    <ul>
                        <li>The story ends leaving the reader feeling emotional or good inside.</li>
                        <li>Tugs at the heart strings</li>
                    </ul>
                </div>
                <div className = 'genre'>
                    <h3>Reflection</h3>
                    <ul>
                        <li>The narrator steps back and reflects on what just happened.</li>
                        <li>They often look back on the experience and determine the importance of it and what was learned.</li>
                    </ul>
                </div>
                <div className = 'genre'>
                    <h3>Cliffhanger</h3>
                    <ul>
                        <li>The story ends leaving the reader hanging or wanting more.</li>
                        <li>Unresolved ending, good for the end of chapters or book series.</li>
                    </ul>
                </div>
                <div className = 'genre'>
                    <h3>Question</h3>
                    <ul>
                        <li>Ends with a question to keep the reader thinking.</li>
                        <li>The question usually involves the reader and is used to make the writing memorable.</li>
                    </ul>
                </div>
                <div className = 'genre'>
                    <h3>Humorous</h3>
                    <ul>
                        <li>Ends with a funny thought or statement, bringing levity to the story or situation, and making it more memorable.</li>
                        
                    </ul>
                </div>  
                <div className = 'genre'>
                    <h3>Image</h3>
                    <ul>
                        <li>Ends with an important scene that the writer shows the reader through vivid details. </li>
                        <li>By showing and not telling, the writer touches the reader's emotions and conveys a mood.</li>
                    </ul>
                </div>
                <div className = 'genre'>
                    <h3>Dialogue</h3>
                    <ul>
                        <li>Ends with an important conversation or quote.</li>
                        <li>Reveals character's personality</li>
                    </ul>
                </div>
            
           </div>

           <div className="page-title" id="litDevices">           
                <h1>Literary Devices</h1>
           </div>

           <div className = 'genres'>

                <div className = 'genre'>
                <h3>Juxtaposition</h3>
                <p><em>Description:</em> Placing two or more elements side by side for the purpose of comparison or contrast.</p>
                <p><em>Example:</em> In the bustling city, the homeless man slept peacefully on a park bench.</p>
                </div>
    
                <div className = 'genre'>
                <h3>Macguffin</h3>
                <p><em>Description:</em> A Macguffin is a plot device, typically an object or goal, that propels the story by motivating the characters. It adds mystery and suspense without intrinsic significance, remaining interchangeable to maintain focus on the characters and their journey.</p>
                <p><em>Example:</em> The mysterious briefcase in the film that everyone is after, but its contents are never revealed.</p>
                </div>
       
            <div className = 'genre'>
                <h3>Framing Device</h3>
                <p><em>Description:</em> A narrative structure where a story is enclosed within another story, serving as a framing element.</p>
                <p><em>Example:</em> The main narrative is presented as a manuscript discovered by a character in the framing story.</p>
            </div>
           
                <div className = 'genre'>
                    <h3>Red Herring</h3>
                    <p><em>Description:</em> A misleading clue or distraction intentionally inserted to divert attention from the real issue or plot point.</p>
                    <p><em>Example:</em> The detective follows a false lead that initially seems crucial to solving the mystery.</p>
                </div>
            
    
                <div className = 'genre'>
                    <h3>Chekhov's Gun</h3>
                    <p><em>Description:</em> The narrative principle that states every element in a story should be necessary and irreplaceable, and any element that is not should be removed.</p>
                    <p><em>Example:</em> A seemingly insignificant object introduced early in the story becomes crucial to the resolution later on.</p>
                </div>
        
                <div className = 'genre'>
                    <h3>Ticking Clock</h3>
                    <p><em>Description:</em> Introduction of a time constraint or impending deadline, creating tension and a sense of urgency in the narrative.</p>
                    <p><em>Example:</em> The characters must complete a mission before a bomb detonates, adding urgency to the plot.</p>
                </div>
          
                <div className = 'genre'>
                    <h3>Self-fulfilling Prophecy</h3>
                    <p><em>Description:</em> A prediction or belief that influences a person's behavior in such a way that it causes the prediction to come true.</p>
                    <p><em>Example:</em> A character, fearing betrayal, takes actions that drive their friends away, fulfilling the fear of betrayal.</p>
                </div>
         
                <div className = 'genre'>
                <h3>Alliteration</h3>
                <p><em>Description:</em> Repetition of the same consonant sound at the beginning of neighboring words.</p>
                <p><em>Example:</em> Peter Piper picked a peck of pickled peppers.</p>
                </div>
       
                <div className = 'genre'>
                    <h3>Predestination</h3>
                    <p><em>Description:</em> The idea that events or outcomes are predetermined and cannot be changed, often tied to a sense of destiny.</p>
                    <p><em>Example:</em> Characters discover a prophecy that foretells the specific events of their lives.</p>
                </div>
         
                <div className = 'genre'>
                    <h3>Unreliable Narrator</h3>
                    <p><em>Description:</em> A narrator whose credibility or reliability is compromised, intentionally or unintentionally, leading to a narrative where the audience questions the truth of the events.</p>
                    <p><em>Example:</em> The narrator with a personal bias or mental instability presents events in a way that distorts the true nature of the story.</p>
                </div>
        
                <div className = 'genre'>
                <h3>Symbolism</h3>
                <p><em>Description:</em> The use of symbols to represent ideas or qualities.</p>
                <p><em>Example:</em> The dove is often a symbol of peace.</p>
                </div>
          
                <div className = 'genre'>
                    <h3>Poetic Justice</h3>
                    <p><em>Description:</em> The concept that virtue is ultimately rewarded, and vice is punished in a manner that is fitting or appropriate.</p>
                    <p><em>Example:</em> The villain who exploited others for personal gain faces a downfall that mirrors their own actions.</p>
                </div>
            
            
           </div>


           <div className="page-title" id="narStyles">           
                <h1>Narrative Styles</h1>
           </div>

           <div className = 'genres'>

          <div className = 'genre'>
                <h3>First-Person Narrative</h3>
                <p><em>Description:</em> The story is told from the perspective of a character within the story using first-person pronouns (I, we).</p>
                <p><em>Example:</em> I walked into the room and saw a mysterious figure standing by the window.</p>
            </div>
            <div className = 'genre'>
                <h3>Third-Person Limited</h3>
                <p><em>Description:</em> The narrator is external to the story and focuses on the thoughts and feelings of a single character.</p>
                <p><em>Example:</em> She felt a wave of anxiety as she approached the interview room.</p>
            </div>
            <div className = 'genre'>
                <h3>Third-Person Omniscient</h3>
                <p><em>Description:</em> The narrator is external and has knowledge of the thoughts and feelings of all characters in the story.</p>
                <p><em>Example:</em> Little did they know, a surprise awaited them just around the corner.</p>
            </div>
            <div className = 'genre'>
                <h3>Second-Person Narrative</h3>
                <p><em>Description:</em> The narrator directly addresses the reader using second-person pronouns (you).</p>
                <p><em>Example:</em> You open the old, creaky door and step into the mysterious room.</p>
            </div>
            <div className = 'genre'>
                <h3>Parallel Narrative</h3>
                <p><em>Description:</em> Multiple storylines occurring simultaneously, often with connections or thematic parallels.</p>
                <p><em>Example:</em> The narrative alternates between different characters or settings, gradually revealing the connections between their experiences.</p>
            </div>
            <div className = 'genre'>
                <h3>Stream of Consciousness</h3>
                <p><em>Description:</em> A narrative technique that presents a character's thoughts and feelings as they occur in the character's mind, often without a clear structure.</p>
                <p><em>Example:</em> The rain began to fall, each droplet tapping on the window like a secret code. I wonder if the world outside feels the same weight I carry within me. The coffee on the table grows cold, forgotten amid the chaos of memories and unspoken words. How did I end up here...</p>
            </div>
            <div className = 'genre'>
                <h3>Epistolary</h3>
                <p><em>Description:</em> The story is told through a series of letters, diary entries, or other written documents.</p>
                <p><em>Example:</em> The old letters revealed the secrets of a love long forgotten.</p>
            </div>
            <div className = 'genre'>
                <h3>Objective Narrative</h3>
                <p><em>Description:</em> The narrator remains a detached observer, presenting only the actions and dialogue of the characters without delving into their thoughts.</p>
                <p><em>Example:</em> The clock struck midnight, and the protagonist entered the room without saying a word.</p>
            </div>
            <div className = 'genre'>
                <h3>Linear Narrative</h3>
                <p><em>Description:</em> The story unfolds in a chronological sequence, moving forward in time without significant jumps or interruptions.</p>
                <p><em>Example:</em> The protagonist's journey began on a sunny morning and continued through a series of challenges and triumphs.</p>
            </div>
            <div className = 'genre'>
                <h3>Nonlinear Narrative</h3>
                <p><em>Description:</em> The story is presented out of chronological order, with events occurring in a sequence other than a straight timeline.</p>
                <p><em>Example:</em> Flashbacks, flash-forwards, and time loops contribute to a narrative structure that challenges traditional linear storytelling.</p>
            </div>
            
           </div>


        </div>
        
    </div>

)}