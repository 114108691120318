import { getTimeAgo } from "./formatDate";

export const setLatestActivity = ({ users, activity }) => {
  // Clone the users array to avoid direct mutation
  const updatedUsers = users.map(user => {
    // Filter the activities to get the ones that belong to this user
    const userActivities = activity.filter(act => act.user === user.username);

    // If the user has activities, find the latest one
    if (userActivities.length > 0) {
      // Sort activities by time (or timestamp) in descending order (latest first)
      const latestActivity = userActivities.sort((a, b) => new Date(b.time) - new Date(a.time))[0];

      // Set the latestActivity field using getTimeAgo
      return {
        ...user,
        latestActivity: getTimeAgo(latestActivity.time) // Assuming activity.time is the correct field
      };
    } else {
      // If no activities for the user, return the user without modifying latestActivity
      return {
        ...user,
        latestActivity: 'No activity'
      };
    }
  });

  return updatedUsers; // Return the updated users array
};
