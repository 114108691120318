
export function formatDate(date) {
  const parsedDate = new Date(date);  // Ensure it's a Date object
  const today = new Date();
  const isToday = parsedDate.toDateString() === today.toDateString();
  const currentYear = today.getFullYear();
  const year = parsedDate.getFullYear();

  // Format month and day (e.g., "Jan 16")
  const options = { month: 'short', day: 'numeric' };
  let formattedDate = parsedDate.toLocaleDateString('en-US', options);

  // Add time if the date is today
  if (isToday) {
    const timeOptions = { hour: 'numeric', minute: 'numeric', hour12: true };
    const time = parsedDate.toLocaleTimeString('en-US', timeOptions);
    formattedDate += ` at ${time}`;
  }

  // Add the year if it's not the current year
  if (year !== currentYear) {
    formattedDate += `, ${year}`;
  }

  return formattedDate;
}


// Get "time ago" string based on how long ago the date occurred
// Get "time ago" string based on how long ago the date occurred
export const getTimeAgo = (timestamp) => {
  const date = new Date(timestamp); // Convert ISO string or timestamp directly to Date
  const now = new Date();
  const diffMs = now - date; // Time difference in milliseconds

  const diffMinutes = Math.floor(diffMs / (1000 * 60));
  const diffHours = Math.floor(diffMinutes / 60);
  const diffDays = Math.floor(diffHours / 24);

  if (diffDays > 0) {
    return `${diffDays} day${diffDays !== 1 ? 's' : ''} ago`;
  } else if (diffHours > 0) {
    return `${diffHours} hour${diffHours !== 1 ? 's' : ''} ago`;
  } else {
    return `${diffMinutes} minute${diffMinutes !== 1 ? 's' : ''} ago`;
  }
};


// Format time only (e.g., "2:30 PM" or "14:30")
export function formatTimeOnly(date, hour12 = true) {
  const parsedDate = new Date(date);  // Ensure it's a Date object
  return parsedDate.toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: hour12, // Set to false for 24-hour format, true for 12-hour format
  });
}


export function formatPotdDate(date){
  const parsedDate = new Date(date);  // Ensure it's a Date object       
  // Format month and day (e.g., "Jan 16")
  const options = { month: 'short', day: 'numeric' };
  let formattedDate = parsedDate.toLocaleDateString('en-US', options);
  return formattedDate;
}