import React from "react";


export const LeftSide = () => {
    return(
        <div className="leftSide">

            
        </div>
    )
}