import React from "react";
import './footer.css'
import { Link } from "react-router-dom";

export const Footer=()=>{



    return(
            <div className='footer'>
                <div className='icons'>
                    <a target="_blank" rel="noreferrer"  href='https://x.com/writician'> <img  alt='twitter link'src='/images/x.png'/></a>
                    <a target="_blank" rel="noreferrer"  href='https://www.instagram.com/writician.site/'> <img  alt='instagram link'src='/images/insta.png'/></a> 
                    <a target="_blank" rel="noreferrer"  href='https://www.facebook.com/writician/'> <img  alt='facebook link'src='/images/fb.png'/></a>
                </div>
                <div id='footerLists'>
                    <div className ='footerList'>
                        <Link className='footerLink'to='/story-elements/plot-elements/genres'>Genres</Link>
                        <Link className='footerLink'to='/story-elements/plot-elements/plot-archetypes'>Plot Archetypes</Link>
                        <Link className='footerLink'to='/story-elements/plot-elements/character-arcs'>Character Arcs</Link>
                        <Link className='footerLink'to='/story-elements/plot-elements/subplots'>Subplots</Link>
                        <Link className='footerLink'to='/story-elements/plot-elements/hooks'>Hooks</Link>
                        <Link className='footerLink'to='/story-elements/plot-elements/endings'>Endings</Link>
                    </div>
                    <div className ='footerList'>
                        <Link className='footerLink'to='/story-elements/character-elements/character-archetypes'>Character Archetypes</Link>
                        <Link className='footerLink'to='/story-elements/character-elements/personality-types'>Personality Types</Link>
                        <Link className='footerLink'to='/story-elements/craft-elements/lit-devices'>Literary Devices</Link>
                        <Link className='footerLink'to='/story-elements/craft-elements/narrative-styles'>Narrative Styles</Link>
                        <Link className='footerLink'to='/story-elements/craft-elements/themes'>Themes</Link>
                    </div>
                    <div className ='footerList'>
                        <Link className='footerLink'to='/prompt-generators'>Prompts</Link>
                        <Link className='footerLink'to='/write-idea'>Write Your Idea</Link>
                        <Link className='footerLink'to='/contests'>Contests</Link>
                        <Link className='footerLink'to='/forum'>Forum</Link>
                        <Link className='footerLink'to='/stories'>Stories</Link>
                        <Link className='footerLink'to='/members'>Members</Link>
                    </div>
                    <div className ='footerList'>
                        <Link className='footerLink'to='/login' >Profile</Link>
                        <Link className='footerLink'to='/account'>Account</Link>
                        <Link className='footerLink'to='/about'>About</Link>
                        <Link className='footerLink'to='/blog'>Blog</Link>
                    </div>
                </div>
                
                <img alt='' id='footerLogo' src='images/favlogotrans.png'/>
                <p>copyright Flintsmoke Digital Ltd 2024</p>
                
                

            </div>

    )
}