import React from "react";
import { useSelector } from "react-redux"; // Ensure you're importing useSelector from react-redux
import { Link } from "react-router-dom";
import { groupActivities } from "../../utilities/groupActivities"; // Ensure to import groupActivities utility

export const AdminActivity = () => {
    const { activity } = useSelector(state => state.app); // Extracting activity and profile from Redux state

    const filteredActivities = activity.filter(a=> a.type!=='thought')

    // Sort the activities by time, most recent first
    const sortedActivities = filteredActivities.slice().sort((a, b) => {
        const dateA = new Date(a.time); 
        const dateB = new Date(b.time);
        
        // Check for invalid dates
        if (isNaN(dateA) || isNaN(dateB)) {
            console.error('Invalid date format:', a.time, b.time);
            return 0; // Do not change order if dates are invalid
        }

        return dateB - dateA; // For descending order
    });

    // Limit to the most recent 100 activities
    const recentActivities = sortedActivities.slice(0, 100);

    const groupedData = groupActivities(recentActivities);


    return (
        <div className="activity-page">
            <div className="ticker">
            <ul className="ticker-content">
                {groupedData.map((activityGroup, index) => (
                    <li key={index} className='admin-activity-line'>
                            {/* Render each activity in the group */}
                            {activityGroup.type === 'follow' ? (
                                <>
                                    <Link to={`/${activityGroup.users[0]}`} className='link'>~{activityGroup.users[0]}</Link> 
                                    <b className={activityGroup.action}> {activityGroup.action} </b> 
                                    <Link to={`/${activityGroup.username}`} className='link'>{activityGroup.author || `~${activityGroup.username}`}</Link>
                                </>
                            ) : activityGroup.type === 'forum' ? (
                                <>
                                    <Link to={`/${activityGroup.users[0]}`} className='link'>~{activityGroup.users[0]} </Link> 
                                    <b className={activityGroup.action}>{activityGroup.action}</b>
                                    {activityGroup.action !== ' posted' && (
                                        <>
                                            {activityGroup.username !== activityGroup.users[0] ? (
                                                <Link to={`/${activityGroup.username}`} className='link'> ~{activityGroup.username}'s</Link>
                                            ) : (activityGroup.action !=='posted' ? ' their': '')}
                                        </>
                                    )}
                                    {activityGroup.action === 'posted' && ' a'}<Link to={`/forum/#${activityGroup.questionId}`} className='link'> forum question </Link>
                                </>
                            ): activityGroup.type === 'prompt' ? (
                                    <>
                                        <Link to={`/${activityGroup.username}`} className='link'>~{activityGroup.username}</Link> <b className={activityGroup.action}> {activityGroup.action}</b> a <Link to={`/prompt-generators/userPrompts/${activityGroup.promptId}`} className="link"><u>prompt</u></Link>
                                    </>
                            ): (
                                <>
                                    {activityGroup.count === 1 ? (
                                        <Link to={`/${activityGroup.users[0]}`} className="link">~{activityGroup.users[0]}</Link>
                                    ) : (
                                        <>
                                            <Link to={`/${activityGroup.users[0]}`} className="link">~{activityGroup.users[0]}</Link>
                                            {activityGroup.count > 2 ? (
                                                ` and ${activityGroup.count - 1} others`
                                            ) : (
                                                <>
                                                    {` and`}
                                                    <Link to={`/${activityGroup.users[1]}`} className="link"> ~{activityGroup.users[1]}</Link>
                                                </>
                                            )}
                                        </>
                                    )}
                                    <b className={activityGroup.action}> {activityGroup.action} </b>
                                    <Link to={`/${activityGroup.username}/${activityGroup.storyId}`} className="link"><u>{activityGroup.title} </u></Link>
                                </>
                            )}
                    </li>
                ))}
            </ul>
            </div>
        </div>
    );
};
