import React, { useState } from "react";
import './merchCarousel.css';

export const MerchCarousel = () => {
    const [vis1, setVis1] = useState(true);
    const [vis2, setVis2] = useState(false);
    const [vis3, setVis3] = useState(false);

    const handleClick = (direction) => {
        if (vis1 && direction === 'prev') {
            setVis1(false);
            setVis3(true);
        } else if (vis1 && direction === 'next') {
            setVis1(false);
            setVis2(true);
        } else if (vis2 && direction === 'prev') {
            setVis2(false);
            setVis1(true);
        } else if (vis2 && direction === 'next') {
            setVis2(false);
            setVis3(true);
        } else if (vis3 && direction === 'prev') {
            setVis3(false);
            setVis2(true);
        } else if (vis3 && direction === 'next') {
            setVis3(false);
            setVis1(true);
        }
    };

    return (
        <section className="merch-carousel">
            <div className="merch-carousel-prev" onClick={() => handleClick('prev')}>‹</div>

            {vis1 && (
                <div className="merch-carousel-item">
                    <a 
                        href="https://my-store-fdd388.creator-spring.com" 
                        target="_blank" 
                        rel="noopener noreferrer" 
                        className="merchLink"
                    >
                        <div className='merchTop'>
                            <img className='merchImage' src='/images/merch/rwl.png' alt='T-Shirt' loading="lazy" />
                        </div>
                    </a>
                </div>
            )}

            {vis2 && (
                <div className="merch-carousel-item">
                    <a 
                        href="https://my-store-fdd388.creator-spring.com" 
                        target="_blank" 
                        rel="noopener noreferrer" 
                        className="merchLink"
                    >
                        <div className='merchTop'>
                            <img className='merchImage' src='/images/merch/hoodie.png' alt='Mug' loading="lazy" />
                        </div>
                    </a>
                </div>
            )}

            {vis3 && (
                <div className="merch-carousel-item">
                    <a 
                        href="https://my-store-fdd388.creator-spring.com" 
                        target="_blank" 
                        rel="noopener noreferrer" 
                        className="merchLink"
                    >
                        <div className='merchTop'>
                            <img className='merchImage' src='/images/merch/mug.png' alt='Hoodie' loading="lazy" />
                        </div>
                    </a>
                </div>
            )}

            <div className="merch-carousel-next" onClick={() => handleClick('next')}>›</div>
        </section>
    );
};
