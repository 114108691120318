import React, { useEffect, useState } from "react";
import { Link, useOutletContext } from "react-router-dom";
import { Stories } from "./stories";
import { Activity } from "./activity/activity";
import UserStats from "./userStats/userStats";
import { Favorites } from "./favorites/favorites";
import { Following } from "./follow/following";
import { Followers } from "./follow/followers";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faFacebook, faInstagram, faPatreon, faReddit, faTumblr, faThreads } from '@fortawesome/free-brands-svg-icons';
import { faGlobe, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { SavedPrompts } from "./savedPrompts/savedPrompts";
import { formatDate } from "../../utilities/formatDate";
import { useDispatch } from "react-redux";
import { updateDoc, doc, arrayRemove } from "firebase/firestore";
import { firestore } from "../../firebase/firebase";
import { setUser } from "../../app/appSlice";

export const ProfHome = () => {
    const { profile, user } = useOutletContext();
    const username = profile.username;

    const { bio, location, joined, twitter, facebook, instagram, patreon, reddit, tumblr, threads, web } = profile || {};

    const [showStories, setShowStories] = useState(true);
    const [showActivity, setShowActivity] = useState(false);
    const [showFavorites, setShowFavorites] = useState(false);
    const [showFollowing, setShowFollowing] = useState(false);
    const [showFollowers, setShowFollowers] = useState(false);
    const [showSavedPrompts, setShowSavedPrompts] = useState(false);
    const [bookmarks, setBookmarks] = useState(profile.bookmarks || []); // Local state for bookmarks

    const dispatch = useDispatch();

    useEffect(() => {
        setShowStories(true);
        setShowActivity(false);
        setShowFavorites(false);
        setShowFollowing(false);
        setShowFollowers(false);
        setShowSavedPrompts(false);
    }, [username]);

    const handleClick = (page) => {
        setShowStories(page === 'stories');
        setShowActivity(page === 'activity');
        setShowFavorites(page === 'favorites');
        setShowFollowing(page === 'following');
        setShowFollowers(page === 'followers');
        setShowSavedPrompts(page === 'savedPrompts');
    };

    // Function to handle bookmark deletion with optimistic update
    const handleDeleteBookmark = async (bookmarkToDelete) => {
        if (!user) return;

        // Optimistically update the local state
        const updatedBookmarks = bookmarks.filter(
            (bookmark) => bookmark.paragraphId !== bookmarkToDelete.paragraphId
        );
        setBookmarks(updatedBookmarks); // Update local state immediately

        // Firestore reference for the user's document
        const userRef = doc(firestore, "users", user.username);

        try {
            // Remove bookmark from Firestore
            await updateDoc(userRef, {
                bookmarks: arrayRemove(bookmarkToDelete),
            });

            // Update global user state
            const updatedUser = {
                ...user,
                bookmarks: updatedBookmarks,
            };
            dispatch(setUser(updatedUser));

            console.log("Bookmark deleted successfully");

        } catch (error) {
            console.error("Error deleting bookmark: ", error);
            // Optional: Revert local state change on failure
            setBookmarks(profile.bookmarks); // Revert back if there's an error
        }
    };

    return (
        <div className="profHome">
            <UserStats username={username} currentUser={profile} />
            <div className="prof-boxes">
                <div className="infoBox">
                    <h3>About</h3>
                    {bio && (
                        <>
                            <h5>Bio:</h5>
                            <p>{bio}</p>
                        </>
                    )}
                    {location && (
                        <>
                            <h5>Location:</h5>
                            <p>{location}</p>
                        </>
                    )}
                    {joined && (
                        <>
                            <h5>Member Since:</h5>
                            <p>{formatDate(joined)}</p>
                        </>
                    )}
                    {(web || twitter || facebook || instagram || patreon || reddit || tumblr || threads) && (
                        <h5>Links:</h5>
                    )}
                    <div className="social-media-icons">
                        {web && (
                            <a href={web} target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faGlobe} size="2x" />
                            </a>
                        )}
                        {twitter && (
                            <a href={twitter} target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faTwitter} size="2x" />
                            </a>
                        )}
                        {facebook && (
                            <a href={facebook} target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faFacebook} size="2x" />
                            </a>
                        )}
                        {instagram && (
                            <a href={instagram} target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faInstagram} size="2x" />
                            </a>
                        )}
                        {patreon && (
                            <a href={patreon} target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faPatreon} size="2x" />
                            </a>
                        )}
                        {reddit && (
                            <a href={reddit} target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faReddit} size="2x" />
                            </a>
                        )}
                        {tumblr && (
                            <a href={tumblr} target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faTumblr} size="2x" />
                            </a>
                        )}
                        {threads && (
                            <a href={threads} target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faThreads} size="2x" />
                            </a>
                        )}
                    </div>
                    {user?.username ===profile.username && <div>
                        <h5>Bookmarks</h5>
                        {bookmarks?.map((bm, index) => (
                            <div key={index} className="bookmark">
                                <Link to={`/${bm.author}/${bm.storyId}#${bm.paragraphId}`} className="bm-link" >{bm.title}</Link>
                                <span
                                    onClick={() => handleDeleteBookmark(bm)}
                                    className="bm-delete" 
                                >
                                    <FontAwesomeIcon icon={faTrashAlt} />
                                </span>
                            </div>
                        ))}
                    </div>}
                </div>
                <div id="page-box">
                    <nav className="prof-nav">
                        <h3
                            className={showStories ? "active" : ""}
                            onClick={() => handleClick("stories")}
                        >
                            Stories
                        </h3>
                        <h3
                            className={showActivity ? "active" : ""}
                            onClick={() => handleClick("activity")}
                        >
                            Activity
                        </h3>
                        <h3
                            className={showFavorites ? "active" : ""}
                            onClick={() => handleClick("favorites")}
                        >
                            Favorites
                        </h3>
                        <h3
                            className={showFollowing ? "active" : ""}
                            onClick={() => handleClick("following")}
                        >
                            Following
                        </h3>
                        <h3
                            className={showFollowers ? "active" : ""}
                            onClick={() => handleClick("followers")}
                        >
                            Followers
                        </h3>
                        {user && username === user.username && (
                            <h3
                                className={showSavedPrompts ? "active" : ""}
                                onClick={() => handleClick("savedPrompts")}
                            >
                                Saved
                            </h3>
                        )}
                    </nav>
                    {showStories && <Stories />}
                    {showActivity && <Activity />}
                    {showFavorites && <Favorites />}
                    {showFollowing && <Following />}
                    {showFollowers && <Followers />}
                    {showSavedPrompts && <SavedPrompts />}
                </div>
            </div>
        </div>
    );
};
