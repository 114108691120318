import React, { useState } from "react";
import { doc, updateDoc } from "firebase/firestore"; 
import { firestore } from "../../../firebase/firebase";
import { useDispatch, useSelector } from "react-redux";
import { setContests, setStories, setUsers } from "../../../app/appSlice";

export const SelectFeature = ({ story }) => {
    const [selectedFeature, setSelectedFeature] = useState("");
    const { stories, users, contests } = useSelector(state => state.app);
    const dispatch = useDispatch();
    
    const handleFeatureChange = (event) => {
        setSelectedFeature(event.target.value); // Set selected feature based on dropdown
    };

    const handleClick = async () => {
        if (!selectedFeature) {
            alert("Please select a feature type");
            return;
        }

        const storyRef = doc(firestore, "stories", story.id); // Firestore reference for the story
        const userRef = doc(firestore, "users", story.user); // Reference to the user document
        let contestRef = null;

        if (selectedFeature === "winner") {
            const contest = contests.find(c => c.id === story.contestId);
            contestRef = doc(firestore, "contests", contest.id); // Firestore reference for the contest
        }

        try {
            // 1. Update the story with the selected feature (e.g., POTD or Contest Winner)
            const storyUpdateData = {
                badge: selectedFeature
            };
            await updateDoc(storyRef, storyUpdateData);

            // 2. Calculate bonus based on the selected feature
            const bonusAmount = selectedFeature === "winner" ? 500 : 200;

            // 3. Update the user with the new badge and bonus
            const user = users.find(u => u.username === story.user);
            const updatedBadges = user.badges ? [...user.badges, { type: selectedFeature, storyId: story.id, contestId: story.contestId }] : [{ type: selectedFeature, storyId: story.id, contestId: story.contestId }];
            const updatedBonus = (user.bonus || 0) + bonusAmount;

            await updateDoc(userRef, { badges: updatedBadges, bonus: updatedBonus });

            // 4. If it's a contest winner, update the contest with the winning story
            if (selectedFeature === "winner" && contestRef) {
                const contestUpdateData = {
                    winner: {
                        storyId: story.id,
                    }
                };
                await updateDoc(contestRef, contestUpdateData);
            }

            // 5. Update the Redux state
            const updatedStories = stories.map(s => 
                s.id === story.id ? { ...s, badge: selectedFeature } : s
            );

            const updatedUsers = users.map(u =>
                u.username === story.user ? { ...u, badges: updatedBadges, bonus: updatedBonus } : u
            );

            dispatch(setStories(updatedStories));
            dispatch(setUsers(updatedUsers));

            if (selectedFeature === "winner") {
                const updatedContests = contests.map(c =>
                    c.id === story.contestId ? { ...c, winner: { storyId: story.id } } : c
                );
                dispatch(setContests(updatedContests));
            }

            alert(`Story marked as ${selectedFeature}, and ${bonusAmount} bonus points awarded to ${story.user}`);

        } catch (error) {
            console.error("Error updating feature:", error);
        }
    };

    return (
        <div className="select-feature">
            <select value={selectedFeature} onChange={handleFeatureChange}>
                <option value="">Select Feature Type</option>
                <option value="potd">POTD</option>
                <option value="winner">Contest Winner</option>
            </select>
            <button onClick={handleClick}>Select Feature</button>
        </div>
    );
};
