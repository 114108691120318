import React, { useEffect } from 'react';
import { Nav } from '../app/nav/nav';
import { Outlet } from 'react-router-dom';
import { Footer } from './footer/footer';
import PageViewTracker from './pageViewTracker';
import { useDispatch, useSelector } from "react-redux";
import { fetchAppData} from './appSlice'; // Import setUsers action
import { useAuth } from "../contexts/authContext/authContext";

function App() {
    const { currentUser } = useAuth();
    const dispatch = useDispatch();
    const { users, stories, activity, contests, following, isLoading, error, user, forumQuestions,forumAnswers,forumComments, potds, news, userPrompts } = useSelector(state => state.app);

    const data = {
        users,
        stories,
        activity,
        contests,
        user,
        error,
        isLoading,
        following,
        forumAnswers,
        forumComments,
        forumQuestions,
        potds,
        news,
        userPrompts
    };

    // Fetch app data on component mount or when currentUser changes
    useEffect(() => {
        dispatch(fetchAppData());
    }, [dispatch, currentUser]);

    

    return (
        <div className="App">
            {isLoading && <div className="loading-screen">
                <div className="loading-overlay"></div>
                <img src="/images/favlogotrans.png" alt="Loading..." className="loading-image" loading="lazy" />
            </div>}
            <Nav />
            <PageViewTracker />
            <div className='mainOutlet'><Outlet context={data} /></div>
            <Footer />
        </div>
    );
}

export default App;
