import React, {useState} from "react";
import { Link } from "react-router-dom";
import './blogCarousel.css';


export const BlogCarousel = () => {

    const [vis1,setVis1] = useState(true)
    const [vis2,setVis2] = useState(false)
    const [vis3,setVis3] = useState(false)


    const handleClick =(direction) => {
        if(vis1 && direction==='prev'){
            setVis1(false);
            setVis3(true);
        }
        if(vis1 && direction==='next'){
            setVis1(false);
            setVis2(true)
            
        }
        if(vis2 && direction==='prev'){
            setVis2(false);
            setVis1(true);
            
        }
        if(vis2 && direction==='next'){
            setVis2(false);
            setVis3(true);
            
        }
        if(vis3 && direction==='prev'){
            setVis3(false);
            setVis2(true);
            
        }
        if(vis3 && direction==='next'){
            setVis3(false);
            setVis1(true);
            
        }

    }

    return(
            <div className="carousel">
                <div className="carousel-prev" onClick={(()=>handleClick('prev'))}>‹</div>
                    {vis1 && 
                        <div className="carousel-item" id='blog2'>
                            <Link to='/blog/welcome-to-writician' className='blogLink'>
                                <div className='blogTop' id='blogTop1'>
                                    <img className='blog-carousel-image' src='/images/assortedpics/amandine-bataille-5W78I6Jvbjw-unsplash.jpg' alt='typewriter' loading="lazy"/>
                                    <div className = 'blog-carousel-header'>
                                        <div className='blogTitle'>Welcome to Writician!</div>
                                        <div className='blogHook'>A new space for creative writers to learn, share, and grow together.</div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    }
                    {vis2 && 
                        <div className="carousel-item" id='blog1'>
                            <Link to='/blog/are-you-a-gardener-or-an-architect' className='blogLink'>
                                <div className='blogTop' id='blogTop2'>
                                    <img className='blog-carousel-image' src='/images/blog2.png' alt='building next to garden' loading="lazy"/>
                                    <div className = 'blog-carousel-header'>
                                        <div className='blogTitle'>Are you a Gardener or an Architect?</div>
                                        <div className='blogHook'>Find out your plotting style and what it means for your next writing project. </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    }

                    {vis3 && 
                        <div className="carousel-item" id='blog3'>
                            <Link to='/blog/creative-writing-exercise-1' className='blogLink'>
                                <div className='blogTop' id='blogTop3'>
                                    <img className='blog-carousel-image' src='/images/magic.jpg' alt='book with magic coming to life' loading="lazy"/>
                                    <div className = 'blog-carousel-header'>
                                        <div className='blogTitle'>Creative Writing Exercise #1</div>
                                        <div className='blogHook'>Magical abilities rooted in unique world-building.</div>
                                    </div>
                                </div>
                            </Link>
                        </div> 
                    } 
                <div className="carousel-next" onClick={()=>handleClick('next')}>›</div> 
            </div>
    )
}


