import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setUser, setUsers } from "../../app/appSlice";
import { doc, updateDoc } from "firebase/firestore";
import { firestore } from "../../firebase/firebase"; // Import Firestore instance

export const SavePrompt = ({ prompt, type }) => {
    const dispatch = useDispatch();
    const { users, user } = useSelector(state => state.app); // Get users and profile from the global state
    const [isSaved, setIsSaved] = useState(false);

    useEffect(() => {
        setIsSaved(false);
    }, [prompt]);

    const handleClick = async () => {
        if (!user || !user.username) {
            console.error("User must be logged in to save a prompt.");
            return;
        }

        // Format the prompt to be saved
        const newPrompt = {
            prompt: prompt,
            type: type,
            createdAt: new Date().toISOString(),
        };

        // Check if the prompt is already saved (based on some unique identifier like prompt content)
        const isAlreadySaved = user.savedPrompts?.some(savedPrompt => savedPrompt.prompt === newPrompt.prompt);

        if (isAlreadySaved) {
            setIsSaved(true);
            return;
        }

        // Update the profile with the new prompt in savedPrompts
        const updatedProfile = {
            ...user,
            savedPrompts: user.savedPrompts ? [...user.savedPrompts, newPrompt] : [newPrompt],
        };

        // Update users array with the updated profile
        const updatedUsers = users.map(u =>
            u.username === user.username ? updatedProfile : u
        );

        // Dispatch actions to update profile and users in the global state
        dispatch(setUser(updatedProfile));
        dispatch(setUsers(updatedUsers));

        // Save the updated profile in Firestore
        try {
            const profileRef = doc(firestore, "users", user.username); // Reference to the user's Firestore document
            await updateDoc(profileRef, {
                savedPrompts: updatedProfile.savedPrompts // Save the updated prompts field
            });
        } catch (error) {
            console.error("Error saving prompt to Firestore: ", error);
        }

        setIsSaved(true); // Set the state to true after saving
    };

    return (
        <div className='save'>
            <button id='button2' onClick={handleClick} disabled={isSaved}>
                {isSaved ? "Saved" : "Save"}
            </button>
        </div>
    );
};
