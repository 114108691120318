import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faComment,
  faHeart,
  faStar,
  faEye,
  faThumbsUp as faThumbsUpFilled,
} from "@fortawesome/free-solid-svg-icons";
import { formatDate, formatPotdDate } from "../../../utilities/formatDate";
import { DisplayGenreIcon } from "../../../utilities/displayGenreIcon";
import { setStories } from "../../../app/appSlice"; // Add this if stories are managed by Redux.
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../../firebase/firebase";

export const UserPrompt = () => {
  useEffect(() => {
    window.scrollTo(0, 200);
  }, []);

  const { promptId } = useParams();
  const navigate = useNavigate();
  const { userPrompts, stories, user } = useSelector((state) => state.app);
  const dispatch = useDispatch();

  // Find the user prompt with the matching promptId
  const prompt = userPrompts.find((userPrompt) => userPrompt.uid === promptId);

  const isPromptAuthor = prompt?.user === user?.username;

  // Filter the stories related to this prompt
  const relatedStories = stories.filter((story) => story.promptId === promptId);

  // Find the featured story for the prompt
  const featuredStory = relatedStories.find((story) => story.featured === true);

  // Filter out the featured story from the rest
  const nonFeaturedStories = relatedStories.filter(
    (story) => story.featured !== true
  );

  const handleStoryClick = (user, storyId) => {
    navigate(`/${user}/${storyId}`);
  };

  const handleSetAsFeatured = async (storyId) => {
    try {
      // Find the current featured story for this prompt
      const currentFeaturedStory = stories.find(
        (story) => story.promptId === promptId && story.featured
      );
  
      // If there is already a featured story, remove its "featured" status
      if (currentFeaturedStory) {
        const currentFeaturedRef = doc(db, "stories", currentFeaturedStory.uid);
        await updateDoc(currentFeaturedRef, { featured: false });
      }
  
      // Set the new story as featured
      const newFeaturedRef = doc(db, "stories", storyId);
      await updateDoc(newFeaturedRef, { featured: true });
  
      // Update the Redux state
      const updatedStories = stories.map((story) =>
        story.promptId === promptId
          ? { ...story, featured: story.uid === storyId }
          : story
      );
      dispatch(setStories(updatedStories)); // Update the state
  
      console.log("Story updated successfully in Firebase!");
    } catch (error) {
      console.error("Error updating featured story in Firebase:", error);
    }
  };

  if (!prompt) {
    return <div>Prompt not found</div>;
  }

  return (
    <div>
      <h2 style={{color: '#007bff'}}><Link to={`/${prompt.user}`}className='prompt-user-link'>~{prompt.user}</Link> / {formatPotdDate(prompt.time)}</h2>
      <div id="story-page-prompt">{prompt.prompt}</div>

      {/* Display featured story if it exists */}
      {featuredStory && (
        <>
          <h3>Featured Story:</h3>
          <div className="prof-stories">
            <div
              className="prof-story"
              onClick={() =>
                handleStoryClick(featuredStory.user, featuredStory.uid)
              }
            >
              <div className="prof-story-details">
                <h3 className="storyTitle">{featuredStory.title}</h3>
                <h4 style={{ color: "black" }}>{featuredStory.author}</h4>
                <DisplayGenreIcon
                  genres={featuredStory.genres}
                  style={{
                    width: "30px",
                    borderRadius: "25px",
                    margin: ".5rem",
                  }}
                />
                <p className="prof-snippet">
                  <i>{featuredStory.story.substring(0, 500)}...</i>
                </p>
              </div>
              <div className="story-interaction">
                <span>
                  <FontAwesomeIcon id="thumb" icon={faThumbsUpFilled} />{" "}
                  {featuredStory.likes ? featuredStory.likes.length : 0}
                </span>
                {featuredStory.rateable && (
                  <span>
                    <FontAwesomeIcon id="star" icon={faStar} />{" "}
                    {featuredStory.ratings
                      ? (
                          featuredStory.ratings.reduce(
                            (sum, r) => sum + r.rating,
                            0
                          ) / featuredStory.ratings.length
                        ).toFixed(1)
                      : 0}{" "}
                    ({featuredStory.ratings
                      ? featuredStory.ratings.length
                      : 0})
                  </span>
                )}
                <span>
                  <FontAwesomeIcon id="comment" icon={faComment} />{" "}
                  {featuredStory.comments
                    ? featuredStory.comments.length
                    : 0}
                </span>
                <span>
                  <FontAwesomeIcon id="heart" icon={faHeart} />{" "}
                  {featuredStory.favorites
                    ? featuredStory.favorites.length
                    : 0}
                </span>
                <span>
                  <FontAwesomeIcon id="eye" icon={faEye} />{" "}
                  {featuredStory.views}
                </span>
                <span>{formatDate(featuredStory.time)}</span>
              </div>
            </div>
          </div>
        </>
      )}

      {/* Display remaining stories */}
      <h3>
        {nonFeaturedStories.length}{" "}
        {
        nonFeaturedStories.length === 0 || (!featuredStory && nonFeaturedStories.length > 1)
            ? "Stories"
            : nonFeaturedStories.length === 1
            ? !featuredStory
            ? "Story"
            : "Other Story"
            : "Other Stories"
        }
      </h3>
      {nonFeaturedStories.length > 0 && (
        <div className="prof-stories">
          {nonFeaturedStories.map((story) => (
            <div
              key={story.uid}
              className="prof-story"
              onClick={() => handleStoryClick(story.user, story.uid)}
            >
              <div className="prof-story-details">
                <h3 className="storyTitle">{story.title}</h3>
                <h4 style={{ color: "black" }}>{story.author}</h4>
                <DisplayGenreIcon
                  genres={story.genres}
                  style={{
                    width: "30px",
                    borderRadius: "25px",
                    margin: ".5rem",
                  }}
                />
                <p className="prof-snippet">
                  <i>{story.story.substring(0, 500)}...</i>
                </p>
              </div>
              <div className="story-interaction">
                <span>
                  <FontAwesomeIcon id="thumb" icon={faThumbsUpFilled} />{" "}
                  {story.likes ? story.likes.length : 0}
                </span>
                {story.rateable && (
                  <span>
                    <FontAwesomeIcon id="star" icon={faStar} />{" "}
                    {story.ratings
                      ? (
                          story.ratings.reduce((sum, r) => sum + r.rating, 0) /
                          story.ratings.length
                        ).toFixed(1)
                      : 0}{" "}
                    ({story.ratings ? story.ratings.length : 0})
                  </span>
                )}
                <span>
                  <FontAwesomeIcon id="comment" icon={faComment} />{" "}
                  {story.comments ? story.comments.length : 0}
                </span>
                <span>
                  <FontAwesomeIcon id="heart" icon={faHeart} />{" "}
                  {story.favorites ? story.favorites.length : 0}
                </span>
                <span>
                  <FontAwesomeIcon id="eye" icon={faEye} /> {story.views}
                </span>
                <span>{formatDate(story.time)}</span>
              </div>

              {/* Show "Set as Featured" button for the prompt author */}
              {isPromptAuthor && (
                <button
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent triggering the story click
                    handleSetAsFeatured(story.uid);
                  }}
                  className="set-featured-button"
                >
                  Set as Featured
                </button>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
