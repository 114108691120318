import React from "react"
import { Link } from "react-router-dom"

export const PromptHome =()=> {
    window.scrollTo(0,0)

    return (
        <div id='promptHome'>
            
            <h1>Welcome to <strong>Writician</strong>'s world renowned <br/>creative writing prompt generators!</h1>
            <div className="prompts-home-description">
                <p>These unique, hand-crafted writing prompts are designed to challenge you to write complete, complex, and perhaps sometimes out-of-your-comfort-zone type stories.
                    They are meant to be roadmaps for your story, but that's no reason not to get your tires a little dirty.
                </p>
                <p>Once you've chosen your prompt, you can begin working on it right away, or save it to your profile dashboard to write later. When you're ready, submit your story and share it with your fellow writicians.</p>
                <p>Don't forget to check out our <Link to='/contests' className="link" style={{color: 'gray'}}>Contests</Link> page to see if your story fits the criteria for any open competitions.</p>
            </div>
            <h2>
            

            </h2>        
        </div>
    )
}
