import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectPlotPrompt, selectElements, displayPlot } from "./plotPromptSlice";
import { useDispatch } from "react-redux";
import './selector.css'

export const Selector = () => {
    const plotPrompt = useSelector(selectPlotPrompt);
    const dispatch = useDispatch();

    const [count, setCount] = useState(0);

    // useEffect to calculate the number of selected checkboxes when plotPrompt changes
    useEffect(() => {
        let selectedCount = 0;
        Object.keys(plotPrompt).forEach((key) => {
            if (plotPrompt[key] === true) selectedCount++;
        });
        setCount(selectedCount); // Update the count
    }, [plotPrompt]);

    // Function to handle checkbox changes
    const handleChange = (item) => {
        const isChecked = plotPrompt[item]; // Current state of the checkbox
    
        if (isChecked && count === 2) {
            return; 
        }
    
        dispatch(selectElements(item));
        // Dispatch displayPlot if the prompt is not empty
        if (plotPrompt.prompt !== '') {
            dispatch(displayPlot());
        }
    };

    // Destructure items for easier access
    const { gen: genre, plo: plot, pers: personality, char: character, ar: arc, th: theme, ho: hook, end: ending, ld: litDevice, ns: narStyle, gen2: genre2 } = plotPrompt;


    return (
        <div>
            <fieldset className="selector">
                <legend>Choose your Story Elements</legend>
                <div className="check">
                    <div>
                        <input type="checkbox" id="genre" name="genre"
                            value="genre" checked={genre} onChange={() => handleChange('gen')} />
                        <label htmlFor="genre">Genre</label>
                    </div>
                    <div>
                        <input type="checkbox" id="plot" name="plot"
                            value="plot" checked={plot} onChange={() => handleChange('plo')} />
                        <label htmlFor="plot">Plot</label>
                    </div>
                    <div>
                        <input type="checkbox" id="personality" name="personality"
                            value="personality" checked={personality} onChange={() => handleChange('pers')} />
                        <label htmlFor="personality">Personality</label>
                    </div>
                    <div>
                        <input type="checkbox" id="character" name="character"
                            value="character" checked={character} onChange={() => handleChange('char')} />
                        <label htmlFor="character">Character</label>
                    </div>
                    <div>
                        <input type="checkbox" id="arc" name="arc"
                            value="arc" checked={arc} onChange={() => handleChange('ar')} />
                        <label htmlFor="arc">Arc</label>
                    </div>
                    <div>
                        <input type="checkbox" id="theme" name="theme"
                            value="theme" checked={theme} onChange={() => handleChange('th')} />
                        <label htmlFor="theme">Theme</label>
                    </div>
                </div>
                <div className="check">
                    <div>
                        <input type="checkbox" id="genre2" name="genre2"
                            value="genre2" checked={genre2} onChange={() => handleChange('gen2')} />
                        <label htmlFor="genre2">Genre 2</label>
                    </div>
                    <div>
                        <input type="checkbox" id="hook" name="hook"
                            value="hook" checked={hook} onChange={() => handleChange('ho')} />
                        <label htmlFor="hook">Hook</label>
                    </div>
                    <div>
                        <input type="checkbox" id="ending" name="ending"
                            value="ending" checked={ending} onChange={() => handleChange('end')} />
                        <label htmlFor="ending">Ending</label>
                    </div>
                    <div>
                        <input type="checkbox" id="litDevice" name="litDevice"
                            value="litDevices" checked={litDevice} onChange={() => handleChange('ld')} />
                        <label htmlFor="litDevice">Literary Device</label>
                    </div>
                    <div>
                        <input type="checkbox" id="narStyle" name="narStyle"
                            value="narStyle" checked={narStyle} onChange={() => handleChange('ns')} />
                        <label htmlFor="narStyle">Narrative Style</label>
                    </div>
                </div>
            </fieldset>
        </div>
    );
};
