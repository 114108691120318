import { doc, updateDoc } from "firebase/firestore";
import { firestore } from "../firebase/firebase";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { setUsers } from "../app/appSlice";

// Helper function to calculate writer points
const calculatePoints = (totalWords, writerLikes, writerComments, totalWriterReplies, writerRatings, writerAvgRating) => {
    return (
        Math.floor(totalWords / 20) +
        calculateLikesPoints(writerLikes) +
        writerComments * 5 +
        totalWriterReplies * 2 +
        calculateLikesPoints(writerRatings) * writerAvgRating
    );
};

// Helper function to calculate reader points
const calculateReaderPoints = (readerLikes, readerComments, readerReplies, readerRatings, forumPosts, forumAnswers, forumLikes, forumComments, userThoughts) => {
    return (
        calculateLikesPoints(readerLikes) +
        readerComments * 5 +
        readerReplies * 2 +
        readerRatings * 2 +
        forumPosts * 3 +
        forumAnswers * 5 +
        forumComments * 2 +
        (calculateLikesPoints(forumLikes) / 2 ) +
        calculateLikesPoints(userThoughts)
    );
};

// Helper function to calculate points based on stats
const calculateLikesPoints = (stats) => {
    let points = 0;
    if (stats > 10000) {
        points += (stats - 500) * 0.01;
        stats = 10000;
    }
    if (stats > 5000) {
        points += (stats - 500) * 0.05;
        stats = 5000;
    }
    if (stats > 500) {
        points += (stats - 500) * 0.1;
        stats = 500;
    }
    if (stats > 200) {
        points += (stats - 200) * 0.25;
        stats = 200;
    }
    if (stats > 100) {
        points += (stats - 100) * 0.5;
        stats = 100;
    }
    points += stats * 1;
    return points;
};

// Helper function to determine rank
const determineRank = (totalPoints) => {
    switch (true) {
        case totalPoints > 5000: return "Master";
        case totalPoints > 3000: return "Wordsmith";
        case totalPoints > 2000: return "Storyteller";
        case totalPoints > 1000: return "Scribe";
        case totalPoints > 500: return "Journeyman";
        case totalPoints > 250: return "Apprentice";
        case totalPoints > 100: return "Scribbler";
        default: return "Novice";
    }
};

export const UserPoints = ({ profile }) => {
    const { users, activity, stories, forumAnswers } = useSelector(state => state.app);
    const dispatch = useDispatch();
    const username = profile.username;

    useEffect(() => {
        const updateUserPoints = async () => {
            try {
                const userActivities = activity.filter(a => a.user === username);
                const userStories = stories.filter(story => story.user === username);
                const userForumAnswers = forumAnswers.filter(a=>a.user===username);
                const forumLikes = userForumAnswers.reduce((total, answer) => total + (answer.likes?.length || 0), 0);

                // Reader stats
                const readerLikes = new Set(userActivities.filter(a => a.action === "liked").map(a => a.storyId)).size;
                const readerComments = userActivities.filter(a => a.action === "commented on" && a.type === "story" && a.username !== profile.username).length;
                const readerReplies = userActivities.filter(a => a.action === "replied to a comment on" && a.type === "story" && a.username !== profile.username).length;
                const readerRatings = new Set(userActivities.filter(a => a.action === "rated").map(a => a.storyId)).size;
                const forumPosts = userActivities.filter(a => a.action === "posted").length;
                const userAnswers = userActivities.filter(a => a.action === "answered").length;
                const forumComments = userActivities.filter(a => a.action === "commented on" && a.type === "forum").length;
                const userThoughts = userActivities.filter(a => a.action === "shared a thought").length;

                // Writer stats
                const totalWords = userStories.reduce((sum, story) => sum + (story.wordCount || 0), 0);
                const writerLikes = userStories.reduce((sum, story) => sum + (story.likes ? story.likes.length : 0), 0);
                const writerComments = userStories.reduce((sum, story) => {
                const filteredComments = story.comments ? story.comments.filter(comment => comment.username !== profile.username) : [];
                    return sum + filteredComments.length;
                }, 0);
                const writerRatings = userStories.reduce((sum, story) => sum + (story.ratings ? story.ratings.length : 0), 0);
                const totalWriterRating = userStories.reduce((sum, story) => sum + (story.ratings ? story.ratings.reduce((acc, r) => acc + (r.rating || 0), 0) : 0), 0);
                const totalWriterReplies = userStories.reduce((sum, story) => sum + (story.comments ? story.comments.reduce((acc, comment) => acc + (comment.replies ? comment.replies.length : 0), 0) : 0), 0);
                const writerAvgRating = writerRatings ? totalWriterRating / writerRatings : 0;

                // Calculate points
                const writerPoints = calculatePoints(totalWords, writerLikes, writerComments, totalWriterReplies, writerRatings, writerAvgRating);
                const readerPoints = calculateReaderPoints(readerLikes, readerComments, readerReplies, readerRatings, forumPosts, userAnswers, forumLikes, forumComments, userThoughts);
                
                const totalPoints = writerPoints + readerPoints + profile.bonus;
                const rank = determineRank(totalPoints);

                const userPointsRef = doc(firestore, `users/${profile.username}`);
                await updateDoc(userPointsRef, { points: totalPoints, rank });

                // Use a stable reference for users to prevent re-renders
                const updatedUsers = users.map(user => user.username === username ? { ...user, points: totalPoints, rank } : user);
                
                // Only dispatch if the users array actually changes
                if (JSON.stringify(updatedUsers) !== JSON.stringify(users)) {
                    dispatch(setUsers(updatedUsers));
                }

            } catch (error) {
                console.error("Error updating user points:", error);
            }
        };

        if (username) {
            updateUserPoints();
        }
    }, [username, activity, stories, profile.username, users, dispatch, forumAnswers, profile.bonus]); 


    return (
        <span>{profile.points}</span>
    );
};
