import { createSlice } from "@reduxjs/toolkit";



const generate = () => {
    let usp = {
        element: [
            'Braveheart','Don Quixote','Hunger Games','Harry Potter','Twilight','Brave New World',
            'Lord of the Rings','Inception','Star Wars','Forest Gump','Pirates of the Carribean',
            'Game of Thrones','Enders Game','Hitchhikers Guide to the Galaxy',
            'Fear and Loathing in Las Vegas','How to Train Your Dragon','Alice in Wonderland',
            'The Devil Wears Prada','The Godfather','Goodfellas','Blow','Scarface','Friday',
            'The Name of the Wind','Three Musketeers','Peter Pan','Lion King','300',
            'The Shawshank Redemption','E.T.','The Dark Knight','Pulp Fiction','12 Angry Men',
            'Fight Club','The Matrix','Saving Private Ryan','The Silence of the Lambs',
            'Back to the Future','The Terminator','Interstellar','American History X',
            'Lord of the Flies','Gladiator','Apocalypse Now','Step Brothers','X-Men','Toy Story',
            'Good Will Hunting','Citizen Kane','Indiana Jones','Die Hard','The Sixth Sense',
            'Blade Runner','Gone Girl','Sherlock Holmes','Mad Max','Rocky','Into the Wild','Jaws',
            'Dances With Wolves','A Tale of Two Cities','The Lion, the Witch, and the Wardrobe',
            'The Da Vinci Code','The Alchemist','One Hundred Years of Solitude',
            'The Catcher in the Rye','The Great Gatsby','Nineteen Eighty-Four',
            'The Fault in Our Stars','Animal Farm','Robinson Crusoe','Dune','Fifty Shades of Grey',
            'Guardians of the Galaxy','War of the Worlds','The Iliad','Life of Pi',
            'A Wrinkle in Time','The Giver','Tarzan','TopGun',`Uncle Tom's Cabin`,'War and Peace',
            'Mean Girls','To Kill a Mockingbird','The Lost World','Anna Karenina','The Jungle Book',
            'Frankenstein', 'Moby-Dick','The Adventures of Huckleberry Finn','Dracula',
            `The Handmaid's Tale`,'Titanic',
        ],
    }

    let random0 = Math.floor(Math.random() * usp.element.length);
    let random1 = Math.floor(Math.random() * usp.element.length);
    
    if(random0 === random1){random1+=1};
    let elem1 = usp.element[random0];
    let elem2 = usp.element[random1];

    const items = {elem1, elem2};
    return items;
}

export const uspPromptSlice = createSlice({
    name: 'uspPrompt',
    initialState: {
        uspPrompt: '',
    },
    reducers: {
        uspGen: (state) => {
            
            let elem1 = generate().elem1;
            let elem2 = generate().elem2;
            state.uspPrompt = `${elem1} meets ${elem2}`;
        },

        // savePrompt: (state, action) => {
        //     for(let i=0 ; i<state.savedPrompts.length+1 ; i++){
        //         if(state.savedPrompts[i]===action.payload){
        //             return
        //         }
        //     }
        //     state.savedPrompts.push(state.uspPrompt)
        // },
        // removePrompt: (state, action) => {
        //     state.savedPrompts = state.savedPrompts.filter((prompt) => prompt !== action.payload)

        // }
    }
})



export const selectUspPrompt = (state) => state.uspPrompt.uspPrompt
export const selectSavedPrompts = (state) => state.uspPrompt.savedPrompts
export default uspPromptSlice.reducer;
export const { uspGen, savePrompt, removePrompt } = uspPromptSlice.actions;