import React from "react";
import { NavLink } from "react-router-dom";
import { useEffect } from "react";

import { genres } from "../../prompts/plotPrompt/descriptions";

export const Genres = () => {
    useEffect(()=>{
        window.scrollTo(0,0)
    },[])

    return(
        <div>
            <header><h1>Genres</h1></header>
            <NavLink to='/story-elements/plot-elements'><button id='button3'>Back</button></NavLink>
            <br/>
            <br/>
            <div className = 'genres'>

                <div className='genre'>
                    {genres.literaryFiction}
                    <img alt='Literary Fiction' src='/images/genre-icons/literary.png' style={{width: '100px', height: '100px', borderRadius: '55px'}} loading="lazy"/>
                </div>
                <div className='genre'>
                    {genres.fantasy}
                    <img alt='Fantasy' src='/images/genre-icons/fantasy.png' style={{width: '100px', height: '100px', borderRadius: '55px'}} loading="lazy"/>
                </div>
                <div className='genre'>
                    {genres.scifi}
                    <img alt='Science Fiction' src='/images/genre-icons/science fiction.png' style={{width: '100px', height: '100px', borderRadius: '55px'}} loading="lazy"/>
                </div>
                <div className='genre'>
                    {genres.romance}
                    <img alt='Romance' src='/images/genre-icons/romance.png' style={{width: '100px', height: '100px', borderRadius: '55px'}} loading="lazy"/>
                </div>
                <div className='genre'>
                    {genres.historicalFiction}
                    <img alt='Historical Fiction' src='/images/genre-icons/historical fiction.png' style={{width: '100px', height: '100px', borderRadius: '55px'}} loading="lazy"/>
                </div>
                <div className='genre'>
                    {genres.western}
                    <img alt='Western' src='/images/genre-icons/western.png' style={{width: '100px', height: '100px', borderRadius: '55px'}} loading="lazy"/>
                </div>
                <div className='genre'>
                    {genres.mystery}
                    <img alt='Mystery' src='/images/genre-icons/mystery.png' style={{width: '100px', height: '100px', borderRadius: '55px'}} loading="lazy"/>
                </div>
                <div className='genre'>
                    {genres.thriller}
                    <img alt='Thriller' src='/images/genre-icons/thriller.png' style={{width: '100px', height: '100px', borderRadius: '55px'}} loading="lazy"/>
                </div>
                <div className='genre'>
                    {genres.adventure}
                    <img alt='Adventure' src='/images/genre-icons/adventure.png' style={{width: '100px', height: '100px', borderRadius: '55px'}} loading="lazy"/>
                </div>
                <div className='genre'>
                    {genres.horror}
                    <img alt='Horror' src='/images/genre-icons/horror.png' style={{width: '100px', height: '100px', borderRadius: '55px'}} loading="lazy"/>
                </div>
                <div className='genre'>
                    {genres.spaceOpera}
                    <img alt='Space Opera' src='/images/genre-icons/space opera.png' style={{width: '100px', height: '100px', borderRadius: '55px'}} loading="lazy"/>
                </div>
                <div className='genre'>
                    {genres.firstContact}
                    <img alt='First Contact' src='/images/genre-icons/first contact.png' style={{width: '100px', height: '100px', borderRadius: '55px'}} loading="lazy"/>
                </div>
                <div className='genre'>
                    {genres.dystopian}
                    <img alt='Dystopian' src='/images/genre-icons/dystopian.png' style={{width: '100px', height: '100px', borderRadius: '55px'}} loading="lazy"/>
                </div>
                <div className='genre'>
                    {genres.steampunk}
                    <img alt='Steampunk' src='/images/genre-icons/steampunk.png' style={{width: '100px', height: '100px', borderRadius: '55px'}} loading="lazy"/>
                </div>
                <div className='genre'>
                    {genres.cyberpunk}
                    <img alt='Cyberpunk' src='/images/genre-icons/cyberpunk.png' style={{width: '100px', height: '100px', borderRadius: '55px'}} loading="lazy"/>
                </div>
                <div className='genre'>
                    {genres.postApocalyptic}
                    <img alt='Post-Apocalyptic' src='/images/genre-icons/post-apocalyptic.png' style={{width: '100px', height: '100px', borderRadius: '55px'}} loading="lazy"/>
                </div>
                <div className='genre'>
                    {genres.altHistory}
                    <img alt='Alternative History' src='/images/genre-icons/alternative history.png' style={{width: '100px', height: '100px', borderRadius: '55px'}} loading="lazy"/>
                </div>
                <div className='genre'>
                    {genres.highFantasy}
                    <img alt='High Fantasy' src='/images/genre-icons/fantasy.png' style={{width: '100px', height: '100px', borderRadius: '55px'}} loading="lazy"/>
                </div>
                <div className='genre'>
                    {genres.lowFantasy}
                    <img alt='Low Fantasy' src='/images/genre-icons/fantasy.png' style={{width: '100px', height: '100px', borderRadius: '55px'}} loading="lazy"/>
                </div>
                <div className='genre'>
                    {genres.epic}
                    <img alt='Epic' src='/images/genre-icons/epic.png' style={{width: '100px', height: '100px', borderRadius: '55px'}} loading="lazy"/>
                </div>
                <div className='genre'>
                    {genres.urbanFantasy}
                    <img alt='Urban Fantasy' src='/images/genre-icons/urban fantasy.png' style={{width: '100px', height: '100px', borderRadius: '55px'}} loading="lazy"/>
                </div>
                <div className='genre'>
                    {genres.magicalRealism}
                    <img alt='Magical Realism' src='/images/genre-icons/magical realism.png' style={{width: '100px', height: '100px', borderRadius: '55px'}} loading="lazy"/>
                </div>
                <div className='genre'>
                    {genres.superHero}
                    <img alt='Superhero' src='/images/genre-icons/superhero.png' style={{width: '100px', height: '100px', borderRadius: '55px'}} loading="lazy"/>
                </div>
                <div className='genre'>
                    {genres.spyFiction}
                    <img alt='Spy Fiction' src='/images/genre-icons/spy.png' style={{width: '100px', height: '100px', borderRadius: '55px'}} loading="lazy"/>
                </div>
                <div className='genre'>
                    {genres.satire}
                    <img alt='Satire' src='/images/genre-icons/satire.png' style={{width: '100px', height: '100px', borderRadius: '55px'}} loading="lazy"/>
                </div>
                <div className='genre'>
                    {genres.heist}
                    <img alt='Heist' src='/images/genre-icons/heist.png' style={{width: '100px', height: '100px', borderRadius: '55px'}} loading="lazy"/>
                </div>

                {/* <div className='genre'>{genres.middleGrade}</div>
                <div className='genre'>{genres.youngAdult}</div> */}
                

            </div>
        </div>
    )
}

                        
                       
                       