import React, { useEffect } from "react";
import { LeftSide } from "../../app/leftSide/leftSide";
import { RightSide } from "../../app/rightSide/rightSide";
import './about.css';
import { Message } from "./message/message";
import { Link } from "react-router-dom";
import { DisplayGenreIcon } from "../../utilities/displayGenreIcon";

export const About = () => {
    // Scroll to top on render
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const genres = ['science fiction','fantasy','mystery','thriller','dystopian','steampunk','romance','historical fiction','post-apocalyptic'];

//'adventure','horror','literary'

    return (
        <div className='page'>
            <LeftSide />
            <div className='content'>
                <header>
                    <h1>About Writician</h1>
                </header>
                <div className="about-container">
                    <div className="about-left">
                        <div className="about-genres">
                            <DisplayGenreIcon genres={genres} style={{width: '16rem', borderRadius: '10px', margin: '.5rem 2rem .5rem 2rem'}}/>
                        </div>
                    </div>
                    <div className="about-right">
                        <div className='aboutDiv'>
                            <h2>What we're all about</h2>
                            <p>
                            Writician is a platform built on creativity, growth, entertainment, and community. 
                            Our mission is to empower writers to hone their craft while offering readers a hub for discovering high-quality fiction.
                            </p>
                            <p>
                            Our ranking system is designed to inspire engagement and foster development without overwhelming writers.
                            By introducing a structured progression, we ensure writers build their skills step-by-step, starting with short stories before advancing to novels.
                            Skill-building prompts challenge writers to improve their technique and master story structure, preparing them for independent projects with confidence.
                            </p>
                            <p>
                            Through this approach, we aim to cultivate a dynamic, supportive, and vibrant community where writers thrive, readers enjoy exceptional fiction, and creativity flourishes.
                            </p>
                        </div>
                        <div className='aboutDiv'>
                            <h2>Ranking System</h2>
                            <p>
                                The ranking system is designed to encourage members to engage with the community and achieve writing milestones. As you write and engage more, you'll become more valorious, which contributes to your rank and unlocks new features. Here’s how the system works (subject to change):
                            </p>
                            <ul className="ranks-list">
                                <li className="ranks-list-item">
                                    <strong>Novice:</strong>
                                    words/story: 1250
                                    <span>Enter Contests</span>
                                </li>
                                <li className="ranks-list-item">
                                    <strong>Scribbler:</strong> 100 valor<br />
                                    words/story: 1500<br />
                                    <span>Rate Stories</span>
                                </li>
                                <li className="ranks-list-item">
                                    <strong>Apprentice:</strong> 250 valor<br />
                                    words/story: 2000
                                    <span></span>
                                </li>
                                <li className="ranks-list-item">
                                    <strong>Journeyman:</strong> 500 valor<br />
                                    words/story: 2500
                                </li>
                                <li className="ranks-list-item">
                                    <strong>Scribe:</strong> 1000 valor<br />
                                    words/story: 3000
                                    <span>Apply to be a Contest Judge</span>
                                </li>
                                <li className="ranks-list-item">
                                    <strong>Storyteller:</strong> 2000 valor<br />
                                    words/story: 3500
                                    <span>Submit Original Prompts</span>
                                </li>
                                <li className="ranks-list-item">
                                    <strong>Wordsmith:</strong> 3000 valor<br />
                                    words/story: 4000
                                    <span>Submit Promptless Stories</span>
                                </li>
                                <li className="ranks-list-item">
                                    <strong>Master:</strong> 5000 valor<br />
                                    words/story: 5000
                                    <span>Serialize Stories</span>
                                    <span>Submit Blog Posts</span>
                                </li>
                                <li className="ranks-list-item">
                                    <strong>???</strong>
                                    <span>Advanced Ranks TBT</span>
                                </li>
                            </ul>

                        </div>
                        <div className='aboutDiv'>
                            <h2>Features in Development</h2>
                            <ul>
                                <li>User Submitted Prompts</li>
                                <li>Serialized Stories</li>
                                <li>Shared Universes</li>
                                <li>Promptless Story Submissions</li>
                                <li>Art Prompts</li>
                                <li>Story Games</li>
                                <li>Judges Corner</li>
                                <li>Submit Blog Posts</li>
                                <li>User Created Contests</li>
                                <li>Critique Groups</li>
                                <li>Jackpot Contests</li>
                                <li>Give & Recieve Valor</li>
                                <li>Inline Story Comments</li>
                                <li>Author Highlights</li>
                                <li>Newsletter</li>
                                <li>Direct Messaging</li>
                                <li>Master’s Lounge</li>
                            </ul>
                        </div>
                        <div className="aboutDiv">
                        <h2>Support Writician on Patreon</h2>
                        <p>To get a detailed overview of the above features and a timeline for implementation (which you can speed up
                            exponentially with your support) check out our <Link style={{color:'black', fontWeight: '650'}} className='link'>Patreon Page. 
                            
                            </Link>
                        </p>

                        </div>
                        <div className="aboutDiv">
                        <Message />

                        </div>
                    </div>
                    
                </div>
                
            </div>
            <RightSide />
        </div>
    );
};
