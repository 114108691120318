import React from "react";
import { LeftSide } from "../../../app/leftSide/leftSide";
import { RightSide } from "../../../app/rightSide/rightSide";
import './contests.css';
import { Outlet, useNavigate } from "react-router-dom";

export const Contests = () => {

    const navigate = useNavigate();
    const handleClick =() => {
        navigate('/contests')
    }
    return (
        <div className="page">
            <LeftSide />
            <div className="content">
                <header><h1 id='contests-header'onClick={handleClick}>Contests</h1></header>
                <Outlet />
            </div>
            <RightSide />
        </div>
    );
};
