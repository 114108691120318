import React from "react"
import { LeftSide } from "../../app/leftSide/leftSide";
import { RightSide } from "../../app/rightSide/rightSide";
import { Link, Outlet } from "react-router-dom";

export const Blog = () => {

    return(
        <div className='page'>
            <LeftSide />
            <div className = 'content'>
                <header>
                <Link className='link' to={'/blog'}><h1 id='blogHeader'>The Writician Blog</h1></Link>
                </header>
                <Outlet/>
            </div>
            <RightSide />
        </div>
        
    )
}

/*<Outlet />*/