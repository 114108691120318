import React from "react";
import { useDispatch, useSelector } from "react-redux";
import JsonToReact from "../../utilities/jsonToReact";
import { setPrompt } from "../prompts/writePrompt/writePromptSlice";
import { NavLink, useNavigate } from "react-router-dom";
import { formatPotdDate } from "../../utilities/formatDate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBook } from "@fortawesome/free-solid-svg-icons";

export const PotdHome = () => {
  const { potds, stories } = useSelector((state) => state.app);
  const dispatch = useDispatch();
    const navigate = useNavigate();
  // Helper function to check if a prompt is within the last 24 hours
  const isWithin24Hours = (time) => {
    const currentTime = new Date().getTime();
    const promptTime = new Date(time).getTime();
    return currentTime - promptTime <= 24 * 60 * 60 * 1000; // 24 hours in milliseconds
  };

  // Sort potds by time in descending order
  const sortedPotds = [...potds].sort((a, b) => new Date(b.time) - new Date(a.time));

  // Find the most recent prompt
  const mostRecentPrompt = sortedPotds[0]; // After sorting, the first item is the most recent

  const handleWrite = (prompt) => {
    dispatch(setPrompt({ type: prompt.promptType, prompt: prompt.prompt, contest: 'POTD', contestId: prompt.id }));
  };

  return (
      <div className="potds">
        {sortedPotds.map((prompt, index) => {
          // Calculate the number of stories for the current prompt
          const storyCount = stories.filter(story => story.contest === 'potd' && story.contestId === prompt.id).length;

          const isRecent = prompt === mostRecentPrompt && isWithin24Hours(prompt.time);

          return (
            <div key={index} className="potd" >
              <h5  onClick={() => navigate(`/potd/${prompt.id}`)}>{formatPotdDate(prompt.time)}</h5>
              <div className="potd-prompt">{JsonToReact({ jsonString: prompt.prompt })}</div>
              <div className="potd-bottom">
                  {isRecent && (
                  <NavLink to="/writePrompt" className="potd-write">
                      <button id="button2" onClick={() => handleWrite(prompt)}>Write It</button>
                  </NavLink>
                  )}
                  <br/>
                  {storyCount} <FontAwesomeIcon icon={faBook} onClick={() => navigate(`/potd/${prompt.id}`)}/> 
              </div>
            </div>
          );
        })}
      </div>
  );
};
