import React, { useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbsUp as faThumbsUpFilled, faThumbsUp as faThumbsUpOutline, faComment, faTrashAlt, faComments } from "@fortawesome/free-solid-svg-icons";
import { doc, getDoc, updateDoc, arrayUnion, arrayRemove } from "firebase/firestore";
import { Link } from "react-router-dom";
import { firestore } from "../../../firebase/firebase";
import './displayThought.css'
import { formatDate } from '../../../utilities/formatDate';

export const DisplayThought = ({ thought, onDeleteThought, user }) => {
    const [currentThought, setCurrentThought] = useState(thought);
    const [comment, setComment] = useState("");
    const [reply, setReply] = useState("");
    const [visibleReplies, setVisibleReplies] = useState(new Map());
    const [commentsVisible, setCommentsVisible] = useState(false); 
    const [showDeletePopup, setShowDeletePopup] = useState(false); // State for delete popup
    const [commentWarning, setCommentWarning] = useState(false);
    const [replyWarning, setReplyWarning] = useState(false);

    // Handle likes
    const handleLike = async () => {
        if (!user) return;

        const thoughtRef = doc(firestore, `activity`, thought.uid);
        const thoughtDoc = await getDoc(thoughtRef);
        const thoughtData = thoughtDoc.data();
        const likes = thoughtData.likes || [];

        if (likes.includes(user.uid)) {
            await updateDoc(thoughtRef, { likes: arrayRemove(user.uid) });
        } else {
            await updateDoc(thoughtRef, { likes: arrayUnion(user.uid) });
        }

        const updatedThought = await getDoc(thoughtRef);
        setCurrentThought(updatedThought.data());
    };

    // Handle comment submission
    // Handle comment submission
const handleCommentSubmit = async () => {
    if (comment.trim() === "" || !user) return;

    const thoughtRef = doc(firestore, `activity`, thought.uid);
    
    // Check the comment object before submitting
    const newComment = {
        username: user.username || 'Unknown', // Default to 'Unknown' if undefined
        text: comment,
        createdAt: new Date().toISOString(),
        replies: []
    };

    console.log("Submitting comment:", newComment); // Debug log

    await updateDoc(thoughtRef, {
        comments: arrayUnion(newComment)
    });

    setComment("");
    setCommentWarning(false); // Reset warning
    const updatedThought = await getDoc(thoughtRef);
    setCurrentThought(updatedThought.data());
};

// Handle reply submission
const handleReplySubmit = async (parentComment) => {
    if (reply.trim() === "" || !user) return;

    const thoughtRef = doc(firestore, `activity`, thought.uid);
    const newReply = {
        username: user.username || 'Unknown', // Default to 'Unknown' if undefined
        text: reply,
        createdAt: new Date().toISOString(),
    };

    // Ensure that replies are being constructed correctly
    console.log("Submitting reply:", newReply); // Debug log

    const updatedComments = currentThought.comments.map(c => {
        if (c.createdAt === parentComment.createdAt) {
            return {
                ...c,
                replies: [...c.replies, newReply] // Use the newReply object
            };
        }
        return c;
    });

    await updateDoc(thoughtRef, { comments: updatedComments });
    setReply("");
    setReplyWarning(false); // Reset warning
    const updatedThought = await getDoc(thoughtRef);
    setCurrentThought(updatedThought.data());
    setVisibleReplies(prev => new Map(prev).set(parentComment.createdAt, true));
};


    const handleDeleteThought = async () => {
        await onDeleteThought(currentThought.uid); // Call the delete function
        setShowDeletePopup(false); // Close the popup after deletion
    };

    // Toggle visibility of replies for a comment
    const toggleReplies = (comment) => {
        setVisibleReplies(prev => new Map(prev).set(comment.createdAt, !prev.get(comment.createdAt)));
    };

    // Toggle visibility of comments
    const toggleCommentsVisibility = () => {
        setCommentsVisible(!commentsVisible);
    };

    const onDeleteComment = async (commentToDelete) => {
        const thoughtRef = doc(firestore, `activity`, thought.uid);
        const updatedComments = currentThought.comments.filter(c => c.createdAt !== commentToDelete.createdAt);
    
        await updateDoc(thoughtRef, { comments: updatedComments });
        const updatedThought = await getDoc(thoughtRef);
        setCurrentThought(updatedThought.data());
    };
    
    const onDeleteReply = async (parentComment, replyToDelete) => {
        const thoughtRef = doc(firestore, `activity`, thought.uid);
        const updatedComments = currentThought.comments.map(c => {
            if (c.createdAt === parentComment.createdAt) {
                return {
                    ...c,
                    replies: c.replies.filter(r => r.createdAt !== replyToDelete.createdAt)
                };
            }
            return c;
        });
    
        await updateDoc(thoughtRef, { comments: updatedComments });
        const updatedThought = await getDoc(thoughtRef);
        setCurrentThought(updatedThought.data());
    };
    

    return (
        <div className="thought-box">
            <h5>
                {currentThought.username === user.username ? <b id="thought-name">You </b> : <Link to={`/${currentThought.username}`} id="thought-name" className="link">~{currentThought.username} </Link>}
                {currentThought.action}:
            </h5>
            <p className="thought-text">{currentThought.text}</p>
            <p className='activity-date'>{formatDate(currentThought.time)}</p>
            
            <div className='thought-buttons'>
                <div>
                {user && (
                    <button className='thought-like-button' onClick={handleLike}>
                        <FontAwesomeIcon
                            icon={currentThought.likes && currentThought.likes.includes(user.uid) ? faThumbsUpFilled : faThumbsUpOutline}
                            className='like-icon'
                        />
                        {currentThought.likes ? currentThought.likes.length : 0}
                    </button>
                )}

                    <button 
                        className={`toggle-comments ${commentsVisible ? 'active' : ''}`} 
                        onClick={toggleCommentsVisibility}
                    >
                        <>
                            {currentThought.comments && currentThought.comments.length} <FontAwesomeIcon icon={faComment} />{" "}
                            {currentThought.comments && currentThought.comments.reduce((totalReplies, c) => totalReplies + c.replies.length, 0) > 0 && (
                                <>
                                    {currentThought.comments.reduce((totalReplies, c) => totalReplies + c.replies.length, 0)}
                                    <FontAwesomeIcon icon={faComments} />
                                </>
                            )}
                        </>
                    </button>
                </div>
                
                <div>
                {/* Delete Thought Button */}
                {user && thought.username === user.username && (
                    <>
                        <button className='thought-delete-button' onClick={() => setShowDeletePopup(true)}><FontAwesomeIcon icon={faTrashAlt}/></button>
                        {showDeletePopup && (
                            <div className='delete-popup'>
                                <div className='delete-popup-content'>
                                    <p>Are you sure you want to delete this thought?</p>
                                    <button className='confirm-button' onClick={handleDeleteThought}>Confirm Delete</button>
                                    <button className='cancel-button' onClick={() => setShowDeletePopup(false)}>Cancel</button>
                                </div>
                            </div>
                        )}
                    </>
                )}
                </div>
            </div>

            {user && commentsVisible && (
                <>
                    <input
                        type='text'
                        value={comment}
                        onChange={(e) => {
                            setComment(e.target.value);
                            if (e.target.value.length > 2500) {
                                setCommentWarning(true);
                            } else {
                                setCommentWarning(false);
                            }
                        }}
                        placeholder='Add a comment...'
                        className='story-comment-input'
                        maxLength={3000}
                    />
                    <button className='story-comment-submit' onClick={handleCommentSubmit} disabled={commentWarning}>Submit</button>
                    {commentWarning && <p className='warning'>Comment cannot exceed 300 characters.</p>}
                </>
            )}
            {commentsVisible && (
                <div className='story-comments'>
                    {currentThought.comments && currentThought.comments.map((comment, idx) => (
                        <div key={idx} className='story-comment'>
                            <p>
                                <Link to={`/${comment.username}`} id='commenter-name'><strong>~{comment.username}</strong></Link>:
                                <span> {comment.text}</span>
                            </p>
                            <p className='activity-date'>{formatDate(comment.createdAt)}</p>

                            <div className='thought-buttons'>
                                <div className='comment-toggle'>
                                <button 
                                    className={`toggle-comments ${visibleReplies.get(comment.createdAt) ? 'active' : ''}`} 
                                    onClick={() => toggleReplies(comment)}
                                >
                                    {comment.replies.length} <FontAwesomeIcon icon={faComment} />
                                </button>
                                </div>
                                <div>
                                    {user && comment.username === user.username && (
                                        <button className='thought-delete-button' onClick={() => onDeleteComment(comment)}><FontAwesomeIcon icon={faTrashAlt}/></button>
                                    )}
                                </div>
                            </div>

                            {visibleReplies.get(comment.createdAt) && comment.replies.map((reply, replyIdx) => (
                                <div key={replyIdx} className='story-reply'>
                                    <p><Link to={`/${reply.username}`} id='commenter-name'><strong>~{reply.username}</strong></Link>: <span> {reply.text}</span></p>
                                    <p className='activity-date'>{formatDate(reply.createdAt)}</p>

                                    {user && reply.username === user.username && (
                                        <button className='thought-delete-button' onClick={() => onDeleteReply(comment, reply)}><FontAwesomeIcon icon={faTrashAlt}/></button>
                                    )}
                                </div>
                            ))}

                            {visibleReplies.get(comment.createdAt) && user  && (
                                <div className='reply-section'>
                                    <input
                                        type='text'
                                        value={reply}
                                        onChange={(e) => {
                                            setReply(e.target.value);
                                            if (e.target.value.length > 2500) {
                                                setReplyWarning(true);
                                            } else {
                                                setReplyWarning(false);
                                            }
                                        }}
                                        placeholder='Add a reply...'
                                        className='reply-input'
                                        maxLength={3000}
                                    />
                                    <button className='reply-submit' onClick={() => handleReplySubmit(comment)} disabled={replyWarning}>Submit</button>
                                    {replyWarning && <p className='warning'>Reply cannot exceed 300 words.</p>}
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};
