import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBook, faComment, faHeart, faPencil, faStar, faUser } from '@fortawesome/free-solid-svg-icons';
import { faThumbsUp as faThumbsUpFilled } from '@fortawesome/free-solid-svg-icons';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import './userStats.css';
import { faComments } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from "react-redux";

const UserStats = ({ username }) => {
    const { stories, activity, following } = useSelector(state => state.app);

    // Initialize stats
    const writerStats = {
        stories: 0,
        likes: 0,
        totalRatings: 0,
        totalRatingValue: 0,
        avgRating: 0,
        comments: 0,
        replies: 0,
        views: 0,
        favorites: 0,
        followers: 0,
    };

    const readerStats = {
        likes: 0,
        totalRatings: 0,
        totalRatingValue: 0,
        avgRating: 0,
        comments: 0,
        replies: 0,
        favorites: 0,
        following: 0,
        forum: 0,
    };

    // Calculate writer stats
    const userStories = stories.filter(story => story.user === username);
    writerStats.stories = userStories.length;

    userStories.forEach(story => {
        writerStats.likes += story.likes ? story.likes.length : 0;
        writerStats.comments += story.comments ? story.comments.length : 0;
        const storyRatings = story.ratings || [];
        writerStats.totalRatings += storyRatings.length;
        
        // Calculate total rating value for the current story
        const totalRatingValue = storyRatings.reduce((sum, rating) => sum + (rating.rating || 0), 0);
        writerStats.totalRatingValue += totalRatingValue; // Sum of all rating values
        writerStats.views += story.views || 0;
        writerStats.favorites += story.favorites ? story.favorites.length : 0;

        // Calculate replies
        if (story.comments) {
            story.comments.forEach(comment => {
                writerStats.replies += comment.replies ? comment.replies.length : 0;
            });
        }
    });

    // Calculate average rating for the writer
    writerStats.avgRating = writerStats.totalRatings > 0 ? (writerStats.totalRatingValue / writerStats.totalRatings).toFixed(2) : 0;

    // Calculate reader stats
    activity.forEach(act => {
        if (act.user === username) {
            switch (act.action) {
                case 'liked':
                    readerStats.likes++;
                    break;
                case 'commented on':
                    readerStats.comments++;
                    break;
                case 'replied to a comment on':
                    readerStats.replies++;
                    break;
                case 'rated':
                    readerStats.totalRatings++;
                    readerStats.totalRatingValue += act.rating || 0; // Sum of all rating values
                    break;
                case 'favorited':
                    readerStats.favorites++;
                    break;
                case 'forum':
                    readerStats.forum++;
                    break;
                default:
                    break;
            }
        }
    });

    // Calculate average rating for the reader
    readerStats.avgRating = readerStats.totalRatings > 0 ? (readerStats.totalRatingValue / readerStats.totalRatings).toFixed(2) : 0;

    // Calculate following stats
    const userFollowing = following.filter(f => f.follower === username);
    readerStats.following = userFollowing.length;

    // Calculate followers count
    writerStats.followers = following.filter(f => f.followed === username).length;

    return (
        <div className="user-stats">
            <div className='story-interaction'>
                <span title="Writer Stats"><FontAwesomeIcon id='pencil' icon={faPencil} />{writerStats.stories}</span>
                <span title="Followers"><FontAwesomeIcon id='follower' icon={faUser} />{writerStats.followers}</span>
                <span title="Likes"><FontAwesomeIcon id='thumb' icon={faThumbsUpFilled} />{writerStats.likes}</span>
                <span title="Ratings"><FontAwesomeIcon id='star' icon={faStar} />{writerStats.totalRatings ? `${writerStats.avgRating} (${writerStats.totalRatings})` : 0}</span>
                <span title="Comments"><FontAwesomeIcon id='comment' icon={faComment} />{writerStats.comments + writerStats.replies}</span>
                <span title="Favorites"><FontAwesomeIcon id='heart' icon={faHeart} />{writerStats.favorites}</span>
                <span title="Views"><FontAwesomeIcon id='eye' icon={faEye} />{writerStats.views}</span>
            </div>
            <div className='story-interaction'>
                <span title="Reader Stats"><FontAwesomeIcon id='book' icon={faBook} /></span>
                <span title="Following"><FontAwesomeIcon id='follower' icon={faUser} />{readerStats.following}</span>
                <span title="Likes"><FontAwesomeIcon id='thumb' icon={faThumbsUpFilled} />{readerStats.likes}</span>
                <span title="Ratings"><FontAwesomeIcon id='star' icon={faStar} />{readerStats.totalRatings ? `${readerStats.avgRating} (${readerStats.totalRatings})` : 0}</span>
                <span title="Comments"><FontAwesomeIcon id='comment' icon={faComment} />{readerStats.comments + readerStats.replies}</span>
                <span title="Favorites"><FontAwesomeIcon id='heart' icon={faHeart} />{readerStats.favorites}</span>
                <span title="Forum Activity"><FontAwesomeIcon id='forum' icon={faComments} />{readerStats.forum}</span>
            </div>
        </div>
    );
};

export default UserStats;
