import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPrompt } from "../../prompts/writePrompt/writePromptSlice";
import { setUser, setUsers } from "../../../app/appSlice"; // Update the global state
import { useNavigate, useParams } from "react-router-dom";
import { doc, updateDoc } from "firebase/firestore"; // Import updateDoc from Firestore
import { firestore } from "../../../firebase/firebase"; // Import Firestore instance
import './savedPrompts.css';
import JsonToReact from "../../../utilities/jsonToReact";

export const SavedPrompts = () => {
    const { users } = useSelector(state => state.app); // Get users from Redux
    const { username } = useParams(); // Get the username from the URL
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // Find the user by username from the users array
    const user = users.find(u => u.username === username);

    // State for locally managing the saved prompts
    const [localPrompts, setLocalPrompts] = useState(user ? user.savedPrompts : []);

    useEffect(() => {
        if (user && user.savedPrompts) {
            // Sort prompts by `createdAt` in descending order (most recent first)
            const sortedPrompts = [...user.savedPrompts].sort((a, b) =>
                new Date(b.createdAt) - new Date(a.createdAt)
            );
            setLocalPrompts(sortedPrompts);
        }
    }, [user]);

    const handleWrite = (prompt) => {
        // Dispatch the selected prompt to the writing prompt state
        dispatch(setPrompt({ type: prompt.type, prompt: prompt.prompt}));
        navigate('/writePrompt'); // Navigate to the write prompt page
    };

    const handleRemove = async (promptId) => {
        try {
            // Filter out the prompt by ID
            const updatedPrompts = localPrompts.filter(prompt => prompt.createdAt !== promptId);

            // Update the user's savedPrompts in Redux
            const updatedUser = {
                ...user,
                savedPrompts: updatedPrompts,
            };

            // Update users array with the updated user
            const updatedUsers = users.map(u => u.username === user.username ? updatedUser : u);

            // Dispatch actions to update profile and users in the global state
            dispatch(setUser(updatedUser));
            dispatch(setUsers(updatedUsers));

            // Update the user's document in Firestore
            const userDocRef = doc(firestore, `users/${user.username}`);
            await updateDoc(userDocRef, {
                savedPrompts: updatedPrompts,
            });

            // Update local state with the modified prompts
            setLocalPrompts(updatedPrompts);
            console.log("Prompt removed and Firestore updated successfully.");
        } catch (error) {
            console.error("Error removing prompt: ", error);
        }
    };

    if (!user) {
        return <p>User not found.</p>;
    }
    return (
        <div className="saved-prompts">
            {!localPrompts ? (
                <p>No saved prompts.</p>
            ) : (
                localPrompts.map((prompt) => (
                    <div className='prompt-box' key={prompt.createdAt}>
                        <div className="saved-prompt">
                        {JsonToReact({jsonString: prompt.prompt})}
                            <br />
                            <button
                                id='button2'
                                type="button"
                                className="write-button"
                                onClick={() => handleWrite(prompt)}
                            >
                                Write It
                            </button>
                        </div>

                        <p
                            id='button2'
                            className="remove-button"
                            onClick={() => handleRemove(prompt.createdAt)}
                        >
                            Remove
                        </p>
                    </div>
                ))
            )}
        </div>
    );
};
