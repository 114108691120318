import React, { useState, useEffect } from 'react';
import { updatePassword, deleteUser } from 'firebase/auth';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { doc, updateDoc, getDoc, query, collection, where, getDocs } from 'firebase/firestore';
import { firestore } from '../../firebase/firebase';
import { useAuth } from '../../contexts/authContext/authContext';
import { Navigate, useNavigate } from 'react-router-dom';
import './account.css'; // Import the CSS file
import { useSelector } from 'react-redux';

export const Account = () => {
  const { currentUser, setCurrentUser } = useAuth();
  const {user} = useSelector(state=>state.app)
  const [username, setUsername] = useState('');
  const [bio, setBio] = useState('');
  const [penName, setPenName] = useState('');
  const [location, setLocation] = useState('');
  const [profileImage, setProfileImage] = useState(null);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [twitter, setTwitter] = useState('');
  const [facebook, setFacebook] = useState('');
  const [instagram, setInstagram] = useState('');
  const [patreon, setPatreon] = useState('');
  const [reddit, setReddit] = useState('');
  const [tumblr, setTumblr] = useState('');
  const [threads, setThreads] = useState('');
  const [web, setWeb] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isUpdating, setIsUpdating] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  // New state for toggling visibility
  const [showSocialMedia, setShowSocialMedia] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const [bioError, setBioError] = useState('');
const navigate = useNavigate()

  // Fetch user data on component mount
  useEffect(() => {
    !currentUser && navigate('/login');

    window.scrollTo(0,0)
    const fetchUserData = async () => {
      try {
        if (currentUser) {
          setUsername(currentUser.displayName || user.username);          
          const userRef = doc(firestore, `users/${user.username}`);
          const docSnap = await getDoc(userRef);
          if (docSnap.exists()) {
            const userData = docSnap.data();
            setBio(userData.bio || '');
            setPenName(userData.penName || '');
            setLocation(userData.location || '');
            setTwitter(userData.twitter || '');
            setFacebook(userData.facebook || '');
            setInstagram(userData.instagram || '');
            setPatreon(userData.patreon || '');
            setReddit(userData.reddit || '');
            setTumblr(userData.tumblr || '');
            setThreads(userData.threads || '');
            setWeb(userData.web || '');
          }
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserData();
  }, [currentUser,user?.username, navigate]);
   

  // Validate bio length (limit by words)
  useEffect(() => {
    const wordCount = bio.trim().split(/\s+/).length;
    if (wordCount > 500) {
      setBioError('Bio cannot exceed 500 words.');
    } else {
      setBioError('');
    }
  }, [bio]);

  const updateStoriesWithNewPenName = async (oldPenName, newPenName) => {
    try {
      // Query to fetch all stories by the user with the old pen name
      const q = query(collection(firestore, 'stories'), where('user', '==', username));
      
      // Get all stories by this author
      const querySnapshot = await getDocs(q);
  
      // Update each story with the new pen name
      querySnapshot.forEach(async (storyDoc) => {
        const storyRef = doc(firestore, `stories/${storyDoc.id}`);
        await updateDoc(storyRef, {
          author: newPenName,
        });
      });
  
      console.log(`Stories by ${oldPenName} have been updated to ${newPenName}`);
    } catch (error) {
      console.error('Error updating stories with new pen name:', error);
    }
  };

  const handleProfileUpdate = async (e) => {
    e.preventDefault();

    if (bioError) {
      setErrorMessage('Please fix the errors before submitting.');
      return;
    }

    if (newPassword && newPassword !== confirmPassword) {
      setErrorMessage('Passwords do not match.');
      return;
    }

    if (profileImage) {
      const fileSizeMB = profileImage.size / 1024 / 1024; // Convert bytes to MB
      if (fileSizeMB > 1) { // Limit size to 1MB
        setErrorMessage('Profile image size must be less than 1MB.');
        return;
      }
    }

    setIsUpdating(true);
    try {
      const updates = {};

      if (profileImage) {
        const storage = getStorage();
        const storageRef = ref(storage, `profileImages/${user.username}/${profileImage.name}`);
        await uploadBytes(storageRef, profileImage);
        const profileImageUrl = await getDownloadURL(storageRef);
        updates.photoURL = profileImageUrl;
      }

      const userRef = doc(firestore, `users/${user.username}`);
      const firestoreUpdates = {
        bio,
        penName,
        location,
        twitter,
        facebook,
        instagram,
        patreon,
        reddit,
        tumblr,
        threads,
        web,
      };
      if (profileImage) firestoreUpdates.profileImageUrl = updates.photoURL;

      if (Object.keys(firestoreUpdates).length > 0) {
        await updateDoc(userRef, firestoreUpdates);
      }

      if (newPassword) {
        await updatePassword(currentUser, newPassword);
      }

      setSuccessMessage('Profile updated successfully!');
      setErrorMessage('');
    } catch (error) {
      console.error('Error updating profile:', error);
      setErrorMessage(error.message);
      setSuccessMessage('');
    } finally {
      setIsUpdating(false);
    }

    try {
      const oldPenName = user.penName;
      
      // If the pen name has changed, update all stories authored by the user
      if (penName !== oldPenName) {
        await updateStoriesWithNewPenName(oldPenName, penName);
      }
  
      // Your existing profile update logic...
      setSuccessMessage('Profile updated successfully!');
    } catch (error) {
      console.error('Error updating profile:', error);
      setErrorMessage(error.message);
    }

  };

  const handleAccountDeletion = async () => {
    try {
      await deleteUser(currentUser);
      setCurrentUser(null);
      return <Navigate to="/" replace />;
    } catch (error) {
      console.error('Error deleting account:', error);
      setErrorMessage(error.message);
    }
  };

  if (!currentUser) {
    return <Navigate to="/login" replace />;
  }
  

  return (
    <div className='page'>
      <div className="account-form-container">
        <div className="account-header">
          <header>
            <h1>Update Profile</h1>
          </header>
        </div>
        <form onSubmit={handleProfileUpdate} className="account-form">
          <div className="account-field">
            <label>Username</label>
            <input
              disabled={true}
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div className="account-field">
            <label>Pen Name</label>
            <input
              type="text"
              value={penName}
              onChange={(e) => setPenName(e.target.value)}
              maxLength={30}
            />
          </div>
          <div className="account-field">
            <label>Location</label>
            <input
              type="text"
              value={location}
              onChange={(e) => setLocation(e.target.value)}
            />
          </div>
          <div className="account-field">
            <label>Bio</label>
            <textarea
              value={bio}
              onChange={(e) => setBio(e.target.value)}
            />
                        {bioError && <span className="error-message">{bioError}</span>}

          </div>
          <div className="account-field">
            <label>Profile Image</label>
            <input
              type="file"
              accept="image/*"
              onChange={(e) => setProfileImage(e.target.files[0])}
            />
          </div>

          {/* Toggle for social media links */}
          <div className="toggle-section" onClick={() => setShowSocialMedia(!showSocialMedia)}>
            <h3>Social Media Links</h3>
            <span>{showSocialMedia ? '▲' : '▼'}</span>
          </div>
          {showSocialMedia && (
            <div className="social-media-fields">
              <div className="account-field">
                <label>Twitter</label>
                <input
                  type="text"
                  value={twitter}
                  onChange={(e) => setTwitter(e.target.value)}
                />
              </div>
              <div className="account-field">
                <label>Facebook</label>
                <input
                  type="text"
                  value={facebook}
                  onChange={(e) => setFacebook(e.target.value)}
                />
              </div>
              <div className="account-field">
                <label>Instagram</label>
                <input
                  type="text"
                  value={instagram}
                  onChange={(e) => setInstagram(e.target.value)}
                />
              </div>
              <div className="account-field">
                <label>Patreon</label>
                <input
                  type="text"
                  value={patreon}
                  onChange={(e) => setPatreon(e.target.value)}
                />
              </div>
              <div className="account-field">
                <label>Reddit</label>
                <input
                  type="text"
                  value={reddit}
                  onChange={(e) => setReddit(e.target.value)}
                />
              </div>
              <div className="account-field">
                <label>Tumblr</label>
                <input
                  type="text"
                  value={tumblr}
                  onChange={(e) => setTumblr(e.target.value)}
                />
              </div>
              <div className="account-field">
                <label>Threads</label>
                <input
                  type="text"
                  value={threads}
                  onChange={(e) => setThreads(e.target.value)}
                />
              </div>
              <div className="account-field">
                <label>Personal Website</label>
                <input
                  type="text"
                  value={web}
                  onChange={(e) => setWeb(e.target.value)}
                />
              </div>
            </div>
          )}

          {/* Toggle for password change */}
          <div className="toggle-section" onClick={() => setShowPassword(!showPassword)}>
            <h3>Change Password</h3>
            <span>{showPassword ? '▲' : '▼'}</span>
          </div>
          {showPassword && (
            <>
              <div className="account-field">
                <label>New Password</label>
                <input
                  type="password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
              </div>
              {newPassword && (
                <div className="account-field">
                  <label>Confirm New Password</label>
                  <input
                    type="password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </div>
              )}
            </>
          )}

          {errorMessage && <span className='error-message'>{errorMessage}</span>}
          {successMessage && <span className='success-message'>{successMessage}</span>}
          <button className='submit-button' type="submit" disabled={isUpdating}>
            {isUpdating ? 'Updating...' : 'Update Profile'}
          </button>
        </form>
        <button onClick={() => setShowConfirmation(true)} className="delete-account-button">
          Delete Account
        </button>
      </div>

      {showConfirmation && (
        <div className="confirmation-popup">
          <div className="confirmation-popup-content">
            <h2>Confirm Account Deletion</h2>
            <p>Are you sure you want to delete your account? This action is irreversible.</p>
            <button onClick={handleAccountDeletion} className="confirm-delete-button">Yes, Delete</button>
            <button onClick={() => setShowConfirmation(false)} className="cancel-button">Cancel</button>
          </div>
        </div>
      )}
    </div>
  );
};
