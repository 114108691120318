import { getAuth } from 'firebase/auth';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from 'firebase/firestore';
import { getPerformance } from "firebase/performance";


const firebaseConfig = {
    apiKey: "AIzaSyArhu_GojHxgmv5bTyAy8W-RWkKqjo4lAQ",
    authDomain: "writician-64638.firebaseapp.com",
    projectId: "writician-64638",
    storageBucket: "writician-64638.appspot.com",
    messagingSenderId: "22745137839",
    appId: "1:22745137839:web:7e74f0cd795de69adb8a5a",
    measurementId: "G-6J22JPBTXC"
  };

const app = initializeApp(firebaseConfig)
const analytics = getAnalytics(app);  
const auth = getAuth(app);
const db = getFirestore(app);
const firestore = getFirestore();
const perf = getPerformance(app);



export {app, auth,db,analytics, firestore, perf};