import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { formatStoryText } from "../../utilities/formatStoryText";
import './judging.css';
import { setStories } from "../../app/appSlice";
import { firestore } from "../../firebase/firebase";
import { doc, updateDoc } from "firebase/firestore";

export const Judging = () => {
    const [selectedContest, setSelectedContest] = useState("");
    const [unjudgedStories, setUnjudgedStories] = useState([]);
    const [selectedStory, setSelectedStory] = useState(null);
    const [score, setScore] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const { contests, stories } = useSelector((state) => state.app);

    // Load unjudged stories when a contest is selected
    useEffect(() => {
        if (!selectedContest) return;
    
        setIsLoading(true);
    
        try {
            const contest = contests.find(c => c.title === selectedContest);
            if (!contest) return;

            const judgedTitles = contest.judged ? contest.judged.map(j => j.title) : [];

            const unjudgedList = stories.filter(story => {
                const isWithinDateRange =
                    new Date(story.time) >= new Date(contest.startDate) &&
                    new Date(story.time) <= new Date(contest.endDate);
            
                const isUnjudgedAndRelevant = 
                    !judgedTitles.includes(story.title) &&
                    !story.judged;
            
                const matchesPromptCriteria = 
                    (contest.prompt !== "" && story.contest === contest.title) || // Original logic: prompt is not blank
                    (contest.prompt === "" && contest.promptType === "all") ||    // Open to all stories
                    (contest.prompt === "" && story.promptType === contest.promptType); // Matches specific prompt type
            
                return isWithinDateRange &&
                    isUnjudgedAndRelevant &&
                    matchesPromptCriteria; // Final check for prompt matching
            });
            
    
            setUnjudgedStories(unjudgedList);
        } catch (error) {
            console.error("Error processing stories:", error);
        } finally {
            setIsLoading(false);
        }
    }, [selectedContest, contests, stories]);

    const handleStoryClick = (story) => {
        setSelectedStory(story);
    };

    const handleSubmitScore = async () => {
        if (!selectedStory || !score) return;

        try {
            // Calculate influence and final score
            const views = selectedStory.views || 0;
            const likes = (selectedStory.likes ? selectedStory.likes.length : 0) * 10;
            const comments = (selectedStory.comments ? selectedStory.comments.length : 0) * 50;
            const replies = (selectedStory.comments ? selectedStory.comments.reduce((total, comment) => total + (comment.replies ? comment.replies.length : 0), 0) : 0) * 50;
            const ratings = (selectedStory.ratings ? selectedStory.ratings.length * (selectedStory.ratings.reduce((sum, r) => sum + r.rating, 0) / selectedStory.ratings.length) * 20 : 0);

            const influence = views + likes + comments + replies + ratings;
            const scoreValue = parseFloat(score);
            const maxInfluence = Math.max(...stories.map(s => s.influence || 0), influence);
            const finalScore = Math.min(scoreValue * 0.9 + (maxInfluence ? influence / maxInfluence : 0) * 1, 10);

            // Update Firestore by story ID
            const storyRef = doc(firestore, 'stories', selectedStory.id);
            await updateDoc(storyRef, {
                score: scoreValue,
                influence: influence,
                finalScore: finalScore,
                judged: true // Mark as judged
            });

            // Update the local story object
            const updatedStory = {
                ...selectedStory,
                score: scoreValue,
                influence: influence,
                finalScore: finalScore,
                judged: true
            };

            // Update Redux global state for stories
            const updatedStories = stories.map(story =>
                story.id === selectedStory.id ? updatedStory : story
            );
            dispatch(setStories(updatedStories));

            // Remove from unjudgedStories and clear selected story
            setUnjudgedStories(unjudgedStories.filter(story => story.id !== selectedStory.id));
            setSelectedStory(null);
            setScore("");
        } catch (error) {
            console.error("Error updating story:", error);
        }
    };

    return (
        <div className="judging-page">
            {isLoading && (
                <div className="loading-screen">
                    <div className="loading-overlay"></div>
                    <img src="/images/favlogotrans.png" alt="Loading..." className="loading-image" />
                </div>
            )}
            <header>
                <h1>Judging</h1>
            </header>
            <div className="judging-content">
                <div className="contest-dropdown">
                    <label htmlFor="contest-select">Select a Contest:</label>
                    <select
                        id="contest-select"
                        value={selectedContest}
                        onChange={(e) => setSelectedContest(e.target.value)}
                    >
                        <option value="">--Select a Contest--</option>
                        {contests.map((contest) => (
                            <option key={contest.id} value={contest.title}>
                                {contest.title}
                            </option>
                        ))}
                    </select>
                </div>

                {selectedStory ? (
                    <div className="story-fullscreen">
                        <h2>{selectedStory.title}</h2>
                        <p style={{ textAlign: 'left' }} dangerouslySetInnerHTML={{ __html: formatStoryText(selectedStory.story) }}></p>
                        <div className="score-input">
                            <label htmlFor="score">Enter Score:</label>
                            <input
                                id="score"
                                type="number"
                                value={score}
                                onChange={(e) => setScore(e.target.value)}
                                min="0"
                                max="10"
                                step="0.1"
                                required
                            />
                            <button onClick={handleSubmitScore}>Submit Score</button>
                        </div>
                    </div>
                ) : (
                    <div className="stories-columns">
                        <div className="unjudged-stories">
                            <h2>Unjudged Stories</h2>
                            <ul>
                                {unjudgedStories.map((story) => (
                                    <li key={story.id} onClick={() => handleStoryClick(story)}>
                                        <strong>{story.title}</strong>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className="judged-stories">
                            <h2>Judged Stories</h2>
                            {selectedContest && stories.some(s => s.contest === selectedContest && s.judged) ? (
                                <ul>
                                    {stories
                                        .filter(story => story.contest === selectedContest && story.judged)
                                        .sort((a, b) => (b.finalScore || 0) - (a.finalScore || 0))
                                        .map((judged, index) => (
                                            <li key={index}>
                                                <i>{judged.title}</i> : <b>{judged.finalScore?.toFixed(1) || 'N/A'}</b>  
                                                ({judged.score?.toFixed(1) || 'N/A'} /
                                                {judged.influence?.toFixed(1) || 'N/A'})
                                            </li>
                                        ))}
                                </ul>
                            ) : (
                                <p>No judged stories yet.</p>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};
