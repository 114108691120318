import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { DisplayGenreIcon } from "../../../utilities/displayGenreIcon";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComment, faEye, faHeart, faStar, faThumbsUp as faThumbsUpFilled } from "@fortawesome/free-solid-svg-icons";
import { formatDate } from "../../../utilities/formatDate";

export const Favorites = () => {
    const navigate = useNavigate();
    const { stories, users } = useSelector((state) => state.app);
    const { username } = useParams();
    
    // State for favorite stories data
    const [favoriteStories, setFavoriteStories] = useState(null);

    useEffect(() => {
        // Ensure data is available before filtering
        if (stories && users) {
            const user = users.find((u) => u.username === username);
            if (user) {
                const favorites = user.favorites || [];
                const data = stories.filter((s) => favorites.includes(s.title));
                setFavoriteStories(data);
            }
        }
    }, [stories, users, username]);

    const handleStoryClick = (story) => {
        navigate(`/${story.user}/${story.uid}`);
    };

    // Handle loading state or when no favorite stories are found
    if (!favoriteStories) {
        return <div className="stories-page"><p>Loading favorites...</p></div>;
    }

    if (favoriteStories.length === 0) {
        return (
            <div className="stories-page">
                <p>No favorites yet.</p>
            </div>
        );
    }

    return (
        <div className='favorites-page'>
            <h3>{favoriteStories.length} Stories</h3>
            <div className={favoriteStories.length > 0 ? 'prof-stories' : ''}>
                {favoriteStories.map((story, index) => (
                    <div key={index} className='prof-story' onClick={() => handleStoryClick(story)}>
                        <div className='prof-story-details'>
                            <div className="title-author-genres">
                                <strong className='storyTitle'>{story.title}</strong><strong>{story.author}</strong>
                                <DisplayGenreIcon genres={story.genres} style={{ width: '30px', borderRadius: '25px', marginLeft: '.5rem' }}/>
                            </div>
                        </div>
                        <div className='story-interaction'>
                            <span><FontAwesomeIcon id='thumb' icon={faThumbsUpFilled} /> {story.likes?.length || 0}</span>
                            {story.rateable && (
                                <span>
                                    <FontAwesomeIcon id='star' icon={faStar} /> 
                                    {story.ratings ? (story.ratings.reduce((sum, r) => sum + r.rating, 0) / story.ratings.length).toFixed(1) : 0}
                                    ({story.ratings?.length || 0})
                                </span>
                            )}
                            <span><FontAwesomeIcon id='comment' icon={faComment} /> {story.comments?.length || 0}</span>
                            <span><FontAwesomeIcon id='heart' icon={faHeart} /> {story.favorites?.length || 0}</span>
                            <span><FontAwesomeIcon id='eye' icon={faEye} /> {story.views}</span>
                            <span>{formatDate(story.time)}</span>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};
