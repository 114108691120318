import React from "react";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp as faThumbsUpFilled, faStar, faComment, faHeart, faEye } from '@fortawesome/free-solid-svg-icons';
import { DisplayGenreIcon } from "../../utilities/displayGenreIcon";
import JsonToReact from "../../utilities/jsonToReact";
import { formatDate } from "../../utilities/formatDate";
import './featuredPotd.css'
import { useNavigate } from "react-router-dom";

export const FeaturedPotd = () => {
    const { stories } = useSelector(state => state.app);
    const navigate = useNavigate();
    // Find all featured stories with potd = true and sort them by time to get the most recent
    const featuredStories = stories.filter(s => s.potd);
    const todaysFeature = featuredStories.sort((a, b) => new Date(b.time) - new Date(a.time))[0]; // Get the most recent story

    if (!todaysFeature) {
        return <div>No featured story for today.</div>; // Handle case when no featured story exists
    }

    return (
        <div key={todaysFeature.id} className='featured-story' onClick={() => navigate(`/${todaysFeature.user}/${todaysFeature.id}`)}>
            <div className='discover-story-details'>
                <div className="">
                    <strong className='storyTitle'>{todaysFeature.title}</strong>
                    <br/>
                    <strong>{todaysFeature.author}</strong>
                    <br/>
                    <br/>
                    <div>
                        <DisplayGenreIcon genres={todaysFeature.genres} style={{ width: '30px', borderRadius: '25px', marginLeft: '.5rem' }} />
                    </div>
                </div>
                <div className="potd-prompt-home">{JsonToReact({ jsonString: todaysFeature.prompt })}</div>

                <p className="discover-snippet"><i>{todaysFeature.story.substring(0, 240)}...</i></p>
                <div className='story-interaction'>
                                        <span><FontAwesomeIcon id='thumb' icon={faThumbsUpFilled} /> {todaysFeature.likes ? todaysFeature.likes.length : 0}</span>
                                        {todaysFeature.rateable && (
                                            <span>
                                                <FontAwesomeIcon id='star' icon={faStar} /> 
                                                {todaysFeature.ratings 
                                                    ? (todaysFeature.ratings.reduce((sum, r) => sum + r.rating, 0) / todaysFeature.ratings.length).toFixed(1) 
                                                    : 0} ({todaysFeature.ratings ? todaysFeature.ratings.length : 0})
                                            </span>
                                        )}
                                        <span><FontAwesomeIcon id='comment' icon={faComment} /> {todaysFeature.comments ? todaysFeature.comments.length : 0}</span>
                                        <span><FontAwesomeIcon id='heart' icon={faHeart} /> {todaysFeature.favorites ? todaysFeature.favorites.length : 0}</span>
                                        <span><FontAwesomeIcon id='eye' icon={faEye} /> {todaysFeature.views}</span>
                                        <span>{formatDate(todaysFeature.time)}</span>
                                    </div>
            </div>
            
        </div>
    );
};
