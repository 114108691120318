import React from "react";
import { Link } from "react-router-dom";

export const WriteHome=() => {
    
    window.scrollTo(0,0)

    return(
        <div className='step'>
            <img id='write-img' src='/images/assortedpics/typewriter1.png' alt='old-timey typewriter' loading="lazy"/>
            <p className='step-p'>So, you’ve got an idea for a story. That’s great, but what do you do with it? 
            Maybe you’ve written a nice start but can’t figure out where to go from there.</p>
            <p className='step-p'>Or perhaps you’ve run into a wall with your protagonist. They’re boring. 
            They’re too perfect. There’s no conflict. Maybe you’ve got a brilliant ending in mind, but don’t know 
            how to get there.</p> 
            <p className='step-p'>This walk-through is here to help.</p>
            <p className='step-p'>Keep in mind, a story can be many things, and every writer might have a different
             definition. All formulas are meant only as guideline; your story should grow organically around a framework, 
             rather than strictly adhering to plot points determined by someone else to be exactly 62% through your 
             manuscript. </p>
             <p className='step-p'>But starting with a formula, with a tried and true method, is the quickest way to get the 
             ball rolling on transforming your own unique idea into a well written, absorbing, and authentic story.</p>
            <h4>First things first,</h4>
            <p className='step-p'>A story shows the change in a character as they grapple with conflicts. 
            Good stories are centered around unique, layered, and flawed characters who in turn suffer because of 
            their flaw. Circumstances beyond their control and the consequences of their own actions test the 
            character, until they confront their flaw, transcend it, and reach their goal.</p>
            <p className='step-p'><b>
                <li>A character has a goal and a flaw.</li>
                <li>In trying to achieve their goal, they run into conflicts that expose their flaw.</li>
                <li>They must change in order to overcome the conflicts and achieve their goal.</li></b>
            </p>
            <p className='step-p'>Not all stories are that simple. Some may have multiple focal characters. 
            Some may end tragically. Some stories are based on heroes who change the people around them instead 
            of themselves. But overall, the above elements will be present. Think character, conflict, and change.</p>
            <p className='step-p'>In this walk-through we will discuss a top-down approach to story writing. Whatever your idea, however big or small, it will surely benefit from taking it through this process if you’re willing to see it through to the other side.</p>
            <br/>
            <Link to='write-idea-step-1'><button id='button2'><span>Let's Begin</span></button></Link>
        </div>
    )
}