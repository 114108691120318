import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux'; // To access global state
import { debounce } from 'lodash';
import './search.css';

export const SearchBar = () => {
    const [queryText, setQueryText] = useState('');
    const [results, setResults] = useState([]);
    const [showDropdown, setShowDropdown] = useState(false);
    const searchRef = useRef(null);

    // Access data from global state using useSelector
    const { users, stories, forumQuestions, forumAnswers, forumComments } = useSelector(state => state.app);

    // Handle input changes and trigger debounced search
    const handleInputChange = (e) => {
        const searchTerm = e.target.value;
        setQueryText(searchTerm);

        // Inline debounce function
        debounce(() => {
            if (searchTerm.trim() === '') {
                setResults([]);
                setShowDropdown(false);
                return;
            }

            // Convert search term to lowercase for case-insensitive matching
            const lowerSearchTerm = searchTerm.toLowerCase();

            // Filter users by username
            const userResults = users.filter(user =>
                user.username.toLowerCase().includes(lowerSearchTerm)
            );

            // Filter stories by title or story content
            const storyResults = stories.filter(story =>
                story.title.toLowerCase().includes(lowerSearchTerm) ||
                story.story.toLowerCase().includes(lowerSearchTerm)
            );

            // Filter forum questions, answers, and comments
            const questionResults = forumQuestions.filter(question =>
                question.text.toLowerCase().includes(lowerSearchTerm)
            );

            const answerResults = forumAnswers.filter(answer =>
                answer.text.toLowerCase().includes(lowerSearchTerm)
            );

            const commentResults = forumComments.filter(comment =>
                comment.text.toLowerCase().includes(lowerSearchTerm)
            );

            // Combine all the results into one array
            const combinedResults = [
                ...userResults.map(user => ({ type: 'user', data: user })),
                ...storyResults.map(story => ({ type: 'story', data: story })),
                ...questionResults.map(question => ({ type: 'question', data: question })),
                ...answerResults.map(answer => ({ type: 'answer', data: answer })),
                ...commentResults.map(comment => ({ type: 'comment', data: comment }))
            ];

            // Update the results and show dropdown
            setResults(combinedResults);
            setShowDropdown(true);
        }, 300)(); // Immediately invoke the debounced function
    };

    // Handle outside click to close dropdown
    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (searchRef.current && !searchRef.current.contains(event.target)) {
                setShowDropdown(false);
            }
        };

        document.addEventListener('mousedown', handleOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

    // Render the dropdown content dynamically based on result type
    const renderResultItem = (result) => {
        switch (result.type) {
            case 'user':
                return (
                    <div key={result.data.id} className="search-dropdown-item">
                        <a href={`/${result.data.username}`} className="search-dropdown-link">
                            {result.data.username}
                        </a>
                    </div>
                );
            case 'story':
                return (
                    <div key={result.data.id} className="search-dropdown-item">
                        <a href={`/${result.data.user}/${result.data.id}`} className="search-dropdown-link">
                            {result.data.title}
                        </a>
                    </div>
                );
            case 'question':
                return (
                    <div key={result.data.id} className="search-dropdown-item">
                        <a href={`/forum#${result.data.id}`} className="search-dropdown-link">
                            Question: {result.data.text.substring(0, 50)}...
                        </a>
                    </div>
                );
            case 'answer':
                return (
                    <div key={result.data.id} className="search-dropdown-item">
                        <a href={`/forum#${result.data.questionId}`} className="search-dropdown-link">
                            Answer: {result.data.text.substring(0, 50)}...
                        </a>
                    </div>
                );
            case 'comment':
                return (
                    <div key={result.data.id} className="search-dropdown-item">
                        <a href={`/forum#${result.data.questionId}`} className="search-dropdown-link">
                            Comment: {result.data.text.substring(0, 50)}...
                        </a>
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <div className="search-container" ref={searchRef}>
            <input
                type="text"
                placeholder="Search..."
                value={queryText}
                onChange={handleInputChange}
                className="search-input"
                name="search"
            />
            {showDropdown && results.length > 0 && (
                <div className="search-dropdown">
                    {results.map(result => renderResultItem(result))}
                </div>
            )}
        </div>
    );
};
