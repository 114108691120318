const extractTextFromJson = (json) => {
    if (typeof json === 'string') {
        return json;
    }
    if (Array.isArray(json)) {
        return json.map(extractTextFromJson).join(' ');
    }
    if (json && typeof json === 'object') {
        if (json.type === 'span') {
            return ''; // Ignore <span> elements and their children
        }
        if (json.type === 'br') {
            return '/ '; // Replace <br> with a comma and a space
        }
        if (json.props && json.props.children) {
            return extractTextFromJson(json.props.children);
        }
        if (json.children) {
            return extractTextFromJson(json.children);
        }
        return Object.values(json).map(extractTextFromJson).join(' ');
    }
    return '';
};

const parseAndExtractText = (jsonString) => {
    try {
        const jsonObject = JSON.parse(jsonString);
        return extractTextFromJson(jsonObject);
    } catch (error) {
        console.error("Error parsing JSON string:", error);
        return "";
    }
};

export default parseAndExtractText