import React, { useState, useEffect } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComment, faEye, faHeart, faStar } from "@fortawesome/free-solid-svg-icons";
import { faThumbsUp as faThumbsUpFilled } from '@fortawesome/free-solid-svg-icons';
import './contest.css';
import { DisplayGenreIcon } from "../../../utilities/displayGenreIcon";
import JsonToReact from "../../../utilities/jsonToReact";
import { setPrompt } from "../../prompts/writePrompt/writePromptSlice";
import { useDispatch, useSelector } from "react-redux";
import { Pagination } from "@mui/material";
import { formatDate } from "../../../utilities/formatDate";

export const Contest = () => {
    
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 20; 
    const { contestId } = useParams();

    // Get stories and contests from the global state
    const { stories, user, contests } = useSelector(state => state.app);

    const contest = contests.find(c => c.id === contestId);
    const today = new Date().toISOString();
    
    // Filter stories based on contest criteria
    const filteredStories = stories.filter(story => {
        const wordCount = story.wordCount;
        const storyDate = new Date(story.time);

        return (
            story.contest === contest?.title &&
            wordCount >= contest.minWordCount &&
            wordCount <= contest.maxWordCount &&
            story.rateable === true &&
            storyDate >= new Date(contest.startDate) &&
            storyDate <= new Date(contest.endDate)
        );
    });

    // Find the winning story
    const winningStory = filteredStories.find(story => story.badge==='winner');

    // Sort the remaining stories by finalScore (highest to lowest)
    const sortedStories = filteredStories
        .filter(story => !story.badge) // Exclude the winning story from sorting
        .sort((a, b) => (b.finalScore || 0) - (a.finalScore || 0)); // Sort by finalScore

    // Calculate total pages for paginated stories (excluding the winner)
    const totalPages = Math.ceil(sortedStories.length / itemsPerPage);

    // Paginate the sorted stories
    const paginatedStories = sortedStories.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    const handleStoryClick = (story) => {
        navigate(`/${story.user}/${story.uid}`);
    };

    const handleWrite = ({ type, prompt, contest, contestId, maxWordCount, minWordCount }) => {
        dispatch(setPrompt({ type, prompt, contest, contestId, maxWordCount, minWordCount }));
    };

    const handleChangePage = (event, value) => {
        setCurrentPage(value);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [currentPage]);

    return (
        <div className="contest-page">
            {contest ? (
                <>
                    <header className="contest-header">
                        <h2 className="storyTitle">{contest.title}</h2>
                        <p className="contest-description">{contest.description}</p>
                        {contest.prompt && (
                            <div className="contest-prompt">
                                {JsonToReact({ jsonString: contest.prompt })}
                                <br />
                                {user && (today<contest.endDate) && (
                                    <NavLink to='/writePrompt'>
                                        <button
                                            id='button2'
                                            onClick={() => handleWrite({
                                                contest: contest.title,
                                                contestId: contest.id,
                                                type: contest.promptType,
                                                prompt: contest.prompt,
                                                maxWordCount: contest.maxWordCount,
                                                minWordCount: contest.minWordCount
                                            })}
                                        >
                                            Enter Contest
                                        </button>
                                    </NavLink>
                                )}
                            </div>
                        )}
                        <p>{contest.minWordCount} - {contest.maxWordCount} words</p>
                        <p className="date-range">Open from: {contest.startDate ? formatDate(contest.startDate) : 'N/A'} to {contest.endDate ? formatDate(contest.endDate) : 'N/A'}</p>
                        <p className="prize">Prize: {contest.prize}</p>
                    </header>
                    <br />

                    {/* Display Winning Story */}
                    {winningStory && (
                        <>
                            <h3>Winning Story</h3>
                            <div className='all-story' onClick={() => handleStoryClick(winningStory)}>
                                <div className='story-details'>
                                    <div className="title-author-genres">
                                        <strong className='storyTitle'>{winningStory.title}</strong>
                                        <strong>{winningStory.author}</strong>
                                        <DisplayGenreIcon genres={winningStory.genres} style={{ width: '30px', borderRadius: '25px', marginLeft: '.5rem' }} />
                                    </div>
                                    <p className="snippet"><i>{winningStory.story.substring(0, 500)}...</i></p>
                                </div>
                                <div className='story-interaction'>
                                    <span><FontAwesomeIcon id='thumb' icon={faThumbsUpFilled} /> {winningStory.likes ? winningStory.likes.length : 0}</span>
                                    {winningStory.rateable && (
                                        <span>
                                            <FontAwesomeIcon id='star' icon={faStar} /> 
                                            {winningStory.ratings 
                                                ? (winningStory.ratings.reduce((sum, r) => sum + r.rating, 0) / winningStory.ratings.length).toFixed(1) 
                                                : 0} ({winningStory.ratings ? winningStory.ratings.length : 0})
                                        </span>
                                    )}
                                    <span><FontAwesomeIcon id='comment' icon={faComment} /> {winningStory.comments ? winningStory.comments.length : 0}</span>
                                    <span><FontAwesomeIcon id='heart' icon={faHeart} /> {winningStory.favorites ? winningStory.favorites.length : 0}</span>
                                    <span><FontAwesomeIcon id='eye' icon={faEye} /> {winningStory.views}</span>
                                    <span>{formatDate(winningStory.time)}</span>
                                </div>
                            </div>
                            <br />
                        </>
                    )}

                    {/* Display remaining stories sorted by finalScore */}
                    {paginatedStories.length > 0 && <h3>Other Stories</h3>}
                    <div className="stories-list">
                        {paginatedStories.length > 0 ? (
                            paginatedStories.map((story, index) => (
                                <div key={index} className='all-story' onClick={() => handleStoryClick(story)}>
                                    <div className='story-details'>
                                        <div className="title-author-genres">
                                            <strong className='storyTitle'>{story.title}</strong>
                                            <strong>{story.author}</strong>
                                            <DisplayGenreIcon genres={story.genres} style={{ width: '30px', borderRadius: '25px', marginLeft: '.5rem' }} />
                                        </div>
                                        <p className="snippet"><i>{story.story.substring(0, 500)}...</i> </p>
                                    </div>
                                    <div className='story-interaction'>
                                        <span><FontAwesomeIcon id='thumb' icon={faThumbsUpFilled} /> {story.likes ? story.likes.length : 0}</span>
                                        {story.rateable && (
                                            <span>
                                                <FontAwesomeIcon id='star' icon={faStar} /> 
                                                {story.ratings 
                                                    ? (story.ratings.reduce((sum, r) => sum + r.rating, 0) / story.ratings.length).toFixed(1) 
                                                    : 0} ({story.ratings ? story.ratings.length : 0})
                                            </span>
                                        )}
                                        <span><FontAwesomeIcon id='comment' icon={faComment} /> {story.comments ? story.comments.length : 0}</span>
                                        <span><FontAwesomeIcon id='heart' icon={faHeart} /> {story.favorites ? story.favorites.length : 0}</span>
                                        <span><FontAwesomeIcon id='eye' icon={faEye} /> {story.views}</span>
                                        <span>{formatDate(story.time)}</span>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <p>No stories found for this contest.</p>
                        )}
                    </div>
                </>
            ) : (
                <p>Contest not found.</p>
            )}
            
            {/* Pagination for remaining stories */}
            {totalPages > 1 && (
                <Pagination
                    count={totalPages}
                    page={currentPage}
                    onChange={handleChangePage}
                    color="primary"
                    style={{ marginTop: "20px", display: "flex", justifyContent: "center" }}
                    className="pagination"
                />
            )}
        </div>
    );
};
