import React from "react";


export const Card = () => {
    return(
        <div className='plotArchs-page'>
            <h1>Orson Scott Card’s MICE Quotient</h1>
            <div className="card-heading">
                <p>In his book Characters and Viewpoint Orson Scott Card writes:</p>
                <p>"What are the different kinds of stories? Forget about publishing genres for a moment; there isn’t one kind of characterization for academic-literary stories, another kind for science fiction, and still others for westerns, mysteries, thrillers, or historicals. Instead let’s look at four basic factors present in every story, with varying degrees of emphasis. Balancing these factors determines what sort of characterization a story must have, should have, or can have."</p>
                <p>The four factors are <b>milieu</b>, <b>idea</b>, <b>character</b>, and <b>event</b>.</p>
            </div>
            <br/>
            <ul>
                <li><h3>Milieu</h3></li>
                    <p>A milieu story concerns the <b>world</b> surrounding the characters you create.</p>
                    <ul>
                        <li><p><u>Start:</u> The story begins when the main character enters the strange new world. Drop the reader directly into the world.</p></li>
                        <li><p><u>End:</u> The story ends when the main character comes back from the strange new world.</p></li>
                    </ul>
                <li><h3>Idea</h3></li>
                    <p>An idea story concerns the <b>information</b> you intend the reader to uncover or learn as they read your story.</p>
                    <ul>
                        <li><p><u>Start:</u> The story starts by raising a question – when your main character meets an obstacle. They have a problem that must be solved. This gives rise to a question: how will they get around the obstacle?</p></li>
                        <li><p><u>End:</u> The story ends when the character has answered the question and removed the obstacle.</p></li>
                    </ul>
                <li><h3>Character</h3></li>
                    <p>A character story concerns the <b>nature</b> of at least one of the characters in your story. Specifically, <b>what</b> this character does and <b>why</b> they do it.</p>
                    <ul>
                        <li><p><u>Start:</u> Your main character is unbearably dissatisfied with their role in society and sets about changing it.</p></li>
                        <li><p><u>End:</u> Your main character either finds a new role, is content to return to their old role, or despairs.</p></li>
                    </ul>
                <li><h3>Event</h3></li>
                    <p>An event story concerns <b>what</b> happens and <b>why</b> it happens.</p>
                    <ul>
                        <li><p><u>Start:</u> Your main character tries to restore order to the world.</p></li>
                        <li><p><u>End:</u> Your main character either succeeds or fails.</p></li>
                    </ul>
            </ul>
            <br/>
            <ul>
                <li><p>A one-thread story will likely be pretty boring. The idea here is that depending on what thread you put emphasis on, that makes your story what type it is.</p></li>
                <li><p>Nest the different threads inside of one another. If you’re going to use multiple threads, you should open and close them in order. (Though you don’t need to use all four.)</p></li>
                <li><p>The order is:<br/><br/>
                    open (<b>character</b> – <b>event</b> – <b>millieu</b> – <b>inquiry</b>) - close (<b>inquiry</b> – <b>milieu</b> – <b>event</b> - <b>character</b>)</p></li>
                <li><p>Example of all four: Begin with identity crisis, then the status quo is disrupted, they enter a place, and a question arises. Then, they answer the question, leave the place, restore the status quo and gain a new sense of self.</p></li>
                <li><p>Aim to resolve all threads as closely together as possible at the climax, all in one swoop if possible.</p></li>
            </ul>
            <a href='https://www.goodreads.com/en/book/show/7963' target='_blank' rel="noreferrer"><img src ='/images/card.jpeg' alt='Characters and Viewpoint by Orson Scott Card'/></a>
        </div>
    )
}