import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBook } from "@fortawesome/free-solid-svg-icons";
import { formatPotdDate } from "../../utilities/formatDate";
import JsonToReact from "../../utilities/jsonToReact";
import { setPrompt } from "../prompts/writePrompt/writePromptSlice";

export const HomePotd = () => {
  const { potds, stories } = useSelector((state) => state.app);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // Sort potds by time in descending order and get the most recent one
  const sortedPotds = potds.length ? [...potds].sort((a, b) => new Date(b.time) - new Date(a.time)) : [];
  const mostRecentPotd = sortedPotds.length ? sortedPotds[0] : null;

  // Handle writing the prompt
  const handleWrite = (prompt) => {
    dispatch(setPrompt({ type: prompt.promptType, prompt: prompt.prompt, contest: 'potd', contestId: prompt.id }));
    navigate("/writePrompt");
  };

  // Count stories related to the most recent POTD
  const storyCount = mostRecentPotd ? stories.filter(story => story.contest === 'potd' && story.contestId === mostRecentPotd.id).length : 0;

  // Handle clicking the prompt to view its details
  const handleClick = (potdId) => {
    navigate(`/potd/${potdId}`);
  };

  if (!mostRecentPotd) {
    return <div>No Prompt of the Day available.</div>;
  }

  return (
    <div className="home-potd">
      <h3 id='home-potd-header' onClick={() => handleClick(mostRecentPotd.id)} style={{ cursor: "pointer" }}>{formatPotdDate(mostRecentPotd.time)}</h3>

      <div className="potd-prompt-home" >
        {JsonToReact({ jsonString: mostRecentPotd.prompt })}
      </div>

      <div className="potd-info">
        <span>{storyCount} <FontAwesomeIcon icon={faBook} /></span>
      </div>

      <div className="potd-write">
        <button id="button2" onClick={() => handleWrite(mostRecentPotd)}>Write It</button>
      </div>
    </div>
  );
};
