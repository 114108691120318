import { GoogleAuthProvider } from "firebase/auth/web-extension";
import { auth } from "./firebase";
import { createUserWithEmailAndPassword, sendEmailVerification, sendPasswordResetEmail, signInWithEmailAndPassword, signInWithPopup, updatePassword, updateProfile } from "firebase/auth";
import { FirebaseError } from "firebase/app";

//updates username
export const doUpdateProfile = async (
    username) => {

    if (!auth.currentUser) {
        return {
            error: 'Not Logged In!'
        };
    }
    try {
        await updateProfile(auth.currentUser, {
            displayName: username
        });
    } catch (e) {
        if (e instanceof FirebaseError) {
            return {
                error: e.message
            };
        }
    }
    return {};
};

export const doCreateUserWithEmailAndPassword = async(email,password)=>{
    return createUserWithEmailAndPassword(auth, email,password).catch((err) =>
        console.log(err)
    );
};

export const doSignInWithEmailAndPassword = async(email,password)=>{
    return signInWithEmailAndPassword(auth,email,password)
};

export const doSignInWithGoogle = async() => {
    const provider = new GoogleAuthProvider();
    const result = await signInWithPopup(auth, provider);
    const user = result.user;
    return user;
};

export const doSignOut = ()=>{
    return auth.signOut();
}

export const doPasswordReset=(email)=>{
    return sendPasswordResetEmail(auth,email);
};

export const doPasswordChange = (password)=> {
    return updatePassword(auth.currentUser, password);
};

export const doSendEmailVerification =()=>{
    return sendEmailVerification(auth.currentUser, {
        url: `${window.location.origin}/home`
    });
};