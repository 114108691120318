// src/components/News.js
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "./news.css";
import { formatDate } from "../../../utilities/formatDate";

export const News = () => {
  // Access newsItems from the global Redux state
  const newsItems = useSelector((state) => state.app.news);

  return (
    <div className="rolodex">
      <div className="news-items-container">
        {newsItems && newsItems.length > 0 ? (
          newsItems.map((item) => (
            <Link to={item.link} key={item.id} className="news-item">
              <h4>{item.title}</h4>
              <p>{item.content}</p>
              <p className="news-date">{formatDate(new Date(item.date))}</p>
            </Link>
          ))
        ) : (
          <p>No news available.</p>
        )}
      </div>
    </div>
  );
};
