import { createSlice } from "@reduxjs/toolkit";
import { genres, plots, personalities, characters, arcs, hooks,endings,litDevices, narStyles } from "./descriptions";

const generate = () => {
let story = {
    genres: ['sci-fi','fantasy','mystery','thriller','dystopian','steampunk','romance','historical fiction','post-apocalyptic','adventure','horror','literary fiction'],
    characters: ['innocent','everyman','hero','caregiver','explorer','rebel','lover','creator','jester','sage','magician','ruler'],
    personalities: ['architect','logician','commander','debater','advocate','mediator','protagonist','campaigner','logistician','defender','executive','consul','virtuoso','adventurer','entrepreneur','entertainer'],
    plots: ['overcoming the monster','rags to riches','voyage and return','quest','comedy','tragedy','rebirth','rebellion against the one'],
    themes: [
        'self sacrifice',
        'the evils of technology/false idols',
        'death and dying',
        'revolution',
        'faith',
        'the fear of failure',
        'the nature of the universe',
        'overcoming obstacles',
        'love',
        'power and corruption',
        'justice and mercy',
        'heartbreak',
        'the wisdom of experience',
        'self reliance',
        'greed',
        'fate and free will',
        'believing in yourself',
        'injustice and inequality',
        'an identity crisis',
        'grief',
        'redemption',
        'the clash of cultures',
        'the burden of leadership',
        'the fragility of life',
        'coming of age',
        'the pursuit of knowledge',
        'betrayal',
        'the fear of the unknown',
        'friendship and loyalty',
        'hope and despair',
        'parenthood and legacy',
        'social isolation',
        'the search for meaning',
        'war and peace',
        'sacrificing for the greater good',
        'illusion vs. reality',
        'madness and sanity',
        'the duality of man',
        'survival',
        'the power of language',
        'freedom vs. control',
        'lost innocence',
        'the quest for power',
        'memory and nostalgia',
        'revenge',
        'duty vs. desire',
        'humanity vs. nature'
    ]
    ,
    arcs: ['positive change','flat','corruption','disillusionment','fall'],
    genre2: ['sci-fi','fantasy','mystery','thriller','dystopian','steampunk','romance','historical fiction','post-apocalyptic','adventure','horror','literary fiction','satire','spy vs. spy','space opera', 'first contact','alternative history','cyberpunk','magical realism','super hero','heist','urban fantasy','western'],
    hooks: ['action','analogy','command','bold statement','thoughts or feelings','definition','dialogue','foreshadowing','onomatopoeia','question','theme statement','sentence fragment','setting'],
    endings: ['circular','twist','moral','warm & fuzzy','reflection','cliffhanger','question','humorous','image','dialogue'],
    litDevices: ['juxtaposition','macguffin','framing device','red herring',`chekhov's gun`,'ticking clock','self-fulfilling prophecy','unreliable narrator','alliteration','symbolism','predestination','poetic justice'],
    narStyles: ['first-person','second-person','third-person limited','third-person omniscient','objective','parallel','epistolary','stream-of-consciousness','linear','non-linear'],
    length: ['500','1000','1500','2000','2500','3000','5000','7500','10000','12500','15000','20000']

    };

const random0 = Math.floor(Math.random() * story.genres.length);
const random1 = Math.floor(Math.random() * story.characters.length);
const random2 = Math.floor(Math.random() * story.personalities.length);
const random3 = Math.floor(Math.random() * story.plots.length);
const random4 = Math.floor(Math.random() * story.themes.length);
const random5 = Math.floor(Math.random() * story.arcs.length);
const random6 = Math.floor(Math.random() * story.genre2.length);
const random7 = Math.floor(Math.random() * story.hooks.length);
const random8 = Math.floor(Math.random() * story.endings.length);
const random9 = Math.floor(Math.random() * story.litDevices.length);
const random10 = Math.floor(Math.random() * story.narStyles.length);
// const random11 = Math.floor(Math.random() * story.length.length);


let genre = story.genres[random0];
let character = story.characters[random1];
let personality = story.personalities[random2];
let plot = story.plots[random3];
let theme = story.themes[random4];
let arc = story.arcs[random5];
let genre2 = story.genre2[random6];
let hook = story.hooks[random7];
let ending = story.endings[random8];
let litDevice = story.litDevices[random9];
let narStyle = story.narStyles[random10];
// let length = story.length[random11];





const items = {genre,character,personality,plot,theme,arc,genre2,hook,ending,litDevice,narStyle}
return items
}

export const plotPromptSlice = createSlice({
    name: 'plotPrompt',
    initialState: {
        prompt: '',
        genre: '',
        character: '',
        personality: '',
        plot: '',
        theme: '',
        arc: '',
        genre2: '',
        hook: '',
        ending: '',
        litDevice: '',
        narStyle: '',
        length: '',
        gen: true,
        char: true,
        pers: true,
        plo: true,
        th: true,
        ar: true,
        gen2: false,
        ho: false,
        end: false,
        ld: false,
        ns: false,
        len: false,
        showGenre: '',
        showPlot: '',
        showPers: '',
        showChar: '',
        showArc: '',
        showGenre2: '',
        showHook: '',
        showEnding: '',
        showLitDevice: '',
        showNarStyle: '',
        showLength: '',
        //showAll: '',
    },
    reducers: {
        plotGen: (state) => {
    
            state.genre = generate().genre;
            state.character = generate().character;
            state.personality = generate().personality;
            state.plot = generate().plot;
            state.theme = generate().theme;
            state.arc = generate().arc;
            state.genre2 = generate().genre2;
            state.hook = generate().hook;
            state.ending = generate().ending;
            state.litDevice = generate().litDevice;
            state.narStyle = generate().narStyle;
            state.length = generate().length;

        },
        displayPlot: (state) =>{

            const genre = state.genre;
            const character = state.character;
            const personality = state.personality;
            const plot = state.plot;
            const theme = state.theme;
            const arc = state.arc;
            const genre2 = state.genre2;
            const hook = state.hook;
            const ending = state.ending;
            const litDevice = state.litDevice;
            const narStyle = state.narStyle;
            const length = state.length;

            const gen = state.gen;
            const char = state.char;
            const pers = state.pers;
            const plo = state.plo;
            const th = state.th;
            const ar = state.ar;
            const gen2 = state.gen2;
            const ho = state.ho;
            const end = state.end;
            const ld = state.ld;
            const ns = state.ns;
            const len = state.len

         
            function showGenre(){
                let description;
                    if(genre==='sci-fi'){
                        description = genres.scifi
                    }
                    if(genre==='fantasy'){
                        description = genres.fantasy
                    }
                    if(genre==='mystery'){
                        description = genres.mystery
                    }
                    if(genre==='thriller'){
                        description = genres.thriller
                    }
                    if(genre==='dystopian'){
                        description = genres.dystopian
                    }
                    if(genre==='steampunk'){
                        description = genres.steampunk
                    }
                    if(genre==='romance'){
                        description = genres.romance
                    }
                    if(genre==='historical fiction'){
                        description = genres.historicalFiction
                    }
                    if(genre==='post-apocalyptic'){
                        description = genres.postApocalyptic
                    }
                    if(genre==='adventure'){
                        description = genres.adventure
                    }
                    if(genre==='horror'){
                        description = genres.horror
                    }
                    if(genre==='literary fiction'){
                        description = genres.literaryFiction
                    }
                    return description;        
                } 
        
                function showPlot(){
                    let description;
                    if(plot==='overcoming the monster'){
                        description = plots.overcoming
                        }
                        if(plot==='rags to riches'){
                            description = plots.rags
                        }
                        if(plot==='voyage and return'){
                            description = plots.voyage
                        }
                        if(plot==='quest'){
                            description = plots.quest
                        }
                        if(plot==='comedy'){
                            description = plots.comedy
                        }
                        if(plot==='tragedy'){
                            description = plots.tragedy
                        }
                        if(plot==='rebirth'){
                            description = plots.rebirth
                        }
                        if(plot==='rebellion against the one'){
                            description = plots.rebellion
                        }
                        return description;        
        
        
                }
        
                function showPers(){
                    let description;
                    if(personality==='architect'){
                        description=personalities.architect
                    }
                    if(personality==='logician'){
                        description=personalities.logician
                    }
                    if(personality==='commander'){
                        description=personalities.commander
                    }
                    if(personality==='debater'){
                        description=personalities.debater
                    }
                    if(personality==='advocate'){
                        description=personalities.advocate
                    }
                    if(personality==='mediator'){
                        description=personalities.mediator
                    }
                    if(personality==='protagonist'){
                        description=personalities.protagonist
                    }
                    if(personality==='campaigner'){
                        description=personalities.campaigner
                    }
                    if(personality==='logistician'){
                        description=personalities.logistician
                    }
                    if(personality==='defender'){
                        description=personalities.defender
                    }
                    if(personality==='executive'){
                        description=personalities.executive
                    }
                    if(personality==='consul'){
                        description=personalities.consul
                    }
                    if(personality==='virtuoso'){
                        description=personalities.virtuoso
                    }
                    if(personality==='adventurer'){
                        description=personalities.adventurer
                    }
                    if(personality==='entrepreneur'){
                        description=personalities.entrepreneur
                    }
                    if(personality==='entertainer'){
                        description=personalities.entertainer
                    }
                    return description;        
                }
        
                function showChar(){
                    let description;
                    if(character==='lover'){
                        description=characters.lover
                    }
                    if(character==='jester'){
                        description=characters.jester
                    }
                    if(character==='hero'){
                        description=characters.hero
                    }
                    if(character==='sage'){
                        description=characters.sage
                    }
                    if(character==='ruler'){
                        description=characters.ruler
                    }
                    if(character==='magician'){
                        description=characters.magician
                    }
                    if(character==='everyman'){
                        description=characters.everyman
                    }
                    if(character==='caregiver'){
                        description=characters.caregiver
                    }
                    if(character==='rebel'){
                        description=characters.rebel
                    }
                    if(character==='innocent'){
                        description=characters.innocent
                    }
                    if(character==='explorer'){
                        description=characters.explorer
                    }
                    if(character==='creator'){
                        description=characters.creator
                    }
                    return description;        
                }
        
                function showArc(){
                    let description;
                    if(arc==='positive change'){
                        description =arcs.positive
                    }
                    if(arc==='flat'){
                        description =arcs.flat
                    }
                    if(arc==='corruption'){
                        description =arcs.corruption
                    }
                    if(arc==='disillusionment'){
                        description =arcs.disillusionment
                    }
                    if(arc==='fall'){
                        description =arcs.fall
                    }
                    return description;        
                }

                function showGenre2(){
                let description;
                    if(genre2==='sci-fi'){
                        description = genres.scifi
                    }
                    if(genre2==='fantasy'){
                        description = genres.fantasy
                    }
                    if(genre2==='mystery'){
                        description = genres.mystery
                    }
                    if(genre2==='thriller'){
                        description = genres.thriller
                    }
                    if(genre2==='dystopian'){
                        description = genres.dystopian
                    }
                    if(genre2==='steampunk'){
                        description = genres.steampunk
                    }
                    if(genre2==='romance'){
                        description = genres.romance
                    }
                    if(genre2==='historical fiction'){
                        description = genres.historicalFiction
                    }
                    if(genre2==='post-apocalyptic'){
                        description = genres.postApocalyptic
                    }
                    if(genre2==='adventure'){
                        description = genres.adventure
                    }
                    if(genre2==='horror'){
                        description = genres.horror
                    }
                    if(genre2==='spy vs. spy'){
                        description = genres.spyFiction
                    }
                     if(genre2==='satire'){
                        description = genres.satire
                    }
                     if(genre2==='magical realism'){
                        description = genres.magicalRealism
                    }
                     if(genre2==='urban fantasy'){
                        description = genres.urbanFantasy
                    }
                     if(genre2==='heist'){
                        description = genres.heist
                    }
                     if(genre2==='space opera'){
                        description = genres.spaceOpera
                    }
                     if(genre2==='first contact'){
                        description = genres.firstContact
                    }
                     if(genre2==='alternative history'){
                        description = genres.altHistory
                    }
                     if(genre2==='cyberpunk'){
                        description = genres.cyberpunk
                    }
                    if(genre2==='western'){
                        description = genres.western
                    }
                    if(genre2==='super hero'){
                        description = genres.superHero
                    }
                    if(genre2==='epic'){
                        description = genres.epic
                    }
                    return description;        
                } 

                function showHook(){
                    let description;
                    if(hook==='action'){
                        description=hooks.action;
                    }
                    if(hook==='analogy'){
                        description=hooks.analogy;
                    }
                    if(hook==='command'){
                        description=hooks.command;
                    }
                    if(hook==='bold statement'){
                        description=hooks.boldStatement;
                    }
                    if(hook==='thoughts or feelings'){
                        description=hooks.thoughts;
                    }
                    if(hook==='definition'){
                        description=hooks.definition;
                    }
                    if(hook==='dialogue'){
                        description=hooks.dialogue;
                    }
                    if(hook==='foreshadowing'){
                        description=hooks.foreshadowing;
                    }
                    if(hook==='onomatopoeia'){
                        description=hooks.onomatopoeia;
                    }
                    if(hook==='question'){
                        description=hooks.question;
                    }
                    if(hook==='theme statement'){
                        description=hooks.themeStatement;
                    }
                    if(hook==='sentence fragment'){
                        description=hooks.sentenceFragment;
                    }
                    if(hook==='setting'){
                        description=hooks.setting;
                    }
                    return description;
                }

                function showEnding(){
                    let description;
                    if(ending==='circular'){
                        description=endings.circular;
                    }
                    if(ending==='twist'){
                        description=endings.twist;
                    }
                    if(ending==='moral'){
                        description=endings.moral;
                    }
                    if(ending==='warm & fuzzy'){
                        description=endings.warm;
                    }
                    if(ending==='reflection'){
                        description=endings.reflection;
                    }
                    if(ending==='cliffhanger'){
                        description=endings.cliffhanger;
                    }
                    if(ending==='question'){
                        description=endings.question2;
                    }
                    if(ending==='humorous'){
                        description=endings.humorous;
                    }
                    if(ending==='image'){
                        description=endings.image;
                    }
                    if(ending==='dialogue'){
                        description=endings.dialogue2;
                    }
                    return description;
                }

                function showLitDevice(){
                    let description;

                    if(litDevice==='juxtaposition'){
                        description=litDevices.juxtaposition;
                    }
                    if(litDevice==='macguffin'){
                        description=litDevices.macguffin;
                    }
                    if(litDevice==='framing device'){
                        description=litDevices.framingDevice;
                    }
                    if(litDevice==='red herring'){
                        description=litDevices.redHerring;
                    }
                    if(litDevice==='predestination'){
                        description=litDevices.predestination;
                    }
                    if(litDevice==='self-fulfilling prophecy'){
                        description=litDevices.prophecy;
                    }
                    if(litDevice==='alliteration'){
                        description=litDevices.alliteration;
                    }
                    if(litDevice===`chekhov's gun`){
                        description=litDevices.chekhovsGun;
                    }
                    if(litDevice==='ticking clock'){
                        description=litDevices.tickingClock;
                    }
                    if(litDevice==='unreliable narrator'){
                        description=litDevices.unreliableNarrator;
                    }
                    if(litDevice==='poetic justice'){
                        description=litDevices.poeticJustice;
                    }
                    if(litDevice==='symbolism'){
                        description=litDevices.symbolism;
                    }
                    return description
                }



                function showNarStyle(){
                    let description;
                    if(narStyle==='first-person'){
                        description=narStyles.first
                    }
                    if(narStyle==='second-person'){
                        description=narStyles.second
                    }
                    if(narStyle==='third-person limited'){
                        description=narStyles.thirdLimited
                    }
                    if(narStyle==='third-person omniscient'){
                        description=narStyles.thirdO
                    }
                    if(narStyle==='stream-of-consciousness'){
                        description=narStyles.stream
                    }
                    if(narStyle==='epistolary'){
                        description=narStyles.epistolary
                    }
                    if(narStyle==='objective'){
                        description=narStyles.objective
                    }
                    if(narStyle==='linear'){
                        description=narStyles.linear
                    }
                    if(narStyle==='non-linear'){
                        description=narStyles.nonLinear
                    }
                    if(narStyle==='parallel'){
                        description=narStyles.parallel
                    }
                    return description;
                }


  

                //THE PROMPT

                    let fill1 = pers || char ? ' about ' : '';
                    let fill2 = ar? ' with a ' : '';
                    let fill3 = ar? ' character arc' : '';
                    let fill4 = th? ' about ' : '';
                    let fill5 = ' ';
                    let fill6 = gen2 && gen ? ' / ' : '';
                    let fill7 = 'A ';
                    let fill8 = 'a ';
                    let fill9 = ' with a ';
                    let fill10 = end && ' and a ';
                    let fill11 = ld && ' using ';
                    let fill12 = ns &&  ' and a ';

                    if(genre==='adventure' && gen){
                        fill7 = 'An '
                    }
                    if(genre2==='adventure' && !gen && gen2){
                        fill7 = ' an '
                    }
                    if(!gen && plot && plot==='overcoming the monster'){
                        fill7 = 'A '
                    }
                    
                    personality === 'advocate' ||personality ===  'adventurer' ||personality ===  'executive' ||personality ===  'entrepreneur' ||personality ===  'entertainer' ||personality ===  'advocate' ||personality ===  'architect' ? fill8 = 'an ' : fill8 = 'a ';
                    if(!pers){
                        if(character === 'everyman' || character === 'innocent' ||character === 'explorer'){
                            fill8 = 'an ';
                        } else fill8 = 'a ';
                    }
                       
                    if(ho){
                        if(hook ==='analogy' ||hook=== 'action'){
                            fill9=' with an '
                        }
                    }

                    if(ld){
                        if(litDevice==='framing device' ||litDevice==='red herring'||litDevice==='macguffin'||litDevice==='self-fulfilling prophecy'||litDevice==='ticking clock' ){
                            fill11 = ' using a '
                        }
                        if(litDevice === 'unreliable narrator'){
                            fill11 = ' using an '
                        }
                    }

                    if(ns){
                        if(!ld){
                            if(narStyle==='objective' || narStyle==='epistolary'){
                                fill12 = ' using an '
                            } else fill12 = ' using a '
                        } else

                            if(narStyle==='objective' || narStyle==='epistolary'){
                                fill12 = ' and an '
                            } else fill12 = ' and a '
                    }

                    

        
            state.prompt = JSON.stringify(
                <div className = 'text' >{fill7}{gen &&<b>{genre}<span>{showGenre()}</span></b>}{fill6}{gen2 &&<b>{genre2}<span>{showGenre2()}</span></b>}{fill5}
                 {plo && <b>{plot}<span>{showPlot()}</span></b>}{fill5}
                  story{fill1}{(pers || char) && fill8}{pers && <b>{personality}<span>{showPers()}</span></b>}
                {(pers && char) && ' '}{char && <b>{character}<span>{showChar()}</span></b>}{fill2}
                  {ar && <b>{arc}<span>{showArc()}</span></b>}
                  {fill3}{fill4}
                  {th && <i>{theme}</i>}{ho&&fill9}{ho &&<b>{hook}<span>{showHook()}</span></b>}{ho && ' hook'}
                  {ho&& fill10}{(!ho && end) && fill9}{end &&<b>{ending}<span>{showEnding()}</span></b>}{end && ' ending'}
                  {fill11}{ld &&<b>{litDevice}<span>{showLitDevice()}</span></b>}{fill12}{ns &&<b>{narStyle}<span>{showNarStyle()}</span></b>}{ns &&' narrative'}
                  {len && ' in under '}{len && <>{length}</>}{len && ' words'}</div>)
                



            /*state.showAll = {
                genre: state.showGenre,
                plot: state.showPlot,
                personality: state.showPers,
                character: state.showChar,
                arc: state.showArc
            }*/

        },
        selectElements: (state, action) => {
            if(action.payload==='gen'){
                state.gen ? state.gen=false : state.gen=true;
            }
            if(action.payload==='char'){
                  state.char? state.char=false : state.char=true;
            }
            if(action.payload==='pers'){
                  state.pers? state.pers=false: state.pers=true;
            }
            if(action.payload==='plo'){
                  state.plo? state.plo=false: state.plo=true;
            }
            if(action.payload==='th'){
                  state.th? state.th=false: state.th=true;
            }
            if(action.payload==='ar'){
                  state.ar? state.ar=false: state.ar=true;
            }
            if(action.payload==='gen2'){
                    state.gen2? state.gen2=false: state.gen2=true;
            }
            if(action.payload==='ho'){
                    state.ho? state.ho=false: state.ho=true;
            }
            if(action.payload==='end'){
                    state.end? state.end=false: state.end=true;
            }
            if(action.payload==='ld'){
                    state.ld? state.ld=false: state.ld=true;
            }
            if(action.payload==='ns'){
                    state.ns? state.ns=false: state.ns=true;
            }
            if(action.payload==='len'){
                    state.len? state.len=false: state.len=true;
            }

        },
       
    }
})


export const selectPlotPrompt = (state) => state.plotPrompt
export const selectSavedPrompts = (state) => state.plotPrompt.savedPrompts;
//export const selectShowAll = (state) => state.plotPrompt.showAll;
export default plotPromptSlice.reducer;
export const { plotGen, selectElements, displayPlot, showAll } = plotPromptSlice.actions;