import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { doc, setDoc, collection } from "firebase/firestore";
import { Link, useNavigate } from "react-router-dom";
import './newStory.css';
import { firestore } from "../../firebase/firebase";
import { setActivity, setStories } from "../../app/appSlice";
import { LeftSide } from "../../app/leftSide/leftSide";
import { RightSide } from "../../app/rightSide/rightSide";
import { TandC } from "../prompts/writePrompt/T&C";
import { GenreSelector } from "../../utilities/genreSelector/genreSelector";
import { selectGenres } from "../prompts/writePrompt/writePromptSlice";

export const NewStory = () => {
    const [storyPremise, setStoryPremise] = useState('');
    const [title, setTitle] = useState('');
    const [story, setStory] = useState('');
    const [wordCount, setWordCount] = useState(0);
    const [contentWarnings, setContentWarnings] = useState([]);
    const [showContentWarnings, setShowContentWarnings] = useState(false);
    const [showGuidelines, setShowGuidelines] = useState(false);
    const [showPremiseGuidelines, setShowPremiseGuidelines] = useState(false);
    const [agreeToTerms, setAgreeToTerms] = useState(false);
    const [openToRatings, setOpenToRatings] = useState(false);
    const [termsPopup, setTermsPopup] = useState(false);
    const navigate = useNavigate();
    const [wordCountMax, setWordCountMax] = useState(0);
    const wordCountMin =250;

    const dispatch = useDispatch();
    const {activity, stories, user} = useSelector(state=>state.app);
    const genres = useSelector(selectGenres);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleTitle = ({ target }) => setTitle(target.value);
    const handleStoryPremise = ({ target }) => setStoryPremise(target.value);


    const handleStory = ({ target }) => {
        const storyText = target.value;
        const wordCount = storyText.trim().split(/\s+/).length;
        setStory(storyText);
        setWordCount(wordCount);
    };

    const handleContentWarnings = ({ target }) => {
        const selectedWarning = target.value;
        setContentWarnings(prevWarnings => {
            if (prevWarnings.includes(selectedWarning)) {
                return prevWarnings.filter(warning => warning !== selectedWarning);
            } else {
                return [...prevWarnings, selectedWarning];
            }
        });
    };

    const writeStory = async () => {
        const newDocRef = doc(collection(firestore, `stories`));
        const storyUid = newDocRef.id;

        const docData = {
            title: title,
            genres: genres,
            story: story,
            contentWarnings: contentWarnings,
            prompt: storyPremise,
            promptType: 'original',
            user: user.username,
            author: user.penName ? user.penName : user.username,
            time: new Date().toISOString(),
            uid: storyUid,
            views: 0,
            judged: false,
            score: 0,
            contest: '',
            contestId: '',
            flags: [],
            wordCount: wordCount,
            rateable: openToRatings,
            universe: '',
            comments: [],
        };

        try {
            await setDoc(newDocRef, docData);
            const updatedStories = [...stories, docData];
            dispatch(setStories(updatedStories));
            console.log('Story document written with ID: ', storyUid);
        } catch (error) {
            console.error('Error adding document:', error);
        }

        const addActivity = async (action) => {
            const newDocRef = doc(collection(firestore, `activity`));
            const uid = newDocRef.id;
            const docData = {
                user: user.username,
                username: user.username,
                action: action,
                title: title,
                time: new Date().toISOString(),
                uid: uid,
                author: user.penName ? user.penName : user.username,
                storyId: storyUid,
                type: 'story',
                prompt: storyPremise,
                promptType: 'original',
                actId: storyUid,
            };

            try {
                await setDoc(newDocRef, docData);
                const updatedActivities = [...activity, docData];
                dispatch(setActivity(updatedActivities));
                console.log('Activity document written with ID: ', uid);
            } catch (error) {
                console.error('Error adding document:', error);
            }
        };

        addActivity('submitted');
    };

    const onSubmit = () => {
        if (!agreeToTerms) {
            alert("Please agree to the terms and conditions before submitting.");
            return;
        }
        if (title === '') {
            alert("Please give your story a title.");
            return;
        }
        if (storyPremise === '') {
            alert("Please provide a story premise.");
            return;
        }
        writeStory();
        navigate(`/${user.username}`);
    };

    const genreOptions = [
        "science fiction", "fantasy", "mystery", "thriller", "adventure", "horror",
        "historical fiction", "western",
        "literary", "romance","dystopian", "steampunk",
        "space opera", "first contact", 
        "post-apocalyptic", "alternative history", "epic", "urban fantasy", "magical realism", 
        "cyberpunk", "superhero",
         "satire", "heist", 'spy'
    ];
    const contentWarningOptions = [
        "Violence", "Profanity", "Sexual Content", "Drug Use", "Self-Harm",
        "Suicide", "Child Abuse", "Animal Abuse", "Racism", "Homophobia",
        "Transphobia", "Ableism", "Misogyny"
    ];

    const findWordCount = (rank) => {
        switch (rank) {
            case 'Master':
                setWordCountMax(5000)
                break;
            case 'Wordsmith':
                    setWordCountMax(4000)
                break;
            case 'Storyteller':
                    setWordCountMax(3000)
                break;
            case 'Scribe':
                    setWordCountMax(2500)
                break;
            case 'Journeyman':
                    setWordCountMax(2000)
                break;
            case 'Apprentice':
                    setWordCountMax(1750)
                break;
            case 'Scribbler':
                    setWordCountMax(1500)
                break;
            default:
                setWordCountMax(1250)
        }
    }

    useEffect(() => {
        if (user && wordCountMax === 0) {
            findWordCount(user.rank); // Ensure this doesn't trigger re-renders unless necessary
        }
    }, [user, wordCountMax]);
    
    return (
        <div className="page">
            <LeftSide />
            <div className='content'>
                <header><h1>Write Your Story</h1></header>

                <div className='genres-div'>
                    <label><h3>Select 1-2 Genres</h3></label>
                    <GenreSelector genres={genreOptions} iconStyle={{width: '80px'}}/>
                </div>
                <div className='guidelines'>
                    <label onClick={() => setShowGuidelines(!showGuidelines)} style={{ cursor: 'pointer' }}>
                        <h3>Submission Guidelines ▼</h3>
                    </label>
                    {showGuidelines && 
                        <div className="guidelines-text">
                            <ul>
                                <li>
                                    <p><strong>Write Safely: </strong> We recommend writing your story in a word processor first, then copying and pasting it here. If you write directly on this page and it reloads, you'll lose your story!</p>
                                </li>
                                <li>
                                    <p><strong>Originality: </strong> Only submit original work; don't try to be someone you're not.</p>
                                </li>
                                <li>
                                    <p><strong>Avoid AI Assistance: </strong> Please do not use generative AI to write your stories. I mean, what would be the point?</p>
                                </li>
                                <li>
                                    <p><strong>Prompt Relevance: </strong> It is my opinion that the prompt should be viewed in service to the story, not the other way around. The story should hold up without knowledge of the prompt.</p>
                                </li>
                                <li>
                                    <p><strong>Formatting Tips: </strong> Format your story clearly, using proper paragraph breaks and punctuation.</p>
                                </li>
                                <li>
                                    <p><strong>Content Warnings: </strong> Be sure to select the appropriate content warnings at the bottom of this page. Make this a chill place for everybody.</p>
                                </li>
                                <li>
                                    <p><strong>Word Count Guidelines: </strong> Unless otherwise specified by contest rules, aim for a word count between 250 words and the maximum allowed by your rank.</p>
                                </li>
                                <li>
                                    <p><strong>Check Contest Eligibility: </strong> Before submitting, visit the contests page to see if your story qualifies for any open contests. By agreeing to our contest terms and conditions, your story will be open to being rated by other users.</p>
                                    <p>If you submit more than one story for the same contest, only your most recent submission will be considered.</p>
                                </li>
                                <li>
                                    <p><strong>Engage with the Community: </strong> We encourage you to read, rate and comment on other submissions. Engaging with the community not only fosters a supportive environment but it will increase the reach of your own stories. Story influence is also considered in contest judgments. Share your stories on social media to maximize visibility!</p>
                                </li>
                                <li>
                                    <p><strong>Need Help Writing? </strong>Check out our <Link to='/write-idea' className="link">7 step process</Link> for turning your idea into an awesome story!</p>
                                </li>
                            </ul>
                        </div>
                    }
                </div>
                <div className='title-input'>
                    <h3>Title</h3>
                    <input type='text' placeholder='Enter a title...' value={title} onChange={handleTitle} />
                </div>
                <div className='story-premise'>
                    <div className="premise-guidelines">
                        <label onClick={() => setShowPremiseGuidelines(!showPremiseGuidelines)} style={{ cursor: 'pointer' }}>
                                    <h3>Story Premise ▼</h3>
                                </label>
                        {showPremiseGuidelines && (
                        <div className="premise-text">
                            <p>
                                A story premise explains the core idea behind your story using five key elements:
                            </p>
                            <ol>
                                <li>
                                    <strong>Protagonist:</strong> Who is the hero of your story?  
                                    <br/>
                                    <em>Example:</em> "A disillusioned hacker..."
                                </li>
                                <li>
                                    <strong>Situation:</strong> What is their status quo?  
                                    <br/>
                                    <em>Example:</em> "...searching for the truth about his reality..."
                                </li>
                                <li>
                                    <strong>Objective:</strong> What must they do?  
                                    <br/>
                                    <em>Example:</em> "...must discover the power within himself as the One..."
                                </li>
                                <li>
                                    <strong>Opponent:</strong> Who opposes them?  
                                    <br/>
                                    <em>Example:</em> "...in order to free humanity from robotic oppression..."
                                </li>
                                <li>
                                    <strong>Disaster:</strong> What are the stakes?  
                                    <br/>
                                    <em>Example:</em> "...lest humanity remain enslaved forever."
                                </li>
                                
                            </ol>
                            <p>
                                Full Example: A disillusioned hacker searching for the truth about his reality must discover the power within himself in order to free humanity from robotic oppression lest humanity remain enslaved forever.
                            </p>
                            <b>
                                OR you can summarize your story any way you want! Keep in mind, it's the hook that will get readers in the door!
                            </b>
                        </div>
                        )}
                    </div>
                    <textarea 
                        placeholder="Enter your story premise..." 
                        value={storyPremise} 
                        onChange={handleStoryPremise} 
                    />
                </div>
                <div className='story-input'>
                    <h3>Your Story</h3>
                    <textarea
                        placeholder="Write your story here..."
                        value={story}
                        onChange={handleStory}
                    />
                    <p className={`word-count ${wordCount > 5000 ? 'over-limit' : ''}`}>Word Count: {wordCount}/5000</p>
                </div>
                <div className='content-warnings'>
                    <label onClick={() => setShowContentWarnings(!showContentWarnings)} style={{ cursor: 'pointer' }}>
                        <h3>Content Warnings ▼</h3>
                    </label>
                    {showContentWarnings &&
                        <div className="genre-checkboxes">
                            {contentWarningOptions.map((warning, index) => (
                                <div key={index} className="checkbox-item">
                                    <label>
                                        <input
                                            type="checkbox"
                                            value={warning}
                                            checked={contentWarnings.includes(warning)}
                                            onChange={handleContentWarnings}
                                        />
                                        {warning}
                                    </label>
                                </div>
                            ))}
                        </div>
                    }
                </div>
                <div className="terms-ratings">
                    <div className="terms-conditions">
                    {termsPopup && (
                                <div className="popup">
                                    <div className="popup-content">
                                        <TandC />
                                        <button onClick={() => setTermsPopup(false)}>Close</button>
                                    </div>
                                </div>
                            )}
                        
                            <input
                                type="checkbox"
                                checked={agreeToTerms}
                                onChange={() => setAgreeToTerms(!agreeToTerms)}
                            />
                        <label>
                            I agree to the <span className="terms-link" onClick={() => setTermsPopup(true)}>terms and conditions</span>
                            
                        </label>
                    </div>
                    <div className="ratings-eligibility">
                        <label>
                            <input
                                type="checkbox"
                                checked={openToRatings}
                                onChange={() => setOpenToRatings(!openToRatings)}
                            />
                            I wish to have my story rateable and be eligible for contests
                        </label>
                    </div>
                </div>
                {user && agreeToTerms && wordCount >= wordCountMin && <button className="submit-button" onClick={onSubmit}>Submit</button>}
            </div>
            <RightSide />
        </div>
    );
};
