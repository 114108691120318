import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import './contests.css';
import JsonToReact from "../../../utilities/jsonToReact";
import { useDispatch, useSelector } from "react-redux";
import { setPrompt } from "../../prompts/writePrompt/writePromptSlice";
import { formatDate } from "../../../utilities/formatDate";
import { PotdHome } from "../../potd/potdHome";

export const ContestsHome = () => {
    const { contests, user } = useSelector(state => state.app);
    const [showOpen, setShowOpen] = useState(false);
    const [showClosed, setShowClosed] = useState(false);
    const [showPotd, setShowPotd] = useState(true);  // New state for POTD

    useEffect(()=>{
        window.scrollTo(0,0)

    },[])

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleContestClick = (contest) => {
        navigate(`/contests/${contest.id}`, { state: { contest } });
    };

    const handleWrite = ({ type, prompt, contest, contestId, maxWordCount, minWordCount }) => {
        dispatch(setPrompt({ type: type, prompt: prompt, contest: contest, contestId: contestId, maxWordCount: maxWordCount, minWordCount: minWordCount }));
    };

    const handleClick = (view) => {
        if (view === 'open') {
            setShowOpen(true);
            setShowClosed(false);
            setShowPotd(false);
        } else if (view === 'closed') {
            setShowOpen(false);
            setShowClosed(true);
            setShowPotd(false);
        } else if (view === 'potd') {  // Handle POTD click
            setShowOpen(false);
            setShowClosed(false);
            setShowPotd(true);
        }
    };

    const today = new Date();
    const openContests = contests.filter(contest => new Date(contest.endDate) >= today);
    const closedContests = contests.filter(contest => new Date(contest.endDate) < today);   

    return (
        <div className="contests">
            <nav className="prof-nav">
            <h3 className={showPotd ? "active" : ""} onClick={() => handleClick('potd')}>Prompts of the Day</h3>  {/* POTD Nav Option */}
                <h3 className={showOpen ? "active" : ""} onClick={() => handleClick('open')}>Open Contests</h3>
                <h3 className={showClosed ? "active" : ""} onClick={() => handleClick('closed')}>Closed Contests</h3>
            </nav>

            {/* Open Contests */}
            {showOpen && openContests.length > 0 ? (
                openContests.map((contest) => (
                    <div key={contest.id} className="contest-header" >
                        <h2 className='storyTitle' onClick={() => handleContestClick(contest)}>
                            {contest.title}
                        </h2>
                        <p className="contest-description">{contest.description}</p>
                        {contest.prompt && (
                            <div className="contest-prompt">
                                {JsonToReact({ jsonString: contest.prompt })}
                                <br/>
                                {user && (
                                    <NavLink to='/writePrompt'>
                                        <button id='button2' onClick={() => handleWrite({
                                            contest: contest.title,
                                            contestId: contest.id,
                                            type: contest.promptType,
                                            prompt: contest.prompt,
                                            maxWordCount: contest.maxWordCount,
                                            minWordCount: contest.minWordCount
                                        })}>Enter Contest</button>
                                    </NavLink>
                                )}
                            </div>
                        )}
                        <p>{contest.minWordCount} - {contest.maxWordCount} words</p>
                        <p className="date-range">Open from: {contest.startDate ? formatDate(contest.startDate) : 'N/A'} to {contest.endDate ? formatDate(contest.endDate) : 'N/A'}</p>
                        <p className="prize">Prize: {contest.prize}</p>
                    </div>
                ))
            ) : showOpen && openContests.length === 0 ? (
                <p>No open contests available.</p>
            ) : null}

            {/* Closed Contests */}
            {showClosed && closedContests.length > 0 ? (
                closedContests.map((contest) => (
                    <div key={contest.id} className="contest" >
                        <h3 className='storyTitle' onClick={() => handleContestClick(contest)}>
                            {contest.title}
                        </h3>
                        <p>{contest.description}</p>
                        <h4>Prize: {contest.prize}</h4>
                        {contest.prizeImage && <img loading="lazy" id='prize-img' src={contest.prizeImage} width='200px' alt={contest.prize} />}
                        <p>Ran from: {contest.startDate ? formatDate(contest.startDate) : 'N/A'} to {contest.endDate ? formatDate(contest.endDate) : 'N/A'}</p>
                    </div>
                ))
            ) : showClosed && closedContests.length === 0 ? (
                <p>No closed contests available.</p>
            ) : null}

            {showPotd && <PotdHome />}
        </div>
    );
};
