import React from "react";
import { NavLink, Outlet } from "react-router-dom";
import { LeftSide } from "../../app/leftSide/leftSide";
import { RightSide } from "../../app/rightSide/rightSide";
import './get-idea.css';

export const GetIdea = () => {
    window.scrollTo(0,0)

    return(
        <div className = 'page'>
            <LeftSide />
            <div className='content'>
                <header>
                    <h1>8 Steps for Creating an Awesome Story Idea</h1>
                </header>
                <div id='get-idea'> 

                    <nav className='sideNav'>
                            <button className="sidebtn" id='button3'>Menu
                                <i className="fa fa-caret-down"></i>
                            </button>
                            <div className="sidedown-content">
                                <NavLink to='get-idea-step-1' id='button3' className='sideLink'><span className="text">1. Genre</span></NavLink>
                                <NavLink to="get-idea-step-2" id='button3' className='sideLink'><span className="text">2. Plot Archetype</span></NavLink>
                                <NavLink to="get-idea-step-3" id='button3' className='sideLink'><span className="text">3. Character</span></NavLink>
                                <NavLink to="get-idea-step-4" id='button3' className='sideLink'><span className="text">4. Theme</span></NavLink>
                                <NavLink to="get-idea-step-5" id='button3' className='sideLink'><span className="text">5. Goal & Flaw</span></NavLink>
                                <NavLink to="get-idea-step-6"id='button3'className='sideLink' ><span className="text">6. Opposition</span></NavLink>
                                <NavLink to="get-idea-step-7" id='button3' className='sideLink'><span className="text">7. Supporting Cast</span></NavLink>
                                <NavLink to="get-idea-step-8"id='button3' className='sideLink'><span className="text">8. Setting</span></NavLink>
                                <NavLink to="get-idea-summary" id='button3'className='sideLink' ><span className="text">Summary</span></NavLink>
                            </div>
                    </nav>
                    
                    <Outlet/>
                    
                </div>
            </div>
            <RightSide />
        </div>
    )
}