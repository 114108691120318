import React, { useState } from "react";
import './thought.css';  // Include this for CSS
import { collection, doc, setDoc } from "firebase/firestore";
import { firestore } from "../../../firebase/firebase";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

export const Thought = ({ onNewThought }) => {
    const [thoughtText, setThoughtText] = useState('');
    const [isExpanded, setIsExpanded] = useState(false);
    const [wordCount, setWordCount] = useState(0);
    const [showWarning, setShowWarning] = useState(false);
    const { username } = useParams();
    const {user} = useSelector(state => state.app);

    // Function to count words
    const countWords = (text) => {
        return text.trim().split(/\s+/).filter(Boolean).length;
    };

    // Handles the change in textarea value
    const handleThought = (event) => {
        const text = event.target.value;
        const wordCount = countWords(text);

        setThoughtText(text);
        setWordCount(wordCount);

        // Show warning if the word count exceeds 300
        if (wordCount > 300) {
            setShowWarning(true);
        } else {
            setShowWarning(false);
        }
    };

    // Expands the textarea when focused
    const handleFocus = () => {
        setIsExpanded(true);
    };

    // Shrinks the textarea when blurred
    const handleBlur = () => {
        if (!thoughtText) {
            setIsExpanded(false);
        }
    };

    // This will store the thought directly in the 'activity' collection
    const handleSubmit = async () => {
        if (thoughtText.trim() !== "" && wordCount <= 300 && user) {
            const newDocRef = doc(collection(firestore, `activity`)); // Store only in activity
            const uid = newDocRef.id;

            const activityData = {
                user: user.username,
                action: 'shared a thought',
                text: thoughtText,
                uid: uid,
                username: username,
                time: new Date().toISOString(),
                type: 'thought',  // Specify that this is a thought activity
            };

            try {
                // Store the thought as part of the activity collection
                await setDoc(newDocRef, activityData);

                // Clear the thought input field after submission
                setThoughtText("");
                setWordCount(0);

                // Notify the parent component of the new thought activity
                onNewThought(activityData);

            } catch (error) {
                console.log('Error adding document:', error);
            }
        }
    };

    return (
        <div className="thought-page">
            {user.username === username &&
            <div>
                <textarea
                    placeholder="Share a Thought..."
                    value={thoughtText}
                    onChange={handleThought}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    className={`thought-input ${isExpanded ? 'expanded' : ''}`}
                    maxLength={3000} // Limit to prevent too large of a text
                />
                <br />
                {isExpanded && (
                    <div>
                        {showWarning && <p className="warning-text">⚠️ You have exceeded the 300-word limit!</p>}
                        <button className="submit-button" onClick={handleSubmit} disabled={wordCount > 300}>
                            Share
                        </button>
                    </div>
                )}
            </div>
            }
        </div>
    );
};
