import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LeftSide } from "../../../app/leftSide/leftSide";
import { RightSide } from "../../../app/rightSide/rightSide";
import { selectGenres, selectWritePrompt } from "./writePromptSlice";
import { firestore } from "../../../firebase/firebase";
import { doc, setDoc, collection } from "firebase/firestore";
import { Link, useNavigate } from "react-router-dom";
import './writePrompt.css';
import { TandC } from "./T&C";
import JsonToReact from "../../../utilities/jsonToReact";
import { setActivity, setStories } from "../../../app/appSlice";
import { GenreSelector } from "../../../utilities/genreSelector/genreSelector";

export const WritePrompt = () => {
    const prompt = useSelector(selectWritePrompt);
    const [title, setTitle] = useState('');
    const [story, setStory] = useState('');
    const [wordCount, setWordCount] = useState(0);
    const [contentWarnings, setContentWarnings] = useState([]);
    const [showContentWarnings, setShowContentWarnings] = useState(false);
    const [showGuidelines, setShowGuidelines] = useState(false);
    const [agreeToTerms, setAgreeToTerms] = useState(false);
    const [openToRatings, setOpenToRatings] = useState(false);
    const [termsPopup, setTermsPopup] = useState(false);
    const navigate = useNavigate();
    const [wordCountMax, setWordCountMax] = useState(0);
    const [wordCountMin, setWordCountMin] = useState(0);

    const dispatch = useDispatch();
    const {activity, stories, user} = useSelector(state=>state.app);
    const genres = useSelector(selectGenres)

    useEffect(()=>{
        window.scrollTo(0,0)
        if(!prompt){
            navigate('/prompt-generators')
        }

    },[navigate, prompt]);
    
    const handleTitle = ({ target }) => {
        setTitle(target.value);
    };


    const handleStory = ({ target }) => {
        const storyText = target.value;
        const wordCount = storyText.trim().split(/\s+/).length;
        if (wordCount <= wordCountMax) {
            setStory(storyText);
            setWordCount(wordCount);
        }
    };

    const handleContentWarnings = ({ target }) => {
        const selectedWarning = target.value;
        setContentWarnings(prevWarnings => {
            if (prevWarnings.includes(selectedWarning)) {
                return prevWarnings.filter(warning => warning !== selectedWarning);
            } else {
                return [...prevWarnings, selectedWarning];
            }
        });
    };

    const writeStory = async () => {
        const newDocRef = doc(collection(firestore, `stories`));
        const storyUid = newDocRef.id;

        const docData = {
            title: title,
            genres: genres,
            story: story,
            contentWarnings: contentWarnings,
            prompt: prompt.prompt,
            promptType: prompt.type,
            promptId: prompt.promptId ? prompt.promptId : '',
            user: user.username,
            author: user.penName ? user.penName: user.username,
            time: new Date().toISOString(),
            uid: storyUid,
            views: 0,
            judged: false,
            score: 0,
            contest: prompt.contest? prompt.contest : '',
            contestId: prompt.contestId ? prompt.contestId : '',
            flags: [],
            wordCount: wordCount,
            rateable: openToRatings,
            universe: '',
            comments: [],
        };

        try {
            await setDoc(newDocRef, docData);
            const updatedStories = [...stories, docData]; // Add new activity to existing activities
            dispatch(setStories(updatedStories)); 
            console.log('Story document written with ID: ', storyUid);
        } catch (error) {
            console.log('Error adding document:', error);
        }
        
        const addActivity = async (action) => {
            const newDocRef = doc(collection(firestore, `activity`));
            const uid = newDocRef.id;
            const docData = {
                user: user.username,
                username: user.username,
                action: action,
                title: title,
                time: new Date().toISOString(),
                uid: uid,
                author: user.penName ? user.penName: user.username,
                storyId: storyUid,
                type: 'story',
                prompt: prompt.prompt,
                promptType: prompt.type,
                actId: storyUid,
                contest: prompt.contest? prompt.contest : '',
                contestId: prompt.contestId ? prompt.contestId : '',

            };
    
            try {
                await setDoc(newDocRef, docData);
                const updatedActivities = [...activity, docData]; // Add new activity to existing activities
                dispatch(setActivity(updatedActivities)); 
                console.log('Activity document written with ID: ', uid);
            } catch (error) {
                console.log('Error adding document:', error);
            }
        };

        addActivity('submitted');
    };

    

    const onSubmit = () => {
        if (!agreeToTerms) {
            alert("Please agree to the terms and conditions before submitting.");
            return;
        };
        if (title==='') {
            alert("Please give your story a title.");
            return;
        };
        if (genres.length<1) {
            alert("Please select 1-2 genres.");
            return;
        };
        writeStory();
        
        navigate(`/${user.username}`);
    };

    const genreOptions = [
        "science fiction", "fantasy", "mystery", "thriller", "adventure", "horror",
        "historical fiction", "western",
        "literary", "romance","dystopian", "steampunk",
        "space opera", "first contact", 
        "post-apocalyptic", "alternative history", "epic", "urban fantasy", "magical realism", 
        "cyberpunk", "superhero",
         "satire", "heist", 'spy'
    ];

    const contentWarningOptions = [
        "Violence", "Profanity", "Sexual Content", "Drug Use", "Self-Harm",
        "Suicide", "Child Abuse", "Animal Abuse", "Racism", "Homophobia",
        "Transphobia", "Ableism", "Misogyny"
    ];

    const findWordCount = (rank) => {
        switch (rank) {
            case 'Master':
                setWordCountMax(5000)
                break;
            case 'Wordsmith':
                    setWordCountMax(4000)
                break;
            case 'Storyteller':
                    setWordCountMax(3000)
                break;
            case 'Scribe':
                    setWordCountMax(2500)
                break;
            case 'Journeyman':
                    setWordCountMax(2000)
                break;
            case 'Apprentice':
                    setWordCountMax(1750)
                break;
            case 'Scribbler':
                    setWordCountMax(1500)
                break;
            default:
                setWordCountMax(1250)
        }
    }

    useEffect(() => {
        if (user && wordCountMax === 0) {
            findWordCount(user.rank); // Ensure this doesn't trigger re-renders unless necessary
        }
    }, [user, wordCountMax]);
    
    useEffect(() => {
        setWordCountMin(prompt.minWordCount || 250); // Set default if minWordCount doesn't exist
    }, [prompt.minWordCount]);
    
    useEffect(() => {
        if (prompt.maxWordCount) {
            setWordCountMax(prompt.maxWordCount);
        }
    }, [prompt.maxWordCount]);


    return (
        <div className="page">
            <LeftSide />
            <div className='content'>
                <header><h1>Write Your Story</h1></header>
                <div className='prompt-div'>
                    {prompt.prompt[0]==='{' ? JsonToReact({ jsonString: prompt.prompt }) : prompt.prompt}
                </div>
                <div className='genres-div'>
                    <label><h3>Select 1-2 Genres</h3></label>
                    <GenreSelector genres={genreOptions} iconStyle={{width: '80px'}}/>
                </div>
                <div className='guidelines'>
                    <label onClick={() => setShowGuidelines(!showGuidelines)} style={{ cursor: 'pointer' }}>
                        <h3>Submission Guidelines ▼</h3>
                    </label>
                    {showGuidelines && 
                        <div className="guidelines-text">
                            <ul>
                                <li>
                                    <p><strong>Write Safely: </strong> We recommend writing your story in a word processor first, then copying and pasting it here. If you write directly on this page and it reloads, you'll lose your story!</p>
                                </li>
                                <li>
                                    <p><strong>Originality: </strong> Only submit original work; don't try to be someone you're not.</p>
                                </li>
                                <li>
                                    <p><strong>Avoid AI Assistance: </strong> Please do not use generative AI to write your stories. I mean, what would be the point?</p>
                                </li>
                                <li>
                                    <p><strong>Prompt Relevance: </strong> It is my opinion that the prompt should be viewed in service to the story, not the other way around. The story should hold up without knowledge of the prompt.</p>
                                </li>
                                <li>
                                    <p><strong>Formatting Tips: </strong> Format your story clearly, using proper paragraph breaks and punctuation.</p>
                                </li>
                                <li>
                                    <p><strong>Content Warnings: </strong> Be sure to select the appropriate content warnings at the bottom of this page. Make this a chill place for everybody.</p>
                                </li>
                                <li>
                                    <p><strong>Word Count Guidelines: </strong> Unless otherwise specified by contest rules, aim for a word count between 250 words and the maximum allowed by your rank.</p>
                                </li>
                                <li>
                                    <p><strong>Check Contest Eligibility: </strong> Before submitting, visit the contests page to see if your story qualifies for any open contests. By agreeing to our contest terms and conditions, your story will be open to being rated by other users.</p>
                                    <p>If you submit more than one story for the same contest, only your most recent submission will be considered.</p>
                                </li>
                                <li>
                                    <p><strong>Engage with the Community: </strong> We encourage you to read, rate and comment on other submissions. Engaging with the community not only fosters a supportive environment but it will increase the reach of your own stories. Story influence is also considered in contest judgments. Share your stories on social media to maximize visibility!</p>
                                </li>
                                <li>
                                    <p><strong>Need Help Writing? </strong>Check out our <Link to='/write-idea' className="link">7 step process</Link> for turning your idea into an awesome story!</p>
                                </li>
                            </ul>
                        </div>
                    }
                </div>
                <div className='title-input'>
                    <h3>Title </h3>
                    <input type='text' placeholder='Enter a title...' value={title} onChange={handleTitle}></input>
                </div>
                <div className='story-input'>
                    <h3>Your Story</h3>
                    <textarea
                        placeholder="Paste your story here..."
                        value={story}
                        onChange={handleStory}
                    />
                    <p className={`word-count ${wordCount > wordCountMax ? 'over-limit' : ''}`}>Word Count: {wordCount}/{wordCountMax}</p>
                </div>
                <div className='content-warnings'>
                    <label onClick={() => setShowContentWarnings(!showContentWarnings)} style={{ cursor: 'pointer' }}>
                        <h3>Content Warnings ▼</h3>
                    </label>
                    {showContentWarnings &&
                        <div className="genre-checkboxes">
                            {contentWarningOptions.map((warning, index) => (
                                <div key={index} className="checkbox-item">
                                    <label>
                                        <input
                                            type="checkbox"
                                            value={warning}
                                            checked={contentWarnings.includes(warning)}
                                            onChange={handleContentWarnings}
                                        />
                                        {warning}
                                    </label>
                                </div>
                            ))}
                        </div>
                    }
                </div>
                <div className="terms-ratings">
                    <div className="terms-conditions">
                    {termsPopup && (
                                <div className="popup">
                                    <div className="popup-content">
                                        <TandC />
                                        <button onClick={() => setTermsPopup(false)}>Close</button>
                                    </div>
                                </div>
                            )}
                        
                            <input
                                type="checkbox"
                                checked={agreeToTerms}
                                onChange={() => setAgreeToTerms(!agreeToTerms)}
                            />
                        <label>
                            I agree to the <span className="terms-link" onClick={() => setTermsPopup(true)}>terms and conditions</span>
                            
                        </label>
                    </div>
                    <div className="ratings-eligibility">
                        <label>
                            <input
                                type="checkbox"
                                checked={openToRatings}
                                onChange={() => setOpenToRatings(!openToRatings)}
                            />
                            I wish to have my story rateable and be eligible for contests
                        </label>
                    </div>
                </div>
                {user && agreeToTerms && wordCount >= wordCountMin && <button className="submit-button" onClick={onSubmit}>Submit</button>}
                
            </div>
            <RightSide />
        </div>
    );
};
