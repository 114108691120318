import React from "react"

export const TandC = () => {
    return (
        <div>
            <h1>Terms and Conditions for Submitting Stories</h1>

            <div class="section">
                <h2>1. Eligibility</h2>
                <ol>
                    <li>All story submissions must be original works created by the submitter.</li>
                    <li>Submissions that have been previously published or that have won any previous contests are not eligible for submission to Writician.com.</li>
                    <li>The submitter must be at least 18 years old or have parental consent to participate if under the age of 18.</li>
                </ol>
            </div>

            <div class="section">
                <h2>2. Submission Guidelines</h2>
                <ol>
                    <li>Stories must be submitted through the official Writician.com platform.</li>
                    <li>Submissions must adhere to the specified word count and genre requirements for each contest or call for submissions.</li>
                    <li>Content that is considered overtly offensive, discriminatory, or otherwise inappropriate will be disqualified and removed from the platform.</li>
                </ol>
            </div>

            <div class="section">
                <h2>3. Contest Participation</h2>
                <ol>
                    <li>By submitting a story for a contest, you agree to abide by the contest's specific rules, including deadlines, genre restrictions, and word count limits.</li>
                    <li>Each contest may have its own specific guidelines, which will be detailed on the contest page. Participants are responsible for reading and understanding these rules before submitting their story.</li>
                </ol>
            </div>

            <div class="section">
                <h2>4. Judging Criteria</h2>
                <ol>
                    <li>Submissions will be judged based on creativity, originality, creative adherence to the contest theme, and overall writing quality.</li>
                    <li>The judging panel's decisions are final and binding.</li>
                </ol>
            </div>

            <div class="section">
                <h2>5. Rights and Ownership</h2>
                <ol>
                    <li><strong>Original Ownership:</strong> You, the submitter, retain the copyright to your story. However, by submitting your story to Writician.com, you grant Writician.com a non-exclusive, worldwide, royalty-free license to publish, distribute, and promote your story on the Writician.com platform and related social media channels.</li>
                    <li><strong>First Publishing Rights:</strong> If your story is selected as a winner or receives a prize in a contest, you agree to sell the first publishing rights to Writician.com. This means that Writician.com will have the exclusive right to publish your story for the first time. After publication by Writician.com, all rights revert to you, except for the right to use the story in promotions related to the contest or Writician.com.</li>
                    <li><strong>Prize Acceptance:</strong> By accepting a prize for your story, you acknowledge that you have sold the first publishing rights to Writician.com as detailed above.</li>
                </ol>
            </div>

            <div class="section">
                <h2>6. Compensation</h2>
                <ol>
                    <li><strong>Winning Stories: </strong> If your story wins a contest, it may be sold by Writician.com as specified in the contest guidelines.</li>
                    <li><strong>Non-Winning Stories: </strong> If your story is not selected as a winner or if you submit it outside of a contest, it will only be used for publication on the Writician.com website and related social media channels. No additional compensation will be provided for these submissions.</li>
                </ol>
            </div>

            <div class="section">
                <h2>7. Moral Rights</h2>
                <ol>
                    <li>You retain the moral rights to your story, which include the right to be credited as the author. Writician.com will ensure that your name is clearly associated with your work wherever it is published on the platform.</li>
                </ol>
            </div>

            <div class="section">
                <h2>8. Withdrawal of Submission</h2>
                <ol>
                    <li>You may withdraw your story from consideration at any time before the contest submission deadline. To do so, you must contact Writician.com with a formal request to withdraw your story.</li>
                    <li>Once a contest has ended and winners are announced, stories selected for prizes cannot be withdrawn.</li>
                </ol>
            </div>

            <div class="section">
                <h2>9. Disqualification</h2>
                <ol>
                    <li>Writician.com reserves the right to disqualify any submission that does not comply with these terms and conditions or the specific contest rules.</li>
                    <li>Submissions that are found to be plagiarized or that violate the intellectual property rights of others will be disqualified and removed from the platform.</li>
                </ol>
            </div>

            <div class="section">
                <h2>10. Modifications to Terms</h2>
                <ol>
                    <li>Writician.com reserves the right to modify these terms and conditions at any time. Any changes will be communicated to submitters through the Writician.com platform or via email.</li>
                </ol>
            </div>

            <div class="section">
                <h2>11. Governing Law</h2>
                <ol>
                    <li>These terms and conditions are governed by and construed in accordance with the laws of the jurisdiction in which Writician.com operates. Any disputes arising from or in connection with these terms shall be subject to the exclusive jurisdiction of the courts in that jurisdiction.</li>
                </ol>
            </div>

            <p>By submitting a story to Writician.com, you acknowledge that you have read, understood, and agree to these terms and conditions.</p>
        </div>

    )
}
