// VerifyEmailScreen.js
import React, { useEffect, useState } from 'react';
import { getAuth, sendEmailVerification } from 'firebase/auth';
import { LeftSide } from '../../app/leftSide/leftSide';
import { RightSide } from '../../app/rightSide/rightSide';

const VerifyEmailScreen = ({ onVerified }) => {
  const auth = getAuth();
  const [isSending, setIsSending] = useState(false);
  const [message, setMessage] = useState('');

  useEffect(() => {
    const interval = setInterval(async () => {
      await auth.currentUser.reload();
      if (auth.currentUser.emailVerified) {
        onVerified(); // Callback to proceed if verified
      }
    }, 3000); // Check every 3 seconds
    return () => clearInterval(interval);
  }, [auth, onVerified]);

  const resendVerificationEmail = async () => {
    setIsSending(true);
    try {
      await sendEmailVerification(auth.currentUser);
      setMessage('Verification email sent! Please check your inbox.');
    } catch (error) {
      setMessage('Error sending verification email. Please try again.');
    } finally {
      setIsSending(false);
    }
  };

  return (
    <div className='page'>
        <LeftSide />
        <div className='content'>
            <header><h1>Please Verify Email</h1></header>
            <div className="verify-email-container">
                <p>We have sent a verification link to your email. Please check your inbox and click on the link to proceed.</p>
                {message && <p className="message">{message}</p>}
                <button onClick={resendVerificationEmail} disabled={isSending}>
                    {isSending ? 'Sending...' : 'Resend Verification Email'}
                </button>
            </div>           
        </div>
        <RightSide />
    </div>
  );
};

export default VerifyEmailScreen;
