import { createSlice } from "@reduxjs/toolkit";
import { enneagrams } from "../plotPrompt/descriptions";


const generate = () => {
    let character = {
        hairColor: [
            'black','blonde','gray','dyed','white','Blonde','Brunette','Red','Auburn',
            'Strawberry blonde','Copper','Dark blonde','Light brown','Golden','Platinum',
            'Ash blonde','Dark brown','Chestnut','Honey blonde','Caramel','Burgundy','Mahogany',
            'Violet','Blue', 'green',
        ],
        hairLength: [
            'long','short','medium-length','buzzed','balding','shoulder-length',
            'crew-cut','very long','thigh-length','chin-length','mid-back length',
    ],
        hairStyle: [
            'Spiked','Braided','Curly','Frizzy','Slicked','Unkempt','Dreaded','Straight','Messy',
            'Natural','Cornrowed', 'Afro', 'Top-knotted','Pony-tailed', 'Wavy','Straight','Straight',
                ],
        height: [
            'Very short','Short','Below average height', 'Average height','Above average height','Tall',
            'Very tall',
        ],
        weight: [
            'skinny','athletic','solid','obese','slim','average weight','stocky',
            'muscular','wiry','curvy','plus-sized','doughy','full-figured','compact','stout','ripped',
        ],
        look: [
            'Grizzled', 'Spry', 'Posh', 'Dirty', 'Youthful', 'Cute', 'Handsome', 'Beautiful', 'Ugly',
            'Weary', 'Rugged', 'Graceful', 'Vibrant', 'Sleek', 'Awkward', 'Rundown', 'Jittery', 
            'Sloppy', 'Disheveled', 'Dapper', 'Lively', 'Tidy', 'Elegant', 'Crisp', 
            'Dull', 'Exuberant', 'Meticulous', 'Shabby', 'Spirited', 'Neat', 
            'Glamorous', 'Unkempt', 'Animated', 'Pristine', 'Dingy', 'Unpolished', 'Careworn', 
            'Sophisticated', 'Untidy', 'Gleaming', 'Haggard', 'Chic', 'Bedraggled', 'Glossy', 
            'Fatigued', 'Grimy', 'Dashing', 'Snappy', 'Ragged', 'Polished', 'Frazzled', 
            'Spiffy', 'Frumpy', 'Glittering', 'Slouchy', 'Snazzy', 'Battered', 'Refined', 
            'Tousled', 'Wrinkled', 'Flashy', 'Disarrayed', 'Unblemished', 'Worn', 'Droopy', 
            'Slick', 'Slovenly', 'Buoyant', 'Rumpled', 'Clumsy', 'Clever', 'Saggy', 
            'Glistening', 'Bleary-eyed', 'Suave', 'Careless', 'Sparkling', 'Smart', 
            'Stained', 'Lustrous', 'Disorganized', 'Bright-eyed', 'Groomed', 'Mussy', 
            'Unruffled', 'Immaculate', 'Sharp', 'Frizzy', 'Fresh', 'Messy', 'Impeccable'
        ],
        skinColor: [
            'pale skin','tan skin','light brown skin','brown skin','dark brown skin','black skin'
        ],
        accessories: [
            'face tattoo', 'arm tattoos', 'body tattoos', 'expensive jewelry', 'cheap jewelry',
            'a purse', 'a handbag', 'a gun', 'a sword', 'a walking stick', 'a cane', 'a horse',
            'a pet dog', 'a robot companion', 'a pet monkey', 'a lapdog', 'a lap cat',
            'piercings', 'sunglasses', 'gloves', 'a hat', 'a scarf', 'a belt', 'a wristwatch',
            'headphones', 'a bandana', 'hair accessories', 'a necklace', 'bracelets', 'rings',
            'a pocket watch', 'a backpack', 'an umbrella', 'a cigarette holder', 'an eyepatch', 
            'a cape', 'a mask', 'temporary tattoos', 'boots', 'sandals', 'a fanny pack',
            'a utility pouch', 'a hoverboard', 'a pet snake', 'a camera', 'a flower crown',
            'fingerless gloves', 'chains', 'walking boots', 'a medallion',
            'a walking stick', 'a leather jacket', 'a trench coat', 'a brooch', 'a tiara', 
            'a locket', 'a monocle', 'a cloak', 'a whistle', 'an amulet', 'a tool belt', 
            'a pet bird', 'a pocket knife', 'a wristband', 'a pendant', 'a bracelet', 
            'a flask', 'combat boots', 'a scarf', 'a helmet', 'a hairpin'
        ],
        
        defining: [
            'a nose wart', 'a long nose', 'a pot belly', 'a face scar', 'one hand', 
            'an eye patch', 'burn scars on arms', 'a nose ring', 'a bushy beard', 
            'a magician’s beard', 'a goatee', 'beads in their hair', 'a hidden birthmark', 
            'a visible birthmark', 'yellow teeth', 'a beautiful smile', 'sparkling eyes', 
            'a monocle', 'eye glasses', 'bushy eyebrows', 'large ears', 'a pointed nose', 
            'missing teeth', 'resting scowl face', 'a cane', 'a long beard', 'a mustache', 
            'eye-liner', 'lots of makeup', 'lipstick', 'earrings',
            'a crooked nose', 'dimples', 'rosy cheeks', 'a cleft chin', 'a missing ear', 
            'a scarred lip', 'a tattooed face', 'freckles', 'a prominent jawline', 
            'balding head', 'a widow’s peak', 'sharp cheekbones', 'a double chin', 
            'pierced eyebrows', 'a broken nose', 'pierced lips', 'a hunched back', 
            'pierced tongue', 'heavy eyelids'
        ],
        
    enneagram: [
        "reformer",
        "helper",
        "achiever",
        "individualist",
        "investigator",
        "loyalist",
        "enthusiast",
        "challenger",
        "peacemaker"
    ],
    archetype: [
        "innocent",
        "orphan",
        "hero",
        "caregiver",
        "explorer",
        "rebel",
        "lover",
        "creator",
        "jester",
        "sage",
        "magician",
        "ruler"
    ],

    species: ['human','elf','dwarf','troll','gray alien','reptillian humanoid','feline humanoid',
    'canine humanoid','crustacean humanoid','avian humanoid',
    'vampire','werewolf','ghost','demon','demigod','angel','robot','A.I.', 'immortal','celestial',
    'humanoid alien','shape-shifter',
    'ogre','orc','gnome','sprite','spirit'
],
    }

    const random00 = Math.floor(Math.random() * character.hairColor.length);
    const random01 = Math.floor(Math.random() * character.hairLength.length);
    const random02 = Math.floor(Math.random() * character.hairStyle.length);
    const random03 = Math.floor(Math.random() * character.height.length);
    const random04 = Math.floor(Math.random() * character.weight.length);
    const random05 = Math.floor(Math.random() * character.look.length);
    const random06 = Math.floor(Math.random() * character.skinColor.length);
    const random07 = Math.floor(Math.random() * character.accessories.length);
    const random08 = Math.floor(Math.random() * character.defining.length);
    const random09 = Math.floor(Math.random() * character.enneagram.length);
    //const random10 = Math.floor(Math.random() * character.archetype.length);
    const random11 = Math.floor(Math.random() * character.species.length);

    
    let hairColor = character.hairColor[random00].toLowerCase();
    let hairLength = character.hairLength[random01].toLowerCase();
    let hairStyle = character.hairStyle[random02].toLowerCase();
    let height = character.height[random03].toLowerCase();
    let weight = character.weight[random04].toLowerCase();
    let look = character.look[random05].toLowerCase();
    let skinColor = character.skinColor[random06].toLowerCase();
    let accessories = character.accessories[random07].toLowerCase();
    let defining = character.defining[random08].toLowerCase();
    let enneagram = character.enneagram[random09].toLowerCase();
    //let archetype = character.archetype[random10].toLowerCase();
    let species = character.species[random11].toLowerCase();

    

    const items = {hairColor,hairLength,hairStyle,height,weight,look,skinColor,accessories,defining,enneagram,species};
    return items;

}

export const characterPromptSlice = createSlice({
    name: 'characterPrompt',
    initialState: {
        prompt: '',
        savedPrompts: [],
        hairColor: '',
        hc: true,
        hairLength: '',
        hl: true,
        hairStyle: '',
        hs: true,
        height: '',
        ht: true,
        weight: '',
        wt: true,
        skinColor: '',
        sc: true,
        look: '',
        lk: false,
        accessories: '',
        acc: false,
        defining:'',
        def: false,
        enneagram: '',
        enn: false,
        //archetype: '',
        //arch: true,
        species: '',
        spe: false,
        showEnneagram: ''
    },
    reducers :{
        charGen: (state) => {
            state.hairStyle = generate().hairStyle;
            state.hairLength = generate().hairLength;
            state.hairColor = generate().hairColor;
            state.height = generate().height;
            state.weight = generate().weight;
            state.skinColor = generate().skinColor;
            state.look = generate().look;
            state.accessories = generate().accessories;
            state.defining = generate().defining;
            state.enneagram = generate().enneagram;
            //state.archetype = generate().archetype;
            state.species = generate().species;
        },
        displayPrompt(state){
            const hairStyle = state.hairStyle;
            const hairLength = state.hairLength;
            const hairColor = state.hairColor;
            const height = state.height;
            const weight = state.weight;
            const skinColor = state.skinColor;
            const look = state.look;
            const accessories = state.accessories;
            const defining = state.defining;
            const enneagram = state.enneagram;
            //const archetype = state.archetype;
            const species = state.species;

            const hs = state.hs;
            const hl = state.hl;
            const hc = state.hc;
            const ht = state.ht;
            const wt = state.wt;
            const sc = state.sc;
            const lk = state.lk;
            const acc = state.acc;
            const def = state.def;
            const enn = state.enn;
            //const arch = state.arch;
            const spe = state.spe;

            function showEnneagram(){
                let description;
                    if(enneagram==='reformer'){
                        description = enneagrams.type1
                    }
                    if(enneagram==='helper'){
                        description = enneagrams.type2
                    }
                    if(enneagram==='achiever'){
                        description = enneagrams.type3
                    }
                    if(enneagram==='individualist'){
                        description = enneagrams.type4
                    }
                    if(enneagram==='investigator'){
                        description = enneagrams.type5
                    }
                    if(enneagram==='loyalist'){
                        description = enneagrams.type6
                    }
                    if(enneagram==='enthusiast'){
                        description = enneagrams.type7
                    }
                    if(enneagram==='challenger'){
                        description = enneagrams.type8
                    }
                    if(enneagram==='peacemaker'){
                        description = enneagrams.type9
                    }
                    return description;        
                } 
                
                const fill1 = (lk && (ht || wt)) ? ', ' : '';
                const fill2 = (ht && wt) ? ' and ' : '';
                const fill3 = (!enn && !spe) && (ht || wt || lk) ? ' with ' : ' ';
                const fill4 = ' '; // This remains a space
                const fill5 = (enn || spe) ? ' with ' : '';
                const fill6 = ((hs || hl || hc) && sc && ' and ') || ((!hs && !hl && !hc) && sc && ((def && !acc) || (acc && !def)) && ' and ');
                const fill7 = (hl || hc) ? ' ' : '';
                const fill8 = (hl && hc) ? ' ' : '';
                const fill9 = (hs || hl || hc) ? ' hair' : '';
                const fill10 = (hs || hl || hc) && (acc || def) && sc ? ' with ' : '';
                const fill11 = (acc && def && sc && (!hs && !hl && !hc)) || (acc && def && !sc && (hs || hl || hc)) ? ', ' : '';
                const fill12 = (sc && (!hs && !hl && !hc) && ((acc && !def) || (!acc && def))) || ((hs || hl || hc) && !sc && ((acc && !def) || (!acc && def))) ? ' and ' : '';
                const fill13 = acc && def ? ' and ' : '';


            state.prompt = 
               
                JSON.stringify(
                    <div className = 'text'>
                
                        {lk && look}{fill1}{ht && height}{fill2}{wt && weight}{fill3}
                        {enn && <b>{enneagram}<span>{showEnneagram()}</span></b>}{fill4}{spe && species}{fill5}
                        {sc && skinColor}{fill6}
                        {hs && hairStyle}{fill7}{hl && hairLength}{fill8}{hc && hairColor}{fill9}{fill10}{fill11}{fill12}
                        {def && defining}{fill13}{acc && accessories}

                    </div>)
              
        },

        selectElements: (state, action) => {
            if(action.payload==='hairStyle'){
                state.hs ? state.hs=false : state.hs=true;
            }
            if(action.payload==='hairLength'){
                state.hl ? state.hl=false : state.hl=true;
            }
            if(action.payload==='hairColor'){
                state.hc ? state.hc=false : state.hc=true;
            }
            if(action.payload==='height'){
                state.ht ? state.ht=false : state.ht=true;
            }
            if(action.payload==='weight'){
                state.wt ? state.wt=false : state.wt=true;
            }
            if(action.payload==='skinColor'){
                state.sc ? state.sc=false : state.sc=true;
            }
            if(action.payload==='look'){
                state.lk ? state.lk=false : state.lk=true;
            }
            if(action.payload==='accessories'){
                state.acc ? state.acc=false : state.acc=true;
            }
            if(action.payload==='defining'){
                state.def ? state.def=false : state.def=true;
            }
            if(action.payload==='enneagram'){
                state.enn ? state.enn=false : state.enn=true;
            }
            if(action.payload==='species'){
                state.spe ? state.spe=false : state.spe=true;
            }
        }
        

    }
})

export const selectCharacterPrompt = (state) => state.characterPrompt;
export const selectSavedPrompts = (state) => state.characterPrompt.savedPrompts;
export default characterPromptSlice.reducer;
export const { displayPrompt, selectElements, charGen } = characterPromptSlice.actions;



/*
masculine feminine neutral

name
species
hair
body type
look
skin tone
accessories
defining characteristic
enneagram
archetype

Your character is 
Name, a peacemaker ruler ghost who is body type with skin tone and hair and defining. Their look is look with accessories.
*/