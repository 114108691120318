// utilities/formatStoryText.js
import DOMPurify from 'dompurify';

export const formatStoryText = (text) => {
    if (!text) return "";

    // Split the text into paragraphs based on newlines
    const paragraphs = text.split(/\n+/);

    // Map through each paragraph, assigning unique IDs and wrapping in <p> tags
    const formattedText = paragraphs
        .map((para, index) => `<p id="para-${index}">${DOMPurify.sanitize(para)}</p>`)
        .join(''); // Join paragraphs back into one HTML string

    return formattedText;
};
