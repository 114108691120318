import React, { useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import "./openContests.css";
import { formatDate } from "../../../utilities/formatDate";

export const OpenContests = () => {
    const { contests } = useOutletContext();
    const navigate = useNavigate();

    const [currentIndex, setCurrentIndex] = useState(0);

    const handleContestClick = (contest) => {
        navigate(`/contests/${contest.id}`, { state: { contest } });
    };
    const openContests = contests.filter(c=> c.endDate > new Date().toISOString());

    const handleNavigation = (direction) => {
        if (direction === "next") {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % openContests.length);
        } else {
            setCurrentIndex((prevIndex) =>
                prevIndex === 0 ? contests.length - 1 : prevIndex - 1
            );
        }
    };

    return (
        <div className="open-contests-carousel">
            {openContests.length > 0 ? (
                <>
                    {openContests.length > 1 && (
                        <div
                            className="open-contests-prev"
                            onClick={() => handleNavigation("prev")}
                        >
                            ‹
                        </div>
                    )}
                    
                    <div className="open-contest">
                        <h3 className="storyTitle" onClick={() => handleContestClick(openContests[currentIndex])}>
                            {openContests[currentIndex].title}
                        </h3>
                        <p style={{textAlign: 'center'}}>{openContests[currentIndex].description}</p>
                        <strong>Prize: {openContests[currentIndex].prize}</strong>
                        {openContests[currentIndex].prizeImage && (
                            <img
                                id="prize-img"
                                src={openContests[currentIndex].prizeImage}
                                alt={openContests[currentIndex].prize}
                                loading="lazy"
                            />
                        )}
                        <p>
                            {openContests[currentIndex].startDate
                                ? formatDate(openContests[currentIndex].startDate)
                                : "N/A"}{" "}
                            to{" "}
                            {openContests[currentIndex].endDate
                                ? formatDate(openContests[currentIndex].endDate)
                                : "N/A"}
                        </p>
                    </div>

                    {openContests.length > 1 && (
                        <div
                            className="open-contests-next"
                            onClick={() => handleNavigation("next")}
                        >
                            ›
                        </div>
                    )}
                </>
            ) : (
                <p>No open contests available.</p>
            )}
        </div>
    );
};
