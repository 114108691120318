import { createSlice } from "@reduxjs/toolkit";
import { collection, getDocs } from "firebase/firestore";
import { firestore } from "../firebase/firebase";
import { getAuth } from "firebase/auth";

// Fetch App Data Function
export const fetchAppData = () => async (dispatch) => {
    dispatch(setLoading(true));
    try {
        const usersCollection = collection(firestore, "users");
        const storiesCollection = collection(firestore, "stories");
        const activityCollection = collection(firestore, "activity");
        const contestsCollection = collection(firestore, "contests");
        const followingCollection = collection(firestore, "following");
        const forumQuestionsCollection = collection(firestore, "forum-questions");
        const forumAnswersCollection = collection(firestore, "forum-answers");
        const forumCommentsCollection = collection(firestore, "forum-comments");
        const potdsCollection = collection(firestore, 'potds');
        const newsCollection = collection(firestore, 'news'); 
        const userPromptsCollection = collection(firestore, 'user-prompts');

        const [
            usersSnapshot,
            storiesSnapshot,
            activitySnapshot,
            contestsSnapshot,
            followingSnapshot,
            forumQuestionsSnapshot,
            forumAnswersSnapshot,
            forumCommentsSnapshot,
            potdsSnapshot,
            newsSnapshot,
            userPromptsSnapshot,
        ] = await Promise.all([
            getDocs(usersCollection),
            getDocs(storiesCollection),
            getDocs(activityCollection),
            getDocs(contestsCollection),
            getDocs(followingCollection),
            getDocs(forumQuestionsCollection),
            getDocs(forumAnswersCollection),
            getDocs(forumCommentsCollection),
            getDocs(potdsCollection),
            getDocs(newsCollection),
            getDocs(userPromptsCollection)
        ]);

        const users = usersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        const stories = storiesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        const activities = activitySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        const contests = contestsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        const following = followingSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        const forumQuestions = forumQuestionsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        const forumAnswers = forumAnswersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        const forumComments = forumCommentsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        const potds = potdsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        const news = newsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })); 
        const userPrompts = userPromptsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })); 


        // Dispatch actions to set state
        dispatch(setUsers(users));
        dispatch(setStories(stories));
        dispatch(setActivity(activities));
        dispatch(setContests(contests));
        dispatch(setFollowing(following));
        dispatch(setForumQuestions(forumQuestions));
        dispatch(setForumAnswers(forumAnswers));
        dispatch(setForumComments(forumComments));
        dispatch(setPotds(potds));
        dispatch(setNews(news)); 
        dispatch(setUserPrompts(userPrompts))

        const { currentUser } = getAuth();
        if (currentUser) {
            const userData = users.find(user => user.username === currentUser.displayName);
            dispatch(setUser(userData));
        }

    } catch (error) {
        console.error("Failed to fetch app data:", error);
        dispatch(setError(error.message || "Failed to fetch app data."));
    } finally {
        dispatch(setLoading(false));
    }
};

// Redux Slice
export const appSlice = createSlice({
    name: 'app',
    initialState: {
        users: [],
        stories: [],
        activity: [],
        contests: [],
        following: [],
        forumQuestions: [],
        forumAnswers: [],
        forumComments: [],
        potds: [],
        news: [], 
        userPrompts: [],
        user: null,
        isLoading: false,
        error: null,
    },
    reducers: {
        setUsers: (state, action) => {
            state.users = action.payload;
        },
        setStories: (state, action) => {
            state.stories = action.payload;
        },
        setActivity: (state, action) => {
            state.activity = action.payload;
        },
        setContests: (state, action) => {
            state.contests = action.payload;
        },
        setFollowing: (state, action) => {
            state.following = action.payload;
        },
        setUser: (state, action) => {
            state.user = action.payload;
        },
        setForumQuestions: (state, action) => {
            state.forumQuestions = action.payload;
        },
        setForumAnswers: (state, action) => {
            state.forumAnswers = action.payload;
        },
        setForumComments: (state, action) => {
            state.forumComments = action.payload;
        },
        setPotds: (state, action) => {
            state.potds = action.payload;
        },
        setNews: (state, action) => { 
            state.news = action.payload;
        },
        setUserPrompts: (state, action) => { 
            state.userPrompts = action.payload;
        },
        setLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setError: (state, action) => {
            state.error = action.payload;
        }
    }
});

// Actions
export const { setUsers, setStories, setActivity, setContests, setUser, setLoading, setError, setFollowing, setForumQuestions, setForumAnswers, setForumComments, setPotds, setNews, setUserPrompts } = appSlice.actions;

export default appSlice.reducer;
