// src/components/AdminNews.js
import React, { useState } from "react";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import "./adminNews.css"; // Add styling as needed                      
import { addDoc, collection } from "firebase/firestore";
import { firestore } from "../../../firebase/firebase";

export const AdminNews = () => {
    const [title, setTitle] = useState("");
    const [content, setContent] = useState("");
    const [link, setLink] = useState("");
    const [image, setImage] = useState(null); // State for the optional image file
    const [isSubmitting, setIsSubmitting] = useState(false);

    // Handle image file change
    const handleImageChange = (e) => {
        if (e.target.files[0]) {
            setImage(e.target.files[0]);
        }
    };

    // Upload image to Firebase Storage and get URL
    const uploadImage = async () => {
        if (image) {
            const storage = getStorage();
            const imageRef = ref(storage, `news-images/${image.name}`);
            await uploadBytes(imageRef, image);
            const url = await getDownloadURL(imageRef);
            return url;
        }
        return "";
    };

    // Handle news submission
    const handleAddNews = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        try {
            // Upload image and get its URL
            const imageUrl = await uploadImage();
            const newsCollection = collection(firestore, "news");

            // Add news document to Firestore
            await addDoc(newsCollection, {
                title,
                content,
                link,
                imageUrl,
                date: new Date().toISOString(),
            });

            alert("News item added successfully!");
            
            // Clear fields after successful submission
            setTitle("");
            setContent("");
            setLink("");
            setImage(null);
        } catch (error) {
            console.error("Error adding news item:", error);
            alert("Failed to add news item");
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div className="admin-news">
            <h1>Add News Article</h1>
            <form onSubmit={handleAddNews}>
                <div className="form-group">
                    <label htmlFor="title">Title:</label>
                    <input
                        id="title"
                        type="text"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="content">Content:</label>
                    <textarea
                        id="content"
                        value={content}
                        onChange={(e) => setContent(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="link">Link (optional):</label>
                    <input
                        id="link"
                        type="text"
                        value={link}
                        onChange={(e) => setLink(e.target.value)}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="image">Image (optional):</label>
                    <input
                        id="image"
                        type="file"
                        accept="image/*"
                        onChange={handleImageChange}
                    />
                </div>
                <button type="submit" disabled={isSubmitting}>
                    {isSubmitting ? "Saving..." : "Add News"}
                </button>
            </form>
        </div>
    );
};

export default AdminNews;
