import React from "react";
import { NavLink } from "react-router-dom";
import { useEffect } from "react";

export const Themes = () => {

    useEffect(()=>{
        window.scrollTo(0,0)
    },[])
    
    return (
        <div>
            <header><h1>Themes</h1></header>
            <NavLink to='/story-elements/craft-elements'><button id='button3'>Back</button></NavLink>
            <br/>
            <br/>
            <div className='genres' id='themeList'>
            
                    <div className="genre">
                        <li>Love and Loss</li>
                        <p><em>Description:</em> Exploration of the profound emotions associated with romantic relationships and the pain of separation.</p>
                    </div>

                    <div className="genre">
                        <li>Justice</li>
                        <p><em>Description:</em> Examining the concepts of fairness, morality, and the pursuit of just outcomes in society.</p>
                    </div>

                    <div className="genre">
                        <li>Redemption</li>
                        <p><em>Description:</em> The theme of finding forgiveness and salvation, often through personal growth and positive actions.</p>
                    </div>

                    <div className="genre">
                        <li>Survival</li>
                        <p><em>Description:</em> Exploration of the instinct and challenges associated with the basic human drive to survive against adversity.</p>
                    </div>

                    <div className="genre">
                        <li>War</li>
                        <p><em>Description:</em> Examination of the multifaceted aspects of war, including its glory, necessity, pain, and tragic consequences.</p>
                    </div>

                    <div className="genre">
                        <li>True Love</li>
                        <p><em>Description:</em> Exploration of the pure and enduring nature of genuine, unconditional love between individuals.</p>
                    </div>

                    <div className="genre">
                        <li>Heroism</li>
                        <p><em>Description:</em> Depiction of acts of bravery, selflessness, and courage in the face of adversity.</p>
                    </div>

                    <div className="genre">
                        <li>The Nature of Good and Evil</li>
                        <p><em>Description:</em> Philosophical exploration of morality, ethics, and the inherent nature of good and evil in human beings.</p>
                    </div>

                    <div className="genre">
                        <li>Circle of Life</li>
                        <p><em>Description:</em> Reflection on the cyclical nature of life, including birth, growth, death, and rebirth.</p>
                    </div>

                    <div className="genre">
                        <li>Coming of Age</li>
                        <p><em>Description:</em> Portrayal of characters' journeys from youth to adulthood, often involving self-discovery and maturation.</p>
                    </div>

                    <div className="genre">
                        <li>Sacrifice</li>
                        <p><em>Description:</em> Exploration of the profound act of giving up something valuable for the greater good or the well-being of others.</p>
                    </div>

                    <div className="genre">
                        <li>Deception</li>
                        <p><em>Description:</em> Examination of the consequences and complexities surrounding deceit, lies, and manipulation.</p>
                    </div>

                    <div className="genre">
                        <li>Courage and Perseverance</li>
                        <p><em>Description:</em> Depiction of characters facing adversity with bravery, resilience, and determination.</p>
                    </div>

                    <div className="genre">
                        <li>Betrayal and Revenge</li>
                        <p><em>Description:</em> Exploration of the themes of betrayal, the desire for revenge, and the consequences of seeking vengeance.</p>
                    </div>

                    <div className="genre">
                        <li>Forbidden Romance</li>
                        <p><em>Description:</em> Exploration of romantic relationships that face societal or personal obstacles, often resulting in conflict or tragedy.</p>
                    </div>

                    <div className="genre">
                        <li>Spiritual Enlightenment</li>
                        <p><em>Description:</em> Journey toward spiritual awakening, understanding, and enlightenment often involving self-discovery and introspection.</p>
                    </div>

                    <div className="genre">
                        <li>Mysticism</li>
                        <p><em>Description:</em> Exploration of mysterious and supernatural elements that go beyond the boundaries of the known world.</p>
                    </div>

                    <div className="genre">
                        <li>Faith vs Doubt</li>
                        <p><em>Description:</em> Philosophical exploration of the conflict between belief and skepticism, often in religious or existential contexts.</p>
                    </div>

                    <div className="genre">
                        <li>Heartbreak</li>
                        <p><em>Description:</em> Portrayal of the intense emotional pain and sorrow associated with the loss of love or shattered expectations.</p>
                    </div>

                    <div className="genre">
                        <li>Order vs Chaos</li>
                        <p><em>Description:</em> Exploration of the dichotomy between order and chaos in society, often reflecting on the consequences of each.</p>
                    </div>
                
                    <div className="genre">
                    <li>Nature of Time</li>
                    <p><em>Description:</em> Reflection on the abstract and philosophical aspects of time, its passage, and its impact on human experience.</p>
                    </div>

                    <div className="genre">
                    <li>Change vs Tradition</li>
                    <p><em>Description:</em> Exploration of the tension between embracing change and preserving traditional values and practices.</p>
                    </div>

                    <div className="genre">
                    <li>Death and Dying</li>
                    <p><em>Description:</em> Examination of the themes of mortality, the inevitability of death, and the emotions associated with dying.</p>
                    </div>

                    <div className="genre">
                    <li>Empowerment</li>
                    <p><em>Description:</em> Depiction of individuals gaining strength, confidence, and control over their lives and circumstances.</p>
                    </div>

                    <div className="genre">
                    <li>Emptiness of Attaining a False Dream</li>
                    <p><em>Description:</em> Exploration of the consequences and disillusionment that may follow the pursuit of unfulfilling or illusory goals.</p>
                    </div>

                    <div className="genre">
                    <li>Facing Darkness</li>
                    <p><em>Description:</em> Characters confronting inner or external darkness, representing challenges, fears, or the unknown.</p>
                    </div>

                    <div className="genre">
                    <li>Fate and Free Will</li>
                    <p><em>Description:</em> Philosophical exploration of the interplay between predetermined destiny and the ability to make choices.</p>
                    </div>

                    <div className="genre">
                    <li>Fear of Failure</li>
                    <p><em>Description:</em> Examination of the emotions and struggles associated with the fear of not meeting expectations or succeeding.</p>
                    </div>

                    <div className="genre">
                    <li>Greed as Downfall</li>
                    <p><em>Description:</em> Exploration of the destructive consequences of excessive desire for wealth, power, or possessions.</p>
                    </div>

                    <div className="genre">
                    <li>Identity Crisis</li>
                    <p><em>Description:</em> Portrayal of characters grappling with uncertainty or confusion about their personal identity, values, or purpose.</p>
                    </div>

                    <div className="genre">
                    <li>Illusion of Power</li>
                    <p><em>Description:</em> Exploration of the deceptive nature of authority and control, and the illusion that power guarantees fulfillment.</p>
                    </div>

                    <div className="genre">
                    <li>Immortality</li>
                    <p><em>Description:</em> Examination of the consequences, challenges, and existential questions related to eternal life or immortality.</p>
                    </div>

                    <div className="genre">
                    <li>Injustice & Oppression</li>
                    <p><em>Description:</em> Exploration of social and political injustice, oppression, and the fight for equality and human rights.</p>
                    </div>

                    <div className="genre">
                    <li>Knowledge vs Ignorance</li>
                    <p><em>Description:</em> Philosophical exploration of the contrast between wisdom and ignorance, and the impact of knowledge on individuals and societies.</p>
                    </div>

                    <div className="genre">
                    <li>Escaping Poverty</li>
                    <p><em>Description:</em> Narratives focusing on characters striving to overcome economic challenges and improve their socioeconomic status.</p>
                    </div>

                    <div className="genre">
                    <li>Man vs Wild</li>
                    <p><em>Description:</em> Exploration of the primal struggle between humanity and the untamed forces of nature.</p>
                    </div>

                    <div className="genre">
                    <li>Materialism as Downfall</li>
                    <p><em>Description:</em> Examination of the negative consequences of prioritizing material possessions and wealth over personal well-being or relationships.</p>
                    </div>

                    <div className="genre">
                    <li>Manipulation</li>
                    <p><em>Description:</em> Exploration of characters employing cunning and deceit to influence or control others for personal gain.</p>
                    </div>

                    <div className="genre">
                    <li>Loneliness</li>
                    <p><em>Description:</em> Portrayal of the emotional and existential challenges associated with isolation and the search for connection.</p>
                    </div>

                    <div className="genre">
                    <li>Losing Hope</li>
                    <p><em>Description:</em> Exploration of characters experiencing a profound loss of optimism and belief in a positive outcome.</p>
                    </div>

                
                    <div className="genre">
                    <li>Loss of Innocence</li>
                    <p><em>Description:</em> Depiction of the transformative journey from naivety to awareness and the inevitable loss of innocence.</p>
                    </div>

                    <div className="genre">
                    <li>Lost Honor</li>
                    <p><em>Description:</em> Examination of the consequences and journey associated with the loss of one's honor or reputation.</p>
                    </div>

                    <div className="genre">
                    <li>The Will to Survive</li>
                    <p><em>Description:</em> Exploration of the instinct and challenges associated with the basic human drive to survive against adversity.</p>
                    </div>

                    <div className="genre">
                    <li>Nationalism</li>
                    <p><em>Description:</em> Exploration of themes related to national identity, pride, and the impact of patriotism on individuals and societies.</p>
                    </div>

                    <div className="genre">
                    <li>Speaking Truth to Power</li>
                    <p><em>Description:</em> Portrayal of individuals challenging authority or systems of oppression by voicing inconvenient truths.</p>
                    </div>

                    <div className="genre">
                    <li>Power of Magic</li>
                    <p><em>Description:</em> Exploration of supernatural elements and the transformative power of magic within a narrative context.</p>
                    </div>

                    <div className="genre">
                    <li>Power and Corruption</li>
                    <p><em>Description:</em> Examination of the corrupting influence of power and the ethical dilemmas associated with positions of authority.</p>
                    </div>

                    <div className="genre">
                    <li>Reunion</li>
                    <p><em>Description:</em> Narratives exploring the emotional and often transformative reunions of characters who were separated or estranged.</p>
                    </div>

                    <div className="genre">
                    <li>Power of Words</li>
                    <p><em>Description:</em> Portrayal of the influential and transformative nature of language, communication, and storytelling.</p>
                    </div>

                    <div className="genre">
                    <li>Self Reliance</li>
                    <p><em>Description:</em> Exploration of characters relying on their own abilities and inner strength to navigate challenges and achieve goals.</p>
                    </div>

                    <div className="genre">
                    <li>Social Mobility</li>
                    <p><em>Description:</em> Examination of characters' efforts to improve their social and economic status, often through upward mobility.</p>
                    </div>

                    <div className="genre">
                    <li>Role of Technology in Society</li>
                    <p><em>Description:</em> Exploration of the impact of technological advancements on individuals, societies, and ethical considerations related to technology.</p>
                    </div>

                    <div className="genre">
                    <li>Totalitarianism</li>
                    <p><em>Description:</em> Portrayal of societies under oppressive and authoritarian rule, exploring the consequences on individuals and resistance movements.</p>
                    </div>

                    <div className="genre">
                    <li>Temptation and Destruction</li>
                    <p><em>Description:</em> Examination of characters succumbing to temptation and the destructive consequences that follow.</p>
                    </div>

                    <div className="genre">
                    <li>Vanity as Downfall</li>
                    <p><em>Description:</em> Exploration of the negative consequences associated with excessive pride, narcissism, and a focus on personal appearance.</p>
                    </div>

                    <div className="genre">
                    <li>Vulnerability of the Strong</li>
                    <p><em>Description:</em> Narratives exploring the vulnerability and humanity within seemingly strong or powerful characters.</p>
                    </div>

                    <div className="genre">
                    <li>Temporary Nature of Beauty</li>
                    <p><em>Description:</em> Reflection on the fleeting and ephemeral nature of beauty, emphasizing its transience and impermanence.</p>
                    </div>

                    <div className="genre">
                    <li>Working Class Struggles</li>
                    <p><em>Description:</em> Exploration of the challenges, hardships, and resilience of individuals within the working class.</p>
                    </div>

                    <div className="genre">
                    <li>Winning Against All Odds</li>
                    <p><em>Description:</em> Portrayal of characters overcoming seemingly insurmountable challenges and achieving success against the odds.</p>
                    </div>

                    <div className="genre">
                    <li>Overcoming Fear, Weakness, Vice</li>
                    <p><em>Description:</em> Exploration of characters' journeys to conquer personal fears, weaknesses, or vices for personal growth and redemption.</p>
                    </div>
               
            </div>
        </div>
    )
}