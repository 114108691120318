import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCheck, faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { firestore } from "../../firebase/firebase";
import { doc, setDoc, deleteDoc, collection, query, where, getDocs } from "firebase/firestore";
import './profHeader.css';
import { useDispatch } from "react-redux";
import { setActivity, setFollowing } from "../../app/appSlice";
import { UserPoints } from "../../utilities/updateUserPoints";

// Function to generate a random cool color in HSL format
function getRandomCoolColor() {
  const hue = Math.floor(Math.random() * 120) + 180; // Cool colors range from 180 to 300 in HSL
  const saturation = Math.floor(Math.random() * 40) + 60; // 60-100% saturation for vivid colors
  const lightness = Math.floor(Math.random() * 20) + 40; // 40-60% lightness for balanced brightness
  return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
}

function getRandomWarmColor() {
  const hue = Math.floor(Math.random() * 60); // Warm colors range from 0 to 60 in HSL
  const saturation = Math.floor(Math.random() * 40) + 60; // 60-100% saturation for vivid colors
  const lightness = Math.floor(Math.random() * 20) + 40; // 40-60% lightness for balanced brightness
  return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
}

// Function to apply a random gradient background to the prof-header
function applyRandomGradient() {
  const color1 = getRandomCoolColor();
  const color2 = getRandomCoolColor();
  const color3 = getRandomCoolColor();
  const color4 = getRandomWarmColor();
  const profHeader = document.getElementById('prof-header');
  profHeader.style.background = `linear-gradient(135deg, ${color1}, ${color2})`;

  const profImg = document.getElementById('profile-img');
  profImg.style.border = '10px inset';
  profImg.style.borderImage = `linear-gradient(135deg, ${color3}, ${color2}) 1`;

  const statline = document.getElementById('statline');
  statline.style.color = `${color4}`;
}

export const ProfHeader = ({ user, following, profile, activity }) => {
  const [isFollowing, setIsFollowing] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (user && following) {
      const isUserFollowing = following.some(
        pair => pair.follower === user.username && pair.followed === profile.username
      );
      setIsFollowing(isUserFollowing);
    }

    applyRandomGradient();
  }, [user, profile, following]);

  const handleFollow = async () => {
    if (!user || !profile) return;

    const followPair = { follower: user.username, followed: profile.username };

    if (!isFollowing) {
      // Follow logic
      const followingRef = doc(collection(firestore, 'following'));
      await setDoc(followingRef, followPair);
      // Update local state immediately after following
      setIsFollowing(true);
      dispatch(setFollowing([...following, { ...followPair, id: followingRef.id }])); // Include ID in local following state
      await addActivity('followed', followingRef.id);
    } else {
      // Unfollow logic
      const followedPair = following.find(
        pair => pair.follower === user.username && pair.followed === profile.username
      );

      await deleteDoc(doc(firestore, 'following', followedPair.id)); // Use the correct document ID
      // Update local state immediately after unfollowing
      setIsFollowing(false);
      const updatedFollowing = following.filter(
        pair => pair.id !== followedPair.id
      );
      dispatch(setFollowing(updatedFollowing)); // Dispatch updated following state
      await removeActivity(followedPair.id);
    }
  };

  const addActivity = async (actionType, id) => {
    if (!user) return;

    const newDocRef = doc(collection(firestore, 'activity'));
    const docData = {
      type: 'follow',
      action: actionType,
      user: user.username,
      username: profile.username,
      time: new Date().toISOString(),
      uid: newDocRef.id,
      actId: id,
    };

    try {
      await setDoc(newDocRef, docData);
      dispatch(setActivity([...activity, docData]));
    } catch (error) {
      console.error("Error adding activity:", error);
    }
  };

  const removeActivity = async (id) => {
    try {
      const activityQuery = query(
        collection(firestore, 'activity'),
        where('actId', '==', id)
      );
      const activitySnapshot = await getDocs(activityQuery);

      if (!activitySnapshot.empty) {
        const activityDocId = activitySnapshot.docs[0].id;
        await deleteDoc(doc(firestore, 'activity', activityDocId));
        dispatch(setActivity(activity.filter(act => act.actId !== id)));
      }
    } catch (error) {
      console.error('Error removing activity:', error);
    }
  };

  return (
    <div id="prof-header">
      {user && user.username !== profile.username && (
        <FontAwesomeIcon 
          onClick={handleFollow} 
          id="follow" 
          icon={isFollowing ? faUserCheck : faUserPlus} 
        />
      )}
      <img id="profile-img" alt="profile" src={profile.profileImageUrl} loading="lazy" />
      <div id="pen-name">{profile.penName}</div>
      <Link to={`/${profile.username}`} id="prof-username">
        <h3>~{profile.username}</h3>
      </Link>
      <p id="statline">
        <b>Valor: </b><UserPoints profile = {profile}/>
        <b> Rank: </b>{profile.rank}
      </p>
      <div className="badges">
      {profile.badges?.map((badge, index) => {
          const badgePath = badge.type === 'potd' 
            ? `/potd/${badge.contestId}` 
            : `/contests/${badge.contestId}`;

          return (
            <Link key={index} to={badgePath}>
              <img 
                width='100px' 
                src={`../../../images/badges/${badge.type}.png`} 
                title={badge.type === 'potd' ? 'Prompt of the Day Feature' : 'Contest Winner'} 
                alt = 'badge of honor'
                loading="lazy"
              />
            </Link>
          );
        })}
      </div>
    </div>
  );
};
