import React from 'react';
import PropTypes from 'prop-types';

// Function to recursively create React elements from JSON
const createElement = (node, index, omitSpan = false) => {
  if (typeof node === 'string' || typeof node === 'number') {
    return node;
  }

  if (node === false || node === null || node === undefined) {
    return null;
  }

  const { type, props } = node;

  // Omit span and its content if omitSpan is true
  if (omitSpan && type === 'span') {
    return null;
  }

  // Add logic to replace <br> elements with a custom string
  if (type === 'br') {
    return ' / '; // Replace <br> with a slash and a space
  }

  if (typeof type === 'string') {
    return React.createElement(
      type,
      { ...props, key: index }, // Add key prop to the props object
      Array.isArray(props.children)
        ? props.children.map((child, i) => createElement(child, i, omitSpan)) // Pass index as a key for children
        : createElement(props.children, 0, omitSpan)
    );
  }
  return null;
};

const JsonToReact = ({ jsonString, omitSpan = false }) => {
  if (typeof jsonString !== 'string' || !jsonString.trim()) {
    console.warn('Invalid input:', jsonString);
    return <div>Invalid data</div>;
  }

  // Try to parse the string as JSON
  try {
    const parsedObject = JSON.parse(jsonString);

    // If parsedObject is an object, recursively convert it to React elements
    if (typeof parsedObject === 'object' && parsedObject !== null) {
      return createElement(parsedObject, 0, omitSpan);
    }

    // If the parsed object isn't a valid JSON structure for React, just return the string
    return <div>{jsonString}</div>;
  } catch (error) {
    // If the string is not JSON, return the plain string
    return <div>{jsonString}</div>;
  }
};


export const renderRandomPrompt = ({ jsonString }) => {
  if (typeof jsonString !== 'string' || !jsonString.trim()) {
    return <div>Invalid data</div>;
  }

  // Parse the JSON and render the specific React element structure
  try {
    const parsedObject = JSON.parse(jsonString);

    // Render the exact expected structure
    return (
      <div className="random text">
        {parsedObject.props.children.map((child, index) =>
          typeof child === 'string' ? (
            child
          ) : (
            <br key={index} />
          )
        )}
      </div>
    );
  } catch {
    return <div>Invalid data</div>;
  }
};


JsonToReact.propTypes = {
  jsonString: PropTypes.string.isRequired,
  omitSpan: PropTypes.bool,
};

export default JsonToReact;
