import React, { useState } from "react";
import { collection, doc, setDoc } from "firebase/firestore";
import { firestore } from "../../../firebase/firebase";
import { useSelector } from "react-redux";
import './message.css'
export const Message = () => {

    const {user} = useSelector(state=>state.app);
    
    const [subject, setSubject] = useState("");
    const [body, setBody] = useState("");
    const [category, setCategory] = useState("");
    const [error, setError] = useState("");

    const categories = ["General Inquiry", "Technical Support", "Feedback", "Other"];

    const handleSubmit = async () => {
        // Validation
        if (!category) return setError("Please select a category.");
        if (!subject || subject.split(" ").length > 30) return setError("Subject must be non-empty and under 30 words.");
        if (body.length < 10 || body.length > 5000) return setError("Body must be between 10 and 500 characters.");

        // Confirmation Popup
        if (!window.confirm("Are you sure you want to submit this message?")) return;
        // Submit to Firestore
        const newDocRef = doc(collection(firestore, `messages`));

            const docData = {
                username: user.username,
                category,
                subject,
                body,
                time: new Date().toISOString(),

            }

        try {
            await setDoc(newDocRef, docData);
            alert("Message sent successfully.");
            // Clear form
            setSubject("");
            setBody("");
            setCategory("");
            setError("");
        } catch (error) {
            console.error("Error sending message:", error);
            setError("Failed to send message. Please try again.");
        }



    };

    return (
        <div className="message">
            <h3>Contact Admin</h3>

            <label>
                Category:
                <br/>
                <select value={category} onChange={(e) => setCategory(e.target.value)}>
                    <option value="">Select a category</option>
                    {categories.map((cat, index) => (
                        <option key={index} value={cat}>
                            {cat}
                        </option>
                    ))}
                </select>
            </label>

            <label>
                Subject:
                <input
                    type="text"
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                    placeholder="Enter subject here"
                    maxLength={150} // Limit input to 150 characters (approx. 30 words)
                />
            </label>

            <label>
                Message:
                <textarea
                    value={body}
                    onChange={(e) => setBody(e.target.value)}
                    placeholder="Enter your message here"
                    rows="5"
                    maxLength={5000}
                />
            </label>

            {error && <p className="error">{error}</p>}

            <button onClick={handleSubmit} disabled={!user}>Submit Message</button>
        </div>
    );
};
