import React from "react";
import PropTypes from "prop-types";


const calculatePoints = (totalWords, writerLikes, writerComments, totalWriterReplies, writerRatings, writerAvgRating) => {
    return (
        Math.floor(totalWords / 20) +
        calculateLikesPoints(writerLikes) +
        writerComments * 5 +
        totalWriterReplies * 2 +
        calculateLikesPoints(writerRatings) * writerAvgRating
    );
};

// Helper function to calculate reader points
const calculateReaderPoints = (readerLikes, readerComments, readerReplies, readerRatings, forumPosts, forumAnswers, forumLikes, forumComments, userThoughts) => {
    return (
        calculateLikesPoints(readerLikes) +
        readerComments * 5 +
        readerReplies * 2 +
        readerRatings * 2 +
        forumPosts * 3 +
        forumAnswers * 5 +
        forumComments * 2 +
        (calculateLikesPoints(forumLikes) / 2 ) +
        calculateLikesPoints(userThoughts)
    );
};

// Helper function to calculate points based on stats
const calculateLikesPoints = (stats) => {
    let points = 0;
    if (stats > 10000) {
        points += (stats - 500) * 0.01;
        stats = 10000;
    }
    if (stats > 5000) {
        points += (stats - 500) * 0.05;
        stats = 5000;
    }
    if (stats > 500) {
        points += (stats - 500) * 0.1;
        stats = 500;
    }
    if (stats > 200) {
        points += (stats - 200) * 0.25;
        stats = 200;
    }
    if (stats > 100) {
        points += (stats - 100) * 0.5;
        stats = 100;
    }
    points += stats * 1;
    return points;
};

// Helper function to determine rank
const determineRank = (totalPoints) => {
    switch (true) {
        case totalPoints > 5000: return "Master";
        case totalPoints > 3000: return "Wordsmith";
        case totalPoints > 2000: return "Storyteller";
        case totalPoints > 1000: return "Scribe";
        case totalPoints > 500: return "Journeyman";
        case totalPoints > 250: return "Apprentice";
        case totalPoints > 100: return "Scribbler";
        default: return "Novice";
    }
};

const UserPointsBreakdown = ({ profile, activity, stories, forumAnswers }) => {
    // Calculate writer stats
    const totalWords = stories.reduce((sum, story) => sum + (story.wordCount || 0), 0);
    const writerLikes = stories.reduce((sum, story) => sum + (story.likes ? story.likes.length : 0), 0);
    const writerComments = stories.reduce((sum, story) => {
        const filteredComments = story.comments ? story.comments.filter(comment => comment.username !== profile.username) : [];
        return sum + filteredComments.length;
    }, 0);
    const writerRatings = stories.reduce((sum, story) => sum + (story.ratings ? story.ratings.length : 0), 0);
    const totalWriterRating = stories.reduce((sum, story) => sum + (story.ratings ? story.ratings.reduce((acc, r) => acc + (r.rating || 0), 0) : 0), 0);
    const totalWriterReplies = stories.reduce((sum, story) => sum + (story.comments ? story.comments.reduce((acc, comment) => acc + (comment.replies ? comment.replies.length : 0), 0) : 0), 0);
    const writerAvgRating = writerRatings ? totalWriterRating / writerRatings : 0;

    // Calculate reader stats
    const readerLikes = new Set(activity.filter(a => a.action === "liked").map(a => a.storyId)).size;
    const readerComments = activity.filter(a => a.action === "commented on" && a.type === "story" && a.username !== profile.username).length;
    const readerReplies = activity.filter(a => a.action === "replied to a comment on" && a.type === "story" && a.username !== profile.username).length;
    const readerRatings = new Set(activity.filter(a => a.action === "rated").map(a => a.storyId)).size;
    const forumPosts = activity.filter(a => a.action === "posted").length;
    const userAnswers = activity.filter(a => a.action === "answered").length;
    const forumComments = activity.filter(a => a.action === "commented on" && a.type === "forum").length;
    const userThoughts = activity.filter(a => a.action === "shared a thought").length;
    const forumLikes = forumAnswers.reduce((total, answer) => total + (answer.likes?.length || 0), 0);

    // Calculate points
    const writerPoints = calculatePoints(totalWords, writerLikes, writerComments, totalWriterReplies, writerRatings, writerAvgRating);
    const readerPoints = calculateReaderPoints(readerLikes, readerComments, readerReplies, readerRatings, forumPosts, userAnswers, forumLikes, forumComments, userThoughts);

    return (
        <div className="points-breakdown">
            <h3>Points Breakdown for {profile.username}</h3>
            <ul>
                <li>Writer Points:</li>
                <ul>
                    <li>Total Words: {totalWords}</li>
                    <li>Likes on Stories: {writerLikes}</li>
                    <li>Comments on Stories: {writerComments}</li>
                    <li>Replies to Comments: {totalWriterReplies}</li>
                    <li>Ratings Given: {writerRatings}</li>
                    <li>Average Rating: {writerAvgRating.toFixed(2)}</li>
                    <li>Total: {writerPoints}</li>
                </ul>
                <li>Reader Points:</li>
                <ul>
                    <li>Likes on Stories: {readerLikes}</li>
                    <li>Comments on Stories: {readerComments}</li>
                    <li>Replies to Comments: {readerReplies}</li>
                    <li>Ratings Given: {readerRatings}</li>
                    <li>Forum Posts: {forumPosts}</li>
                    <li>Forum Answers: {userAnswers}</li>
                    <li>Forum Comments: {forumComments}</li>
                    <li>User Thoughts: {userThoughts}</li>
                    <li>Forum Likes: {forumLikes}</li>
                    <li>Total: {readerPoints}</li>
                </ul>
                <li>Bonus Points: {profile.bonus}</li>
                <li><strong>Total Points: {writerPoints + readerPoints + profile.bonus}</strong></li>
                <li><strong>Rank: {determineRank(writerPoints + readerPoints + profile.bonus)}</strong></li>
            </ul>
        </div>
    );
};

UserPointsBreakdown.propTypes = {
    profile: PropTypes.object.isRequired,
    activity: PropTypes.array.isRequired,
    stories: PropTypes.array.isRequired,
    forumAnswers: PropTypes.array.isRequired
};

export default UserPointsBreakdown;
