import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { firestore } from "../firebase/firebase";

const PageViewTracker = () => {
  const location = useLocation();

  useEffect(() => {
    const logPageView = async () => {
      try {
        // Get current month and year
        const date = new Date();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed, so add 1
        const year = date.getFullYear();
        const monthYear = `${month}-${year}`;

        // Firestore path: admin/pageViews/month-year
        await addDoc(collection(firestore, `${monthYear}`), {
          path: location.pathname,
          timestamp: serverTimestamp()
        });
      } catch (e) {
        console.error("Error adding document: ", e);
      }
    };

    logPageView();
  }, [location]);

  return null;
};

export default PageViewTracker;
