import React, {useState, useEffect} from "react";
import { displayPrompt, selectCharacterPrompt } from "./characterPromptSlice";
import { useSelector, useDispatch } from "react-redux";
import { charGen } from "./characterPromptSlice";
import { CharacterSelector } from "./characterSelector";
import { setPrompt } from "../writePrompt/writePromptSlice";
import { NavLink } from "react-router-dom";
import { SavePrompt } from "../savePrompt";
import { CreatePromptContest } from "../createPromptContest";
import JsonToReact from "../../../utilities/jsonToReact";
import { CreatePotd } from "../../admin/createPotd";

export default function CharacterPrompt(){

    const characterPrompt = useSelector(selectCharacterPrompt)
    const dispatch = useDispatch();
    const prompt = characterPrompt.prompt
    const [save, setSave] = useState(false);
    const [style, setStyle] = useState({})
    const {user} = useSelector(state => state.app)

    useEffect(()=>{
        
        if(prompt !== undefined){
            setSave(true);

            const style1 = {
                backgroundColor: 'white',
                padding: '2rem'
            }
            setStyle(style1);
        }
    },[prompt])
    

    const handleClick = () => {
        dispatch(charGen());
        dispatch(displayPrompt());
        save? setSave(false): setSave(true);
    }

    const handleWrite =()=>{
        dispatch(setPrompt({type:'character', prompt: prompt}));
    }

    return(
        //adding the save && takes away the blank space of the p object before the prompt is generated
        <div id='plotPrompt'>
            <CharacterSelector />
            <div className='prompt' id='characterPrompt' >
                {save && prompt &&<div id='plotP' style={style}>{JsonToReact({jsonString: prompt}) }
                {user && <NavLink to={'/writePrompt'}><button id='button2' className="write-button" onClick={()=> handleWrite()} >Write It</button></NavLink>}
                {user?.admin &&
                <>
                    <CreatePromptContest prompt={prompt} promptType='character'/>
                    <CreatePotd prompt={prompt} promptType='character'/>
                </>
                }

            </div>
                }
                <button id='button1' onClick={()=> handleClick()}>Generate New Character</button>
                {save && user && <SavePrompt prompt={prompt} type={'character'}/>}
            </div>
        </div>
    )
}
