import React from 'react'
import './potd.css'
import { LeftSide } from '../../app/leftSide/leftSide'
import { RightSide } from '../../app/rightSide/rightSide'
import { Outlet, useNavigate } from 'react-router-dom'


export const PotdPage = () => {
    const navigate = useNavigate();
    return (
        <div className='page'>
        <LeftSide />
        <div className='content'>
            <nav>

            </nav>
            <header><h1 onClick={()=> navigate('/potd')} className='potd-header'>Prompts of the Day</h1></header>
            <Outlet />
        </div>
        <RightSide />

        </div>
    )
}